/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Modal,
  Button,
  Accordion,
  Card,
  Container,
  Row,
  Col,
  Media,
} from "react-bootstrap";
import {
  uploadExam,
  deleteExam,
  updateExam,
} from "../../../redux/actions/exams.action";
import createAlert from "../../../redux/actions/alert.action";

function Tests({ exams, uploadExam, deleteExam, updateExam, patientID }) {
  const [title, setTitle] = useState(null);
  const [filename, setFilename] = useState(null);
  const [show, setShow] = useState(true);
  const [confirm, setConfirm] = useState(false);

  const handleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleuploadexam = () => {
    const { document } = window;
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".png, .jpg, .jpeg, .pdf";
    input.onchange = () => {
      const file = input.files[0];
      if (
        file.type.split("/")[1] === "pdf" ||
        file.type.split("/")[1] === "jpeg" ||
        file.type.split("/")[1] === "png"
      ) {
        const formData = new FormData();
        formData.append("file", file);
        if (title) {
          uploadExam(formData, title, patientID);
          createAlert(
            "Creado",
            "El archivo fue creado correctamente",
            "success"
          );
        } else {
          createAlert("Error", "Rellene el campo titulo", "error");
        }
      } else {
        createAlert(
          "Error",
          "Solo se permiten archivos JPG, PDF y PNG",
          "error"
        );
      }
    };
    input.click();
  };

  const deleteFile = (filename) => {
    deleteExam(filename);
    createAlert(
      "Eliminado",
      "El archivo fue eliminado correctamente",
      "success"
    );
  };

  const showFile = (item) => {
    setTitle(item.title);
    setFilename(item.filename);
    setShow(false);
  };

  const updateFile = () => {
    updateExam(filename, title);
    createAlert(
      "Actualizado",
      "El nombre del archivo fue actualizado correctamente",
      "success"
    );
  };
  return (
    <div className="sales mt-3">
      <div className="media">
        <div className="media-body">
          {" "}
          <h5 className="font-blue mt-3 text-center">
            {" "}
            Subir archivos de exámenes
          </h5>
          <div className="row center center-div">
            <i className="far fa-file-pdf font86" />
            <div className="form-group">
              <input
                type="text"
                className="form-control center m-auto col-12 col-md-7"
                placeholder="Nombre del Archivo"
                name="title"
                value={title}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="form-group mt-3">
              <button
                type="submit"
                className="btn btn-primary btn-block m-auto col-12 col-md-7"
                hidden={!show}
                onClick={handleuploadexam}
              >
                Subir Archivos
              </button>
              <button
                type="submit"
                className="btn btn-primary btn-block m-auto col-12 col-md-7"
                hidden={show}
                onClick={updateFile}
              >
                Actualizar
              </button>
            </div>
          </div>
          <div className="media-body">
            <hr />
            <div className="container">
              {exams.map((item, index) => (
                <div className="row toppad">
                  <div className="col-1">
                    <i className="far fa-file-pdf font-blue font2em" />
                  </div>
                  <div className="col-8">
                    <h6 className="media-heading text-dash">{item.title}</h6>
                  </div>
                  <div className="col-md-3">
                    <div className="row edit-right">
                      <a
                        className="pad-right"
                        onClick={(e) => {
                          showFile(item);
                        }}
                      >
                        <i className="far fa-edit btn-group text-secondarymdv" />
                      </a>
                      <a
                        className="padd-left"
                        target="_blank"
                        href={`/api/patient/history/exam/show/${item.filename}`}
                      >
                        <i className="fas fa-eye btn-group text-primary" />
                      </a>
                      <a
                        className="pad-right"
                        onClick={(e) => {
                          deleteFile(item.filename);
                        }}
                      >
                        <i className="fas fa-times btn-group text-danger" />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Modal show={confirm} centered size="sm">
        <Modal.Header className="border-bottom-0 mx-auto">
          <h5 className="text-center text-primary pt-3 pb-0 font-weight-bold">
            ¿Quiere eliminar el medicamento?
          </h5>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0">
          <h6 className="text-center text-black-50 pt-0 pb-0" />
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <Button
              type="button"
              variant="link"
              className="btn btn-link btn-sm mr-3 text-secondarymdv font-weight-bold"
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              className="btn btn-primary btn-sm"
              type="button"
            >
              Confirmar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  exams: state.history.exams.items,
  patientID: state.profile.searchPatientID,
});

export default connect(mapStateToProps, {
  uploadExam,
  deleteExam,
  updateExam,
})(Tests);

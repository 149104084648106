import React from "react";
import createAlert from "../../redux/actions/alert.action";

const AddDiagnosis = ({ handleDiagnosis, diagnosis }) => (
  <div
    className="modal fade"
    id="Modaldiagnostico"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5
            className="modal-title text-primary mx-auto"
            id="exampleModalLabel"
          >
            SU DIAGNÓSTICO
          </h5>
        </div>
        <div className="modal-body">
          <div
            id="carouselExample"
            className="carousel slide"
            data-ride="carousel"
            data-interval="2000"
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleDiagnosis(e.target.diagnosis.value);
              }}
            >
              <div className="form-group">
                <textarea
                  defaultValue={diagnosis}
                  rows={4}
                  type="text"
                  name="diagnosis"
                  className="form-control"
                  id="recipient-name"
                />
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ width: "200px" }}
                >
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AddDiagnosis;

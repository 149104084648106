import React, { useEffect, useState } from "react";
import Pagination from "../../Components/Pagination/Pagination";
import { Link } from "react-router-dom";
import ModalAddNewEmployed from "./Modals/ModalAddNewEmployed";
import useFarinterSelector from "../../hooks/useFarinterSelector";
import Recordatorio from "./Modals/Recordatorio";
import Recordatorio2 from "./Modals/Recordatorio2";
import Table from "react-bootstrap/Table";
import ItemListEmployeeTable from "./ListEmployees/ItemListEmployeeTable";
import { connect } from "react-redux";
import moment from "moment";
import { usePackage } from "./ListEmployees/state/usePackages";
import { getDependentEmployeeExport } from "../../api/dependentPatient";
import useOpenModal from "../../hooks/useOpenModal";
import * as XLSX from "xlsx";
import {
  getEmployeesSearch,
  giveAllEmployees,
  downAllEmployees,
  getEmployees,
} from "../../redux/actions/auth.action";
import JoinEmployeeToCompany from "./JoinEmployeeToCompany/JoinEmployeeToCompany";

const HomeManageEmployees = ({
  getEmployeesSearch,
  getEmployees,
  selection,
  datos,
  giveAllEmployees,
  downAllEmployees,
}) => {
  const { auth } = useFarinterSelector();
  const { user } = auth;
  const { packages } = usePackage();
  const [searching, setSearching] = useState("");
  const { open, handleOpenModal } = useOpenModal();

  useEffect(() => {
    if (searching === "") {
      getEmployees(user?._id);
    }
  }, []);

  function handleSearching(e) {
    setSearching(e.target.value);
    if (e.target.value === "") {
      getEmployees(user?._id);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    await getEmployeesSearch(user?._id, searching);
  };

  const verifyChecked = () => {
    var cont = 0;
    for (var i = 0; i < datos.length; i++) {
      if (!datos[i].package[0]?.packageKtm._id && datos[i].status === true) {
        cont++;
      }
    }
    return cont;
  };

  const getAllEmployees = () => {
    if (verifyChecked() === selection.length) {
      downAllEmployees();
    } else {
      var array = [];
      for (var i = 0; i < datos.length; i++) {
        if (!datos[i].package[0]?.packageKtm._id && datos[i].status === true) {
          var _id = datos[i]._id;
          var dni = datos[i].dni;
          var idPackage = datos[i].package[0]?.packageKtm._id
            ? datos[i].package[0].packageKtm._id
            : packages[0]._id;
          var formData = {
            _id,
            dni,
            idPackage,
          };
          array.push(formData);
        }
      }
      giveAllEmployees(array);
    }
  };

  const getEmployeesExcel = () => {
    const dataEmployeeExcel = [];
    datos.forEach((employee) => {
      dataEmployeeExcel.push({
        ID: employee.dni,
        Email: employee.email,
        Nombres: employee.firstName,
        Apellidos: employee.lastName,
        Empresa: user?.firstName,
        Tipo_de_Plan:
          employee.package[0]?.createdAt && employee.package[0]?.dueDate
            ? employee.package[0].packageKtm.name
            : "sin plan",
        Duracion:
          employee.package[0]?.createdAt && employee.package[0]?.dueDate
            ? employee.package[0].packageKtm.typeSubscription
            : "sin plan",
        Status: employee.status === true ? "activo" : "inactivo",
        Telefono: employee?.cellPhone ? employee.cellPhone : "+504 00000000",
      });
    });
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(dataEmployeeExcel);

    XLSX.utils.book_append_sheet(wb, ws, "Mysheet");
    XLSX.writeFile(wb, "Empleados.xlsx");
  };

  const getRelation = (relation) => {
    switch (relation) {
      case "son":
        return "Hij@";
      case "grandpa":
        return "Abuelo";
      case "grandma":
        return "Abuela";
      case "mother":
        return "Madre";
      case "father":
        return "Padre";
      case "uncle":
        return "tí@";
      case "other":
        return "otro";
    }
  };

  const getEmployeesDependentsExcel = () => {
    const dataDependents = [];
    datos.forEach(async (data, index) => {
      const res = await getDependentEmployeeExport(data._id);
      if (res.length > 0) {
        res.forEach((dependent) => {
          dataDependents.push({
            Nombres: dependent.data.firstName,
            Apellidos: dependent.data.lastName,
            Dni: dependent.data.dni,
            Parentesco: getRelation(dependent.relation),
            Fecha_creacion: dependent.data?.date.substring(0, 10),
            Genero: dependent.data.gender === "male" ? "hombre" : "mujer",
            Email: dependent.data.email,
            Dni_Usuario_Principal: data.dni,
            Nombres_Empleado:data.firstName,
            Apellidos_Empleado: data.lastName
          });
        });
      }
      if (datos.length - 1 === index) {
        var wb = XLSX.utils.book_new(),
          ws = XLSX.utils.json_to_sheet(dataDependents);

        XLSX.utils.book_append_sheet(wb, ws, "Mysheet");
        XLSX.writeFile(wb, "Dependientes_de_Empleados.xlsx");
      }
    });
  };

  return (
    <div className="container-fluid">
      <ModalAddNewEmployed />
      {open && (
        <JoinEmployeeToCompany open={open} handleOpenModal={handleOpenModal} />
      )}
      <Recordatorio />
      <Recordatorio2 />
      <h2 className="text-primary mb-4 mt-5">
        Bienvenido <b className="text-secondarymdv">{user?.firstName} </b>
      </h2>
      <br />
      <div className="row align-items-center">
        <div className="col-12 col-lg-5">
          <form className="input-group mb-3" onSubmit={(e) => handleSubmit(e)}>
            <input
              value={searching}
              onChange={(e) => handleSearching(e)}
              type="text"
              className="form-control"
              placeholder="&nbsp;Buscar empleado por nombre, identidad o correo"
              aria-label="&nbsp;Buscar empleado por nombre, identidad o correo"
              aria-describedby="Buscar empleado por nombre, identidad o correo"
            />
            <div className="input-group-append">
              <button
                className="btn btn-light border border-right-rounded"
                type="button"
                id="button-addon2"
                onClick={() => getEmployeesSearch(user?._id, searching)}
              >
                <i className="fas fa-search text-secondarymdv" />
              </button>
            </div>
          </form>
        </div>
        <div className="col-12 col-lg-3">
          <div className="d-flex">
            <h6 className="text-dark">Filtrar por:</h6>
            <div className="form-check ml-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                Plan Activo
              </label>
            </div>
          </div>
        </div>
        <div className="col-auto ml-auto">
          <button
            className="btn text-secondarymdv bg-white border mr-3"
            type="button"
            style={{ borderRadius: "0.8rem" }}
            data-toggle="modal"
            data-target="#Recordatorio2"
          >
            <img
              src={require("../../assets/img/vectores/uploadicon.svg")}
              alt="upload employees"
              loading="lazy"
              className="img-fluid mr-1"
              style={{ width: 20, height: 20 }}
            />
            cargar dependientes
          </button>
          <button
            className="btn text-secondarymdv bg-white border mr-3"
            type="button"
            style={{ borderRadius: "0.8rem" }}
            onClick={getEmployeesDependentsExcel}
          >
            <img
              src={require("../../assets/img/vectores/cloud-download.png")}
              alt="upload employees"
              loading="lazy"
              className="img-fluid mr-1"
              style={{ width: 20, height: 20 }}
            />
            Descargar dependientes
          </button>
          <button
            className="btn text-secondarymdv bg-white border mr-3"
            type="button"
            style={{ borderRadius: "0.8rem" }}
            onClick={getEmployeesExcel}
          >
            <img
              src={require("../../assets/img/vectores/cloud-download.png")}
              alt="upload employees"
              loading="lazy"
              className="img-fluid mr-1"
              style={{ width: 20, height: 20 }}
            />
            Descargar empleados
          </button>
          <button
            className="btn text-secondarymdv bg-white border"
            type="button"
            style={{ borderRadius: "0.8rem" }}
            data-toggle="modal"
            data-target="#Recordatorio"
          >
            <img
              src={require("../../assets/img/vectores/uploadicon.svg")}
              alt="upload employees"
              loading="lazy"
              className="img-fluid mr-1"
            />
            Cargar empleados
          </button>
          <button
            className="btn btn-info ml-3"
            data-toggle="modal"
            data-target="#ModalAddNewEmployed"
            type="button"
            style={{ borderRadius: "0.8rem" }}
          >
            <i className="fas fa-plus mr-1" /> Agregar empleado
          </button>
          <button
            className="btn btn-info ml-3"
            type="button"
            style={{ borderRadius: "0.8rem" }}
            onClick={handleOpenModal}
          >
            <i className="fas fa-plus mr-1" /> Vincular existente
          </button>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-lg-12">
          <div className="bg-white">
            <Table responsive borderless>
              <thead>
                <tr>
                  <th>
                    <div className="form-check">
                      <input
                        className="form-check-input-center"
                        role="button"
                        type="checkbox"
                        disabled={verifyChecked() === 0 ? true : false}
                        onClick={() => getAllEmployees()}
                        id="flexCheckDefault"
                        checked={
                          verifyChecked() === selection.length ? true : false
                        }
                        style={{ width: 15, height: 15 }}
                      />
                    </div>
                  </th>
                  <th>
                    <h6 className="text-secondarymdv text-center">ID</h6>
                  </th>
                  <th>
                    <h6 className="text-secondarymdv text-center">Email</h6>
                  </th>
                  <th>
                    <h6 className="text-secondarymdv text-center">Nombres</h6>
                  </th>
                  <th>
                    <h6 className="text-secondarymdv text-center">Apellidos</h6>
                  </th>
                  <th>
                    <h6 className="text-secondarymdv text-center">Empresa</h6>
                  </th>
                  <th>
                    <h6 className="text-secondarymdv text-center">
                      Tipo de plan
                    </h6>
                  </th>
                  <th>
                    <h6 className="text-secondarymdv text-center">Duración</h6>
                  </th>
                  <th>
                    <h6 className="text-secondarymdv text-center">
                      Status Plan
                    </h6>
                  </th>
                  <th>
                    <h6 className="text-secondarymdv text-center">Status</h6>
                  </th>
                  <th>
                    <h6 className="text-secondarymdv text-center">Opciones</h6>
                  </th>
                </tr>
              </thead>
              <tbody>
                <ItemListEmployeeTable />
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      {/* paginador y botón pagar */}
      <div className="d-flex justify-content-between align-items-center mt-5">
        <Pagination />
        {selection.length >= 1 ? (
          <Link
            className="btn btn-primary btn-lg"
            to="/dashboard/enterprise/invoice"
          >
            <img
              src={require("../../assets/img/vectores/cart-add.svg")}
              alt="shopping plan"
              width={28}
              loading="lazy"
              className="img-fluid"
            />
            Pagar planes seleccionados
          </Link>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  datos: state.enterprise.listEmployees,
  selection: state.enterprise.selectedEmployee,
});
export default connect(mapStateToProps, {
  giveAllEmployees,
  downAllEmployees,
  getEmployees,
  getEmployeesSearch,
})(HomeManageEmployees);

import React from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useFormData } from '../state/useFormData';
import { usePasswordShown } from '../state/usePasswordShown';
import lockImg from '../../../assets/img/icons/candado.png';
import eyeImg from '../../../assets/img/icons/ojo.png';
import { useLoaderButton } from '../state/useLoaderButton';
import { Spinner } from 'react-bootstrap';

const CompanyUserRegisterForm = () => {
  const {
    firstName,
    lastName,
    gender,
    handleChange,
    bornAt,
    dni,
    idType,
    handleSetFormatDni,
    listCompanies,
    enterpriseSelected,
    setEnterpriseSelected,
    setDomainSelected,
    email,
    domainSelected,
    terms,
    setTerms,
    password,
    handleSubmitCompanyRegister,
    loaderButton,
  } = useFormData();

  return (
    <div className="col-sm-12">
      <form id="Login">
        <div className="row">
          {listCompanies.length === 0 ? (
            <h2 className="text-primary font-weight-bold flex-justify-center-align-center empty-company-list-container">
              No existen empresas afiliadas
            </h2>
          ) : (
            <>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="text-primary font-weight-bold float-left">
                    Nombre
                  </label>
                  <input
                    className="form-control bg-light"
                    name="firstName"
                    value={firstName}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label className="text-primary font-weight-bold float-left">
                    Apellido
                  </label>
                  <input
                    type="email"
                    className="form-control bg-light"
                    id="inputEmail"
                    name="lastName"
                    value={lastName}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label className="text-primary font-weight-bold float-left">
                    Fecha de Nacimiento
                  </label>
                  <input
                    type="date"
                    className="form-control bg-light"
                    placeholder="Fecha de Nacimiento"
                    name="bornAt"
                    value={bornAt}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label className="text-primary font-weight-bold float-left">
                    Identidad
                  </label>
                  <select
                    className="form-control bg-light"
                    id="exampleFormControlSelect1"
                    name="idType"
                    value={idType}
                    onChange={handleChange}
                    placeholde="Tipo de identificación"
                  >
                    <option value="national">ID nacional</option>
                    <option value="foreign">ID extranjero</option>
                    <option value="rtn">RTN</option>
                  </select>
                </div>
                <div className="form-group">
                  {idType === 'foreign' ? (
                    <input
                      type="text"
                      className="form-control bg-light"
                      name="dni"
                      value={dni}
                      onChange={handleChange}
                    />
                  ) : (
                    <NumberFormat
                      className="form-control bg-light"
                      format={`${handleSetFormatDni()}`}
                      mask="_"
                      name="dni"
                      value={dni}
                      onChange={handleChange}
                    />
                  )}
                </div>
                <div className="form-group">
                  <label className="text-primary font-weight-bold float-left">
                    Género
                  </label>
                  <select
                    className="form-control bg-light"
                    id="exampleFormControlSelect1"
                    name="gender"
                    value={gender}
                    onChange={(e) => handleChange(e)}
                    placeholde="Género"
                  >
                    <option value="female">Femenino</option>
                    <option value="male">Masculino</option>
                  </select>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label className="text-primary font-weight-bold float-left">
                    Empresas afiliadas
                  </label>
                  <select
                    className="form-control bg-light"
                    onChange={(e) => {
                      setEnterpriseSelected(e.target.value);
                      setDomainSelected(
                        listCompanies[e.target.value].domains[0]
                      );
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Selecccione una empresa afiliada
                    </option>
                    {listCompanies.map((doc, index) => (
                      <option value={index}>{doc.name}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label className="text-primary font-weight-bold float-left">
                    Dominios asociados a esta Empresa
                  </label>

                  {listCompanies[enterpriseSelected]?.domains.length > 0 && (
                    <select
                      className="form-control bg-light"
                      onChange={(e) => setDomainSelected(e.target.value)}
                    >
                      {listCompanies[enterpriseSelected].domains.map((data) => (
                        <option value={data}>{data}</option>
                      ))}
                    </select>
                  )}
                </div>
                <div className="form-group">
                  <label className="text-primary font-weight-bold float-left">
                    Correo Electrónico
                  </label>
                  <InputGroup className="mb-3">
                    <FormControl
                      value={email}
                      name="email"
                      onChange={handleChange}
                      style={{ marginRight: 0 }}
                    />
                    <InputGroup.Text
                      id="basic-addon2"
                      style={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderLeft: 0,
                        borderTopRightRadius: '2rem',
                        borderBottomRightRadius: '2rem',
                      }}
                    >
                      {domainSelected ? domainSelected : '@ejemplo.com'}
                    </InputGroup.Text>
                  </InputGroup>
                </div>

                <div className="form-group">
                  <div
                    className="form-check float-left"
                    style={{ marginBottom: '2rem', marginTop: '1rem' }}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox1"
                      name="terms"
                      onChange={() => {
                        setTerms(!terms);
                      }}
                      checked={terms}
                    />
                    <label
                      className="form-check-label text-primary pointer"
                      htmlFor="inlineCheckbox1"
                      data-toggle="modal"
                      data-target="#ModalTermConditions"
                      data-whatever="@getbootstrap"
                    >
                      He leído y aceptado los
                      <b>Términos y Condiciones</b>.
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary font-weight-bold btn-block"
                    disabled={
                      firstName === '' ||
                      lastName === '' ||
                      bornAt === '' ||
                      dni === '' ||
                      email === '' ||
                      !terms ||
                      !domainSelected
                    }
                    style={{
                      opacity:
                        firstName === '' ||
                        lastName === '' ||
                        bornAt === '' ||
                        dni === '' ||
                        email === '' ||
                        !terms ||
                        !domainSelected
                          ? '70%'
                          : '100%',
                    }}
                    onClick={handleSubmitCompanyRegister}
                  >
                    {loaderButton ? (
                      <Spinner
                        style={{
                          width: 20,
                          height: 20,
                          color: '#ffff',
                          fontSize: 8,
                        }}
                        animation="border"
                      />
                    ) : (
                      'REGISTRARSE'
                    )}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default CompanyUserRegisterForm;

import React from 'react';
import {connect} from 'react-redux';
import createAlert from '../../redux/actions/alert.action';
import {hours} from '../../utils/scheduling';
import {addScheduleDoctor} from '../../api/scheduling';

const AddSchedule = ({
  token,
  patient,
  idVideo,
  sendSchedule,
  scheduleVideo,
}) => (
  <div
    className="modal fade"
    id="Modalagenda"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5
            className="modal-title text-primary mx-auto"
            id="exampleModalLabel"
          >
            AGENDAR CITA
          </h5>
        </div>
        <div className="modal-body">
          <div
            id="carouselExample"
            className="carousel slide"
            data-ride="carousel"
            data-interval="2000"
          >
            {!scheduleVideo ? (
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  const reason = event.target.reason.value;
                  const date = event.target.date.value;
                  const hour = event.target.hour.value;
                  addScheduleDoctor({
                    reason,
                    date,
                    token,
                    hour,
                    patient,
                    idVideo,
                  })
                    .then((res) => {
                      const {data} = res;
                      createAlert(
                        '¡Correcto!',
                        'Se agendó la consulta.',
                        'success',
                      );
                      sendSchedule(data);
                    })
                    .catch((error) => {
                      console.log(error);
                      if (error.status === 'error') {
                        createAlert('¡Error!', error.info, 'error');
                      } else {
                        createAlert(
                          '¡Error!',
                          'Lo sentimos no se agendó al cita',
                          'error',
                        );
                      }
                    });
                }}
              >
                <div className="form-group">
                  <textarea
                    className="form-control textarea-properties"
                    placeholder="Motivo de la cita*"
                    name="reason"
                  />
                </div>
                <div className="form-group">
                  <span style={{display: 'flex'}}>Fecha de cita</span>
                  <input
                    type="date"
                    className="form-control center"
                    placeholder="Fecha de cita"
                    name="date"
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    borderRadius: 5,
                    width: 100,
                    flexDirection: 'column',
                  }}
                >
                  <span style={{display: 'flex'}}>Hora cita</span>
                  <select
                    id="exampleFormControlSelect1"
                    name="hour"
                    placeholder="Hora"
                    style={{
                      padding: 5,
                      borderRadius: 10,
                      border: '1px solid #D4D9E3',
                      outline: 'none',
                    }}
                  >
                    {hours.map((hour) => (
                      <option value={hour}>{hour}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group text-center">
                  <button
                    type="submit"
                    className="btn btn-primary mx-auto"
                    style={{width: '200px'}}
                  >
                    ENVIAR
                  </button>
                </div>
              </form>
            ) : (
              <>
                <p>
                  {`${scheduleVideo.reason}, ${scheduleVideo.date}, ${scheduleVideo.hour}`}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);
const mapStateToProps = (state) => ({
  modalAddSchedule: state.ui.schedulingModal.open,
});

export default connect(mapStateToProps, {})(AddSchedule);

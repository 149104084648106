import {
  ADD_VERIFY_CONSULT,
  END_LOADING_VERIFY_CONSULT,
  LOADING_VERIFY_CONSULT,
  REMOVE_VERIFY_CONSULT,
  ADD_VIDEOCONFERENCE,
  DELETE_VIDEOCONFERENCE,
  TYPE_FOLLOW_UP,
} from '../types';

export const addVerifyConsult = (data) => (dispatch) => {
  dispatch({
    type: ADD_VERIFY_CONSULT,
    payload: data,
  });
};

export const loadingVerifyConsult = () => (dispatch) => {
  dispatch({
    type: LOADING_VERIFY_CONSULT,
    payload: true,
  });
};

export const removeVerifyConsult = () => (dispatch) => {
  dispatch({
    type: REMOVE_VERIFY_CONSULT,
  });
};

export const endLoadingVerifyConsult = () => (dispatch) => {
  dispatch({
    type: END_LOADING_VERIFY_CONSULT,
  });
};
export const addVideoconference = (videoconference) => (dispatch) => {
  dispatch({
    type: ADD_VIDEOCONFERENCE,
    payload: videoconference,
  });
};
export const deleteVideoconference = () => (dispatch) => {
  dispatch({
    type: ADD_VIDEOCONFERENCE,
    payload: null,
  });
};

export const changeTypeFollowUp = (type) => ({
  type: TYPE_FOLLOW_UP,
  payload: type,
});

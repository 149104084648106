import React, { useEffect } from "react";
import { connect } from 'react-redux'
import Withdrawal from "../../../Components/Doctor/Withdrawal";
import {getAmountAvailable} from '../../../redux/actions/retreat'

const WithdrawalPage = ({getAmountAvailable}) => {
  useEffect(() =>{
    getAmountAvailable();
  }, [])
  return <Withdrawal />;
};

export default connect(null, {
  getAmountAvailable,
})(WithdrawalPage);

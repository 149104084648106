/* eslint-disable arrow-parens */
/* eslint-disable no-console */
import React, { useState } from "react";
import Select from "react-select";
import MakeAnimated from "react-select/animated";
import { Dropdown, Button } from 'react-bootstrap';

const animatedComponent = MakeAnimated();

const Autocomplete = ({
  placeholder,
  options,
  handleChangeAutocomplete,
  name,
  defOptions,
  multi,
  removeOption
}) => {
  const [selectedRelations, setSelectedRelations] = useState([]);
  const relationes =[
    {value: 'Padres', label: 'Padres'},
    {value: 'Tíos', label: 'Tíos'},
    {value: 'Abuelos', label: 'Abuelos'},
    {value: 'Hermanos', label: 'Hermanos'},
  ]
  const toggleLang = (option) => {
    if (selectedRelations.includes(option)) {
      setSelectedRelations(
        selectedRelations.filter((item) =>
          item !== option));
    } else {
      setSelectedRelations(
        [...selectedRelations, option]);
    }
  };
  const removeRelation = (relation) => {
    setSelectedRelations(
      selectedRelations.filter((item) => item !== relation)
    );
  };
  return (
    <>
      {/*<Select
        closeMenuOnSelect={false}
        components={animatedComponent}
        isMulti={multi}
        options={relationes}
        placeholder={placeholder}
        onChange={(e) => handleChangeAutocomplete(e, name)}
        isClearable={false}
        defaultValue={defOptions}
  />*/}
      <div>

        <Dropdown className="d-inline mx-2" >
          <Dropdown.Toggle variant="success"
            id="dropdown-basic" style={{width:'100%', backgroundColor:'white', color:'grey',
            border: '1px solid #ced4da', borderRadius: '4px', borderColor:'grey',   textAlign: 'left' }}>
            {placeholder}
          </Dropdown.Toggle>
          <Dropdown.Menu >
            {options.map((option, index) => (
              <Dropdown.Item
                key={index}
                onClick={(e) => {
                  handleChangeAutocomplete(option);
                  toggleLang(option);
                }}
                active={selectedRelations.includes(option)}
                style={{ cursor: 'pointer', width: 500}}
              >
                {option}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <div>
          <strong>Relaciones seleccionadas:</strong>
          
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px' }}>
            {selectedRelations.map((relation, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', border: '1px solid #ddd', borderRadius: '4px', padding: '5px 10px', background: '#f1f1f1' }}>
                <span>{relation}</span>
                <button
                  onClick={() => {removeRelation(relation);
                    removeOption(relation)
                  }}
                  style={{
                    marginLeft: '10px',
                    backgroundColor: 'red',
                    color: 'white',
                    border: 'none',
                    borderRadius: '3px',
                    cursor: 'pointer',
                    padding: '0 5px'
                  }}
                >
                  X
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )

};

export default Autocomplete;

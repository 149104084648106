import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  searchPatients,
  searchPatientsCount,
  searchPatientsPagination,
  clearSearchPatientsPagination,
} from '../redux/actions/searchPatient.action';
import '../stylesheets/search.css';
import { changeSearch } from '../redux/actions/searchPatient.action';
import { connect } from 'react-redux';
import ModalSearchPatienName from './Modal/ModalSearchPatienName';
const SearchElement = ({ changeSearch, searching }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const handleSubmit = (e) => {
    dispatch(searchPatientsCount(e));
    dispatch(searchPatientsPagination(e, 1)).then(() => {
      setShowModal(true);
    });
  };
  const handleCloseModal = () => {
    dispatch(clearSearchPatientsPagination()).then(() => {
      setShowModal(false); // Cierra el modal una vez que la acción se completa
    });
  };
  return (
    <>
      <ModalSearchPatienName show={showModal} onHide={handleCloseModal} />
      <div className="input-group mb-1">
        <input
          type="text"
          placeholder="Buscar paciente"
          aria-label="Buscar paciente"
          aria-describedby="basic-addon2"
          value={searching}
          className="form-control"
          onChange={(e) => changeSearch(e.target.value)}
        />
        <div className="input-group-append">
          <button
            className="btn eye-pass btn-outline-secondary"
            role="button"
            id="inputGroupFileAddon04"
            type="button"
            onClick={searching != '' ? () => handleSubmit(searching) : () => {}}
          >
            <img
              src={require('../assets/img/icons/magnifying-glass.png')}
              className="img-fluid"
              width="24"
            />
          </button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  searching: state.searchingPatient.searching,
});
export default connect(mapStateToProps, {
  changeSearch,
})(SearchElement);

/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
import axios from 'axios';
import {
  GET_PROFILE,
  PROFILE_ERROR,
  UPLOAD_PROFILE_PHOTO,
  UPLOAD_PROFILE_DOCTOR_CV,
  ADD_CONSULTATION,
  ADDTIMETABLE,
  DELETETIMETABLE,
  DELETE_REFERENCE_SEARCH_PATIENT,
  SEARCH_PATIENT,
} from '../types';
import createAlert from './alert.action';
import { updateUser } from './auth.action';

export const getProfile = (role) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/profile/${role}`);

    if (res) {
      dispatch({
        type: GET_PROFILE,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_PROFILE,
        payload: null,
      });
    }
  } catch (error) {
    dispatch({
      type: PROFILE_ERROR,
    });
  }
};

export const deleteReferenceSearchPatient = () => ({
  type: DELETE_REFERENCE_SEARCH_PATIENT,
});

export const updateProfile = (formData, role) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const {
    nombre,
    apellido,
    genero,
    correo,
    pais,
    identidad,
    born,
    tel,
    especialidad,
    subEspecialidad,
    idiomas,
    colegiatura,
    universidad,
    experiencia,
    me,
    maestria,
    altura,
    peso,
    tipo_sangre,
    cita,
  } = formData;

  try {
    const sub = [];
    const lenguajes = [];

    if (role === 'doctor' || role === 'nurse') {
      // reestructurar especialidades
      subEspecialidad.forEach((item) => {
        sub.push(item.value);
      });

      // reestructurar lenguajes

      idiomas.forEach((item) => {
        lenguajes.push(item.value);
      });
    }
    // reestructurar genero
    let tempGender;
    if (genero === 'Masculino') {
      tempGender = 'male';
    } else {
      tempGender = 'female';
    }

    const body = JSON.stringify({
      firstName: nombre,
      lastName: apellido,
      gender: tempGender,
      email: correo,
      country: pais,
      dni: identidad,
      bornAt: born,
      cellphone: tel,
      specialty: especialidad,
      subSpecialties: sub,
      languages: lenguajes,
      college: colegiatura,
      university: universidad,
      experience: experiencia,
      about: me,
      master: maestria,
      weight: peso,
      height: altura,
      blood_type: tipo_sangre,
      appointment: cita,
    });
    const res = await axios.put(`/api/profile/${role}`, body, config);

    if (res.status === 200) {
      const doc = {
        firstName: nombre,
        lastName: apellido,
        gender: tempGender,
      };
      dispatch(updateUser(doc));
      dispatch({
        type: GET_PROFILE,
        payload: res.data,
      });
      createAlert('¡Correcto!', 'Usuario actualizado.', 'success');
    }
  } catch (error) {
    if (error.response.data) {
      createAlert('¡Error!', error.response.data, 'error');
    }
  }
};

export const resetpassword = (oldpass, newpass) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const body = JSON.stringify({
      oldpass,
      newpass,
    });
    const res = await axios.put('/api/users/reset', body, config);
    dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });
    createAlert('¡Correcto!', 'Contraseña actualizada con éxito.', 'success');
  } catch (err) {
    if (err.response !== null) {
      const errors = err.response.data;
      createAlert('¡Error!', errors.message, 'error');
    }
  }
};

export const addProfileImage = (file, fileName) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const res = await axios.post('/api/profile/image/upload', file, config);
    dispatch({
      type: UPLOAD_PROFILE_PHOTO,
      payload: res.data.filename,
    });

    if (fileName !== null && fileName !== '' && fileName !== undefined) {
      await axios.delete(`/api/profile/image/${fileName}`);
    }
  } catch (error) {
    console.log(error);
    createAlert('¡Error!', 'Error al intentar guardar el archivo.', 'error');
  }
};

export const addProfileDoctorCvFile = (file, fileName) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const res = await axios.post(
      '/api/profile/doctor/cv/file/upload',
      file,
      config
    );
    dispatch({
      type: UPLOAD_PROFILE_DOCTOR_CV,
      payload: res.data.filename,
    });

    if (fileName !== null && fileName !== '' && fileName !== undefined) {
      await axios.delete(`/api/profile/doctor/cv/file/${fileName}`);
    }
  } catch (error) {
    createAlert('¡Error!', 'Error al intentar guardar el archivo.', 'error');
  }
};

export const addConsultation = (quantity, type) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({
    quantity: quantity,
  });

  try {
    const res = await axios.put(`/api/profile/patient/${type}`, body, config);
    dispatch({
      type: ADD_CONSULTATION,
      payload: res.data,
    });
  } catch (error) {
    createAlert('¡Error!', 'Error al intentar guardar el archivo.', 'error');
  }
};

export const addTimetable = (data) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const { dia, desde, hasta } = data;
    const body = JSON.stringify({
      day: dia,
      from: desde,
      to: hasta,
    });
    const res = await axios.post('/api/timetable', body, config);
    dispatch({
      type: ADDTIMETABLE,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data;
    createAlert('¡Error!', errors.message, 'error');
  }
};

export const deleteTimetable = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/timetable/${id}`);
    dispatch({
      type: DELETETIMETABLE,
    });
  } catch (error) {
    createAlert('¡Error!', 'Error al intentar eliminar.', 'error');
  }
};

export const addSearchPatientId = (id) => (dispatch) => {
  dispatch({
    type: SEARCH_PATIENT,
    payload: id,
  });
};

// eslint-disable-next-line import/prefer-default-export
export const hours = [
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
  '24:00',
];

export const dias = [
  { name: 'Domingo', value: 1},
  { name: 'Lunes', value: 2},
  { name: 'Martes', value: 3},
  { name: 'Miercoles', value: 4},
  { name: 'Jueves', value: 5},
  { name: 'Viernes', value: 6},
  { name: 'Sabado', value: 7},
];
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { getListOnlineDoctor } from '../../api/doctor';
import Spinner from '../Spinner/Spinner';
import CardContactDoctor from '../Card/CardContactDoctor';
import {
  observerDoctorOnline,
  sendCall,
} from '../../websokect/patientWebSocket';
import {
  createVideoconference,
  lastConsultUser,
} from '../../api/videoconference';
import CardLastConsult from '../Card/CardLastConsult';
import CardUpcomingScheduling from '../Card/CardUpcomingScheduling';
import { upcomingScheduling } from '../../api/scheduling';
import ModalAddSchedule from '../../Components/Card/ModalAddconsulting/ModalAddConsulting';
import { changeConsultDoctorOffice } from '../../websokect/consultDoctorOfficeWebsocket';
import { Link } from 'react-router-dom';
import createAlert from '../../redux/actions/alert.action';
class TelemedicinaMovil extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      doctors: [],
      doctor: undefined,
      textSearch: '',
      lastConsult: [],
      upcomingScheduling: [],
      loadingCall: false,
      calling: false,
    };
  }

  componentDidMount() {
    const {
      auth: { token },
    } = this.props;
    const page = 0;
    /* Obteniendo las utlimas consultas del paciente */
    lastConsultUser({ token })
      .then((response) => {
        const { data } = response;
        this.setState({
          lastConsult: data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    /* Obteniendo el listados de citas proximas */
    upcomingScheduling({ token })
      .then((response) => {
        const { data } = response;
        this.setState({
          upcomingScheduling: data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    /* Obteniendo el listado del medico */
    getListOnlineDoctor({
      token,
      page,
    })
      .then((res) => {
        const { doctors } = res;
        this.setState({
          doctors: doctors,
          loading: false,
        });
      })
      .catch((error) => {
        createAlert(
          '¡Error!',
          'Error al obtener los doctores en línea.',
          'error'
        );
        console.log(error);
        this.setState({
          loading: false,
        });
      });
    observerDoctorOnline(() => {
      getListOnlineDoctor({
        token,
        page,
      })
        .then((res) => {
          const { doctors } = res;
          this.setState({
            doctors: doctors,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  handleVideconference = (doctor) => {
    if (!this.state.loadingCall) {
      this.setState({ loadingCall: true }, () => {});
      this.setState({ calling: true }, () => {});
      const {
        auth: { user: patient, token },
        history,
      } = this.props;

      if (doctor.profile.status === 'available') {
        createVideoconference({
          token: token,
          doctor: doctor._id,
          type: 'videocall',
        })
          .then((response) => {
            const { idVideo, idDoctorConsult } = response;
            changeConsultDoctorOffice({ idConsult: idDoctorConsult });
            sendCall(
              {
                doctorId: doctor._id,
                role: patient.role,
                idVideo: idVideo,
                type: 'videocall',
              },
              (data) => {
                if (data.status === 'success') {
                  history.push(`/dashboard/waitingcall/${idVideo}`);
                  this.setState({ loadingCall: false }, () => {});
                  this.setState({ calling: false }, () => {});
                } else {
                  this.setState({ calling: false }, () => {});
                  this.setState({ loadingCall: false }, () => {});
                  createAlert(
                    '¡Error!',
                    'Lo sentimos no pudimos crear la videollamada.',
                    'error'
                  );
                }
              }
            );
          })
          .catch((error) => {
            this.setState({ loadingCall: false }, () => {});
            this.setState({ calling: false }, () => {});
            console.log(error);
            createAlert(
              '¡Error!',
              'Lo sentimos no pudimos crear la videollamada.',
              'error'
            );
          });
      } else {
        this.setState({ loadingCall: false }, () => {});
        this.setState({ calling: false }, () => {});
        createAlert(
          '¡Error!',
          'Lo sentimos, el médico no se encuentra disponible.',
          'error'
        );
      }
    }
  };

  handleChatDoctor = (doctor) => {
    if (!this.state.loadingCall) {
      this.setState({ loadingCall: true }, () => {});
      this.setState({ calling: true }, () => {});
      const {
        auth: { user: patient, token },
        history,
      } = this.props;

      if (doctor.profile.status === 'available') {
        createVideoconference({
          token: token,
          doctor: doctor._id,
          type: 'chat',
        })
          .then((response) => {
            const { idVideo, idDoctorConsult } = response;
            changeConsultDoctorOffice({ idConsult: idDoctorConsult });
            sendCall(
              {
                doctorId: doctor._id,
                role: patient.role,
                idVideo: idVideo,
                type: 'chat',
              },
              (data) => {
                if (data.status === 'success') {
                  this.setState({ loadingCall: false }, () => {});
                  this.setState({ calling: false }, () => {});
                  history.push(`/dashboard/waitingcall/${idVideo}`);
                } else {
                  this.setState({ loadingCall: false }, () => {});
                  this.setState({ calling: false }, () => {});
                  createAlert(
                    '¡Error!',
                    'Lo sentimos no pudimos crear la videollamada.',
                    'error'
                  );
                }
              }
            );
            this.setState({ loadingCall: false }, () => {});
            this.setState({ calling: false }, () => {});
          })
          .catch((error) => {
            this.setState({ loadingCall: false }, () => {});
            this.setState({ calling: false }, () => {});
            console.log(error);
            createAlert(
              '¡Error!',
              'Lo sentimos no pudimos crear la videollamada.',
              'error'
            );
          });
      } else {
        this.setState({ loadingCall: false }, () => {});
        this.setState({ calling: false }, () => {});
        createAlert(
          '¡Error!',
          'Lo sentimos, el médico no se encuentra disponible.',
          'error'
        );
      }
    }
  };

  handleCallDoctor = (doctor) => {
    if (!this.state.loadingCall) {
      this.setState({ loadingCall: true }, () => {});
      this.setState({ calling: true }, () => {});
      const {
        auth: { user: patient, token },
        history,
      } = this.props;

      if (doctor.profile.status === 'available') {
        createVideoconference({
          token: token,
          doctor: doctor._id,
          type: 'call',
        })
          .then((response) => {
            const { idVideo, idDoctorConsult } = response;
            changeConsultDoctorOffice({ idConsult: idDoctorConsult });
            sendCall(
              {
                doctorId: doctor._id,
                role: patient.role,
                idVideo: idVideo,
                type: 'call',
              },
              (data) => {
                if (data.status === 'success') {
                  this.setState({ loadingCall: false }, () => {});
                  this.setState({ calling: false }, () => {});
                  history.push(`/dashboard/waitingcall/${idVideo}`);
                } else {
                  this.setState({ loadingCall: false });
                  createAlert(
                    '¡Error!',
                    'Lo sentimos no pudimos crear la videollamada.',
                    'error'
                  );
                }
              }
            );
          })
          .catch((error) => {
            this.setState({ loadingCall: false }, () => {});
            this.setState({ calling: false }, () => {});
            console.log(error);
            createAlert(
              '¡Error!',
              'Lo sentimos no pudimos crear la videollamada.',
              'error'
            );
          });
      } else {
        this.setState({ loadingCall: false }, () => {});
        this.setState({ calling: false }, () => {});
        createAlert(
          '¡Error!',
          'Lo sentimos, el médico no se encuentra disponible.',
          'error'
        );
      }
    }
  };

  handleDataDoctor = (doctor) => {
    this.setState({
      doctor,
    });
  };

  handleSearchDoctor = (event) => {
    this.setState({
      textSearch: event.target.value,
    });
  };

  render() {
    const {
      auth: { user },
      isOpenModalAddScheduling,
      doctorReference,
    } = this.props;
    const {
      doctors,
      loading,
      doctor: dataDoctor,
      textSearch,
      lastConsult,
      upcomingScheduling,
    } = this.state;
    const userAgent = navigator.userAgent;
    const isChrome = /Chrome/.test(userAgent);
    return (
      <>
        {isOpenModalAddScheduling && doctorReference !== null && (
          <ModalAddSchedule />
        )}
        <div className='container-fluid'>
          <div
            className='alert alert-light alert-dismissible border-0
        fade show mt-4 animate__animated animate__fadeInLeft text-justify p-3'
            role='alert'
            style={{ borderRadius: '0.5rem' }}
          >
            {!isChrome ? (
              <>
                <h6 className='text-secondarymdv font-weight-bold'> Aviso </h6>
                <p className='mt-2 p-0' style={{ fontSize: '13px' }}>
                  Te recomendamos usar el{' '}
                  <strong>Navegador Google Chrome</strong> para una mejor
                  experiencia en Telemedicina, si no lo tienes puedes{' '}
                  <Link
                    to={{
                      pathname:
                        'https://play.google.com/store/apps/details?id=com.android.chrome',
                    }}
                    target='_blank'
                    type='button'
                    className='btn btn-info btn-sm p-1'
                    style={{ borderRadius: '0.5rem' }}
                  >
                    Descárgalo aquí <i className='fab fa-chrome' />
                  </Link>{' '}
                  .
                </p>
              </>
            ) : (
              <></>
            )}

            <p className='mt-2 p-0' style={{ fontSize: '13px' }}>
              Recuerda que para usar correctamente las funciones de Telemedicina
              tienes que tener activo los permisos de tú{' '}
              <strong>cámara y micrófono</strong>, si no sabes como dar permisos
              desde tu dispositivo puedes{' '}
              <Link
                to={{
                  pathname:
                    'https://support.google.com/chrome/answer/2693767?hl=es&co=GENIE.Platform%3DAndroid&oco=0',
                }}
                target='_blank'
                className='btn btn-link bg-light btn-sm text-secondarymdv font-weight-bold'
              >
                ver los pasos aquí
              </Link>{' '}
              .
            </p>
            <button
              type='button'
              className='close'
              data-dismiss='alert'
              aria-label='Close'
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <h5 className='text-primary mt-4 mb-4 font-weight-bold text-center'>
            Telemedicina
          </h5>
          <div className='input-group mb-3'>
            <input
              type='text'
              className='form-control'
              id='Search'
              onChange={this.handleSearchDoctor}
              value={textSearch}
              name='searchdoctor'
              placeholder='&nbsp;Buscar Médico'
              aria-label='&nbsp;BUSCAR MÉDICO'
              aria-describedby='button-addon2'
            />
            <div className='input-group-append'>
              <button
                className='btn btn-light border border-right-rounded'
                type='button'
                id='button-addon2'
              >
                {' '}
                <i className='fas fa-search text-secondarymdv' />
              </button>
            </div>
          </div>

          <div className={doctors.length === 0 ? '' : 'scroll-doctor mt-2'}>
            {loading && <Spinner />}
            {!loading && (
              <>
                {doctors.length === 0 ? (
                  <p className='text-center text-black-50 pt-3 pb-2'>
                    No hay doctores en línea...
                  </p>
                ) : (
                  <>
                    {doctors.map((doctor) => {
                      if (textSearch) {
                        const stringRegex = textSearch.split(/ /).join('|');
                        const regexSearch = new RegExp(stringRegex, 'i');
                        if (
                          regexSearch.test(doctor.firstName) ||
                          regexSearch.test(doctor.lastName)
                        ) {
                          return (
                            <CardContactDoctor
                              key={doctor._id}
                              handleDataDoctor={() =>
                                this.handleDataDoctor(doctor)
                              }
                              image={
                                doctor.image
                                  ? `/api/profile/image/${doctor.image}`
                                  : undefined
                              }
                              name={
                                doctor.gender === 'male'
                                  ? `Dr. ${doctor.firstName} ${doctor.lastName}`
                                  : `Dra. ${doctor.firstName} ${doctor.lastName}`
                              }
                              specialty={doctor.profile.specialty}
                              appointment={doctor.profile.appointment}
                              status={doctor.profile.status}
                              handleVideoCall={() =>
                                this.handleVideconference(doctor)
                              }
                              handleChat={() => this.handleChatDoctor(doctor)}
                              rating={doctor.profile.rating}
                              loadingCall={this.state.loadingCall}
                              calling={this.state.calling}
                            />
                          );
                        } else {
                          return undefined;
                        }
                      } else {
                        return (
                          <CardContactDoctor
                            key={doctor._id}
                            handleDataDoctor={() =>
                              this.handleDataDoctor(doctor)
                            }
                            image={
                              doctor.image
                                ? `/api/profile/image/${doctor.image}`
                                : undefined
                            }
                            name={
                              doctor.gender === 'male'
                                ? `Dr. ${doctor.firstName} ${doctor.lastName}`
                                : `Dra. ${doctor.firstName} ${doctor.lastName}`
                            }
                            specialty={doctor.profile.specialty}
                            appointment={doctor.profile.appointment}
                            status={doctor.profile.status}
                            handleVideoCall={() =>
                              this.handleVideconference(doctor)
                            }
                            handleChat={() => this.handleChatDoctor(doctor)}
                            handleCall={() => this.handleCallDoctor(doctor)}
                            doctorId={doctor._id}
                            rating={doctor.profile.rating}
                            loadingCall={this.state.loadingCall}
                            calling={this.state.calling}
                          />
                        );
                      }
                    })}
                  </>
                )}
              </>
            )}
          </div>

          <div className='mt-4 mb-4 scroll-date'>
            <h5 className='text-secondarymdv mb-3'>Últimas Consultas</h5>
            {/* Start last date */}
            {lastConsult.length > 0 ? (
              lastConsult.map(({ doctor, createdAt, diagnosis }) => (
                <CardLastConsult
                  diagnosis={diagnosis}
                  image={
                    doctor.image
                      ? `/api/profile/image/${doctor.image}`
                      : undefined
                  }
                  date={moment(createdAt).format('LLLL')}
                />
              ))
            ) : (
              <p>No hay consultas resgitradas...</p>
            )}
            {/* Finish  */}
          </div>

          <div className='mt-4 mb-4'>
            <h5 className='text-secondarymdv mb-3'>Próximas Citas</h5>
            {/* Start next date */}
            {upcomingScheduling.length > 0 ? (
              upcomingScheduling.map(({ doctor, formatDate, hour }) => (
                <CardUpcomingScheduling
                  image={
                    doctor.image
                      ? `/api/profile/image/${doctor.image}`
                      : undefined
                  }
                  lastName={doctor.lastName}
                  date={`${moment(formatDate).format('LL')}, ${hour}`}
                />
              ))
            ) : (
              <p className='text-center'>No hay próximas citas.</p>
            )}
            {/* Finish  */}
          </div>
          {/* start modal card info */}
          <div
            className='modal fade'
            id='perfildoctorMovil'
            tabIndex='-1'
            role='dialog'
            aria-labelledby='exampleModalLabel'
            aria-hidden='true'
          >
            <div
              className='modal-dialog modal-dialog-centered modal-dialog-scrollable'
              role='document'
            >
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5
                    className='modal-title mx-auto text-primary'
                    id='exampleModalLabel'
                  >
                    Información del Médico
                  </h5>
                  <button
                    type='button'
                    className='close text-secondarymdv'
                    data-dismiss='modal'
                    aria-label='Close'
                  >
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div className='modal-body'>
                  <div className='container db-social'>
                    <div className='jumbotron jumbotron-fluid' />
                    <div className='container'>
                      <div className='row justify-content-center'>
                        <div className='col-xl-12 col-md-12 d-flex justify-content-center'>
                          <span>
                            <i className='fas fa-star c-star center' /> 3/5
                          </span>
                        </div>
                        <div className='col-xl-11'>
                          <div className='widget head-profile has-shadow'>
                            <div className='widget-body pb-0'>
                              <div className='row d-flex align-items-center'>
                                <div className='col-xl-12 col-md-12 d-flex justify-content-center'>
                                  <div className='image-default'>
                                    <img
                                      className='media-object img-circle img-doc-3'
                                      src={
                                        dataDoctor?.image
                                          ? `/api/profile/image/${dataDoctor.image}`
                                          : require('../../assets/img/avatars/avatar-doc-male.png')
                                      }
                                      alt='Perfil'
                                    />
                                    <br />
                                  </div>
                                  <div className='infos'>
                                    <h4 className='media-heading text-primary mt-5'>
                                      {dataDoctor && (
                                        <>
                                          {`${
                                            dataDoctor.gender === 'male'
                                              ? 'Dr.'
                                              : 'Dra.'
                                          } `}
                                          {dataDoctor.lastName}
                                        </>
                                      )}
                                    </h4>
                                    <span className='text-primary'>
                                      {' '}
                                      {dataDoctor?.profile.specialty}
                                    </span>
                                    <div className='location'>
                                      <div className='row center-div font-p'>
                                        <div className='outter2'>
                                          <h4 className='line mar-2 m-0 text-primary'>
                                            Perfil Profesional
                                          </h4>
                                          <div className='py-4'>
                                            <p className='clearfix'>
                                              <span className='font-blue-r'>
                                                Graduado de : &nbsp;
                                              </span>
                                              <span className='text-secondarymdv'>
                                                {dataDoctor?.profile.university}
                                              </span>
                                            </p>
                                            <p className='clearfix'>
                                              <span className='font-blue-r'>
                                                Experiencia : &nbsp;
                                              </span>
                                              <span className='text-secondarymdv'>
                                                {dataDoctor?.profile.experience}{' '}
                                                Años
                                              </span>
                                            </p>
                                            <p className='clearfix'>
                                              <span className='font-blue-r'>
                                                No de colegiatura : &nbsp;
                                              </span>
                                              <span className='text-secondarymdv'>
                                                {dataDoctor?.profile.college}
                                              </span>
                                            </p>
                                            <p className='clearfix'>
                                              <span className='font-blue-r'>
                                                Idiomas : &nbsp;
                                              </span>
                                              <span className='text-secondarymdv'>
                                                {dataDoctor?.profile.languages.map(
                                                  (text) => (
                                                    <span>{text},</span>
                                                  )
                                                )}
                                              </span>
                                            </p>
                                            <p className='clearfix'>
                                              <span className='font-blue-r'>
                                                Maestrías : &nbsp;
                                              </span>
                                              <span className='text-secondarymdv'>
                                                {/* Maestria */}
                                                {dataDoctor?.profile.master}
                                              </span>
                                            </p>
                                            <p className='clearfix'>
                                              <span className='font-blue-r'>
                                                Sub Especialidades : &nbsp;
                                              </span>
                                              <span className='text-secondarymdv '>
                                                {dataDoctor?.profile.subSpecialties.map(
                                                  (text) => (
                                                    <span>{text},</span>
                                                  )
                                                )}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='row center-div font-p'>
                                        <div className='outter2'>
                                          <h4 className='line mar-2 m-0 text-primary'>
                                            Mensaje del Médico
                                          </h4>
                                          <li className='text-secondarymdv'>
                                            {dataDoctor?.profile.about}
                                          </li>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => ({
  auth: state.auth,
  isOpenModalAddScheduling: state.ui.schedulingModal.open,
  doctorReference: state.reference.userRef,
});

export default connect(mapStatetoProps)(TelemedicinaMovil);

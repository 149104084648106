import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Spinner,
  Form,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { connect, useDispatch, useSelector } from 'react-redux';
import { checkcellphone } from '../../redux/actions/auth.action';
import { addUserByCheker } from '../../redux/actions/presential.action';
import { changeAddUserFlag } from '../../redux/actions/references';
import { countries } from '../../utils/countries';
import ModalCellphoneVerify from '../Modal/ModalCellphoneVerify';

const AddPatientChecker = ({
  emailPatient,
  searchType,
  cellphonePatient,
  dialCodePatient,
  idTypePatient,
  dniPatient,
  addUserByCheker,
  checkcellphone,
  ischecker,
}) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    dni: dniPatient,
    idType: idTypePatient || 'national',
    gender: 'female',
    email: emailPatient,
    cellphone: cellphonePatient,
    dialCode: dialCodePatient,
    registerType: searchType === 'cellphone' ? 'cellphone' : 'email',
    country: countries[0].name,
    bornAt: '',
  });

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const {
    firstName,
    lastName,
    dni,
    idType,
    gender,
    email,
    bornAt,
    dialCode,
    registerType,
    cellphone,
    country,
  } = formData;

  const [showModal, setShowModal] = useState(false);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  const setFormat = () => {
    if (idType === 'national') {
      return '####-####-#####';
    } else if (idType === 'rtn') {
      return '####-####-######';
    }
  };
  const setFormatPhone = () => {
    return '########';
  };

  const handleChange = (e) => {
    if (e.target.name === 'country') {
      const tempCountry = countries.filter(
        (pais) => pais.name === e.target.value
      );
      setFormData({
        ...formData,
        dialCode: tempCountry[0].dial_code,
        country: tempCountry[0].name,
        email: '',
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Validar que el teléfono no esté en uso
      if (registerType === 'cellphone') {
        if (user?.role === 'callcenter') {
          await addUserByCheker({ ...formData, ischecker: ischecker });
        } else {
          const result = await checkcellphone(formData);
          if (result.success && isMounted) {
            setShowModal(true); // Mostrar el modal si la verificación del teléfono es exitosa
          }
        }
        dispatch(changeAddUserFlag(false));
      } else {
        await addUserByCheker({ ...formData, ischecker: ischecker });
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error);
    } finally {
      if (isMounted) {
        setLoading(false);
      }
    }
  };

  /*if (loading) {
    return (
      <div className='text-center'>
        <Spinner />
      </div>
    );
  }*/

  return (
    <div className='bg-white' style={{ position: 'relative' }}>
      <ModalCellphoneVerify formData={formData} presential />
      <div className='profile-header'>
        <h3 className='text-white text-center pt-4'>Agregar pacientes</h3>
      </div>
      <Row className='p-2' style={{ paddingTop: 15 }}>
        <Col md={6} xs={12}>
          <label
            className='text-secondarymdv font-weight-light'
            style={{ textAlign: 'center', margin: 0, width: '100%' }}
          >
            Nombre
          </label>
          <div className='form-group'>
            <input
              type='text'
              className='form-control center'
              id='inputEmail'
              name='firstName'
              value={firstName}
              onChange={handleChange}
            />
          </div>
          <label
            className='text-secondarymdv font-weight-light'
            style={{ textAlign: 'center', margin: 0, width: '100%' }}
          >
            Apellido
          </label>
          <div className='form-group'>
            <input
              type='email'
              className='form-control center'
              id='inputEmail'
              name='lastName'
              value={lastName}
              onChange={handleChange}
            />
          </div>
          <label
            className='text-secondarymdv font-weight-light'
            style={{ textAlign: 'center', margin: 0, width: '100%' }}
          >
            Identidad
          </label>
          <div className='form-group'>
            <select
              className='form-control'
              id='exampleFormControlSelect1'
              name='idType'
              value={idType}
              onChange={handleChange}
              placeholde='Tipo de identificación'
            >
              <option value='national'>ID nacional</option>
              <option value='foreign'>ID extranjero</option>
              <option value='rtn'>RTN</option>
            </select>
          </div>
          <div className='form-group'>
            {idType === 'foreign' ? (
              <input
                type='text'
                className='form-control center'
                name='dni'
                value={dni}
                onChange={handleChange}
              />
            ) : (
              <NumberFormat
                className='form-control center'
                format={`${setFormat()}`}
                mask='_'
                name='dni'
                value={dni}
                onChange={(e) => handleChange(e)}
              />
            )}
          </div>
        </Col>
        <Col md={6} xs={12}>
          <label
            className='text-secondarymdv font-weight-light'
            style={{ textAlign: 'center', margin: 0, width: '100%' }}
          >
            Fecha de nacimiento
          </label>
          <div className='form-group'>
            <input
              type='date'
              className='form-control center'
              placeholder='Fecha de Nacimiento'
              name='bornAt'
              onChange={handleChange}
              value={bornAt}
            />
          </div>
          <label
            className='text-secondarymdv font-weight-light'
            style={{ textAlign: 'center', margin: 0, width: '100%' }}
          >
            Género
          </label>
          <div className='form-group'>
            <select
              className='form-control'
              id='exampleFormControlSelect1'
              name='gender'
              value={gender}
              onChange={handleChange}
              placeholde='Género'
            >
              <option value='female'>Femenino</option>
              <option value='male'>Masculino</option>
            </select>
          </div>
          <div className='form-group'>
            <label className='text-secondarymdv font-weight-light'>
              Tipo de registro
            </label>
            <select
              className='form-control bg-light'
              id='exampleFormControlSelect1'
              name='registerType'
              value={registerType}
              onChange={(e) => handleChange(e)}
              placeholde='Tipo de registro'
            >
              <option value='email'>Correo electrónico</option>
              <option value='cellphone'> Teléfono</option>
            </select>
          </div>
          {registerType === 'cellphone' && (
            <>
              <label className='text-secondarymdv font-weight-light float-left'>
                Pais
              </label>
              <div>
                <Form.Control
                  as='select'
                  style={{ marginBottom: 15 }}
                  onChange={handleChange}
                  name='country'
                  value={country}
                >
                  {countries.map((pais, key) => (
                    <option key={pais.code}>{pais.name}</option>
                  ))}
                </Form.Control>
              </div>
              <div className='form-group'>
                <InputGroup>
                  <InputGroup.Prepend
                    value={dialCode}
                    name='dialCode'
                    onChange={handleChange}
                  >
                    <InputGroup.Text
                      style={{
                        borderTopLeftRadius: 20,
                        borderBottomLeftRadius: 20,
                      }}
                    >
                      {dialCode}
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <NumberFormat
                    placeholder='Telefono'
                    className='form-control bg-light'
                    format={`${setFormatPhone()}`}
                    mask='_'
                    name='cellphone'
                    value={cellphone}
                    onChange={handleChange}
                  />
                </InputGroup>
              </div>
            </>
          )}
          {registerType === 'email' && (
            <>
              <label className='text-secondarymdv font-weight-light float-left'>
                Correo Electrónico
              </label>
              <div className='input-group'>
                <div className='input-group-prepend'>
                  <span
                    className='input-group-text border-left-rounded bg-light'
                    id='basic-addon1'
                  >
                    <img
                      src={require('../../assets/img/icons/@.png')}
                      alt='imagen'
                      width='18'
                      className='img-fluid'
                    />
                  </span>
                </div>
                <input
                  type='email'
                  className='form-control bg-light'
                  id='inputEmail'
                  name='email'
                  value={email}
                  onChange={handleChange}
                />
              </div>
            </>
          )}
        </Col>
      </Row>
      <div className='text-center'>
        <button
          type='submit'
          className='btn btn-primary mt-4 mb-3 pl-5 pr-5'
          onClick={handleSubmit}
          style={{
            opacity:
              (firstName.trim().length < 3 ||
                lastName.trim().length < 3 ||
                bornAt.trim().length < 3 ||
                dni.trim().length < 3 ||
                (registerType === 'cellphone' && cellphone.trim().length < 7) ||
                (registerType === 'email' && email.trim().length < 3)) &&
              '70%',
          }}
          disabled={
            firstName.trim().length < 3 ||
            lastName.trim().length < 3 ||
            bornAt.trim().length < 3 ||
            dni.trim().length < 3 ||
            (registerType === 'cellphone' && cellphone.trim().length < 7) ||
            (registerType === 'email' && email.trim().length < 3) ||
            loading
          }
        >
          {loading ? (
            <Spinner
              style={{
                width: 20,
                height: 20,
                color: '#ffff',
                fontSize: 8,
              }}
              animation='border'
            />
          ) : (
            'GUARDAR'
          )}
        </button>
      </div>
    </div>
  );
};

export default connect(null, {
  addUserByCheker,
  checkcellphone,
})(AddPatientChecker);

import {
  ADD_EMPLOYEE,
  UPDATE_EMPLOYEE,
  LIST_EMPLOYEES,
  GIVE_EMPLOYEE,
  DOWN_EMPLOYEE,
  GIVE_ALL_EMPLOYEES,
  DOWN_ALL_EMPLOYEES,
  CHANGE_MODAL_DEPENDENTS,
  LIST_DEPENDENTS_EMPLOYEE
} from "../types";

const initialState = {
  listEmployees: [],
  selectedEmployee: [],
  usingAddDependentModal: false,
  listEmployeeDependent: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_EMPLOYEE:
      return {
        ...state,
        itemployeesListems: [...state.employeesList, payload],
      };

    case UPDATE_EMPLOYEE:
      return {};

    case GIVE_EMPLOYEE:
      return {
        ...state,
        selectedEmployee: [...state.selectedEmployee, payload],
      };
    case GIVE_ALL_EMPLOYEES:
      return {
        ...state,
        selectedEmployee: [],
        selectedEmployee: payload,
      };
    case DOWN_EMPLOYEE:
      return {
        ...state,
        selectedEmployee: state.selectedEmployee.filter(
          (item) => item.dni !== payload
        ),
      };
    case DOWN_ALL_EMPLOYEES:
      return {
        ...state,
        selectedEmployee: [],
      };
    case CHANGE_MODAL_DEPENDENTS:
      return {
        ...state,
        usingAddDependentModal: payload,
      }
    case LIST_EMPLOYEES:
      return {
        ...state,
        listEmployees: payload,
      };  
    case LIST_DEPENDENTS_EMPLOYEE:
      return {
        ...state,
        listEmployeeDependent: payload,
      };  
    default:
      return state;
  }
}

/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable global-require */
import React from 'react';
import {connect} from 'react-redux';
import {getOneVideoconference} from '../../api/videoconference';
import Spinner from '../Spinner/Spinner';
import {
  cancelVideoconferencePatient,
  observerAcceptVideoconferencePatient,
  observerCancelVideoconferenceDoctor,
} from '../../websokect/notificationsWebsocket';
import createAlert from '../../redux/actions/alert.action';

class WaitingScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      doctor: undefined,
    };
  }

  componentDidMount() {
    const {token, match, history} = this.props;
    const {params} = match;
    const {roomId: idVideo} = params;
    getOneVideoconference({token, idVideo})
      .then((res) => {
        const {doctor, videoconference} = res;
        this.setState({
          doctor: doctor,
          loading: false,
        });
        observerAcceptVideoconferencePatient({idVideo}, () => {
          if (videoconference.type === 'videocall') {
            window.location.href = `/videoconference/${idVideo}`;
          } else if (videoconference.type === 'chat') {
            history.push(`/dashboard/chat/${idVideo}`);
          } else if (videoconference.type === 'call') {
            window.location.href = `/call/${idVideo}`;
          }
        });
        observerCancelVideoconferenceDoctor({idVideo}, () => {
          createAlert('¡Atención!', 'El doctor ha cancelado la llamada.', 'info');
          history.push('/dashboard');
        });
      })
      .catch((error) => {
        console.log(error);
        createAlert(
          '¡Error!',
          'Lo sentimos hubo un error al realizar la llamada.',
          'error',
        );
        history.push('/dashboard');
      });
  }

  handleCancelVideoconference = () => {
    const {match, history} = this.props;
    const {roomId} = match.params;
    cancelVideoconferencePatient({idVideo: roomId}, (data) => {
      if (data.status === 'success') {
        history.push('/dashboard');
      } else {
        createAlert(
          '¡Error!',
          'Lo sentimos no se pudo cancelar la videollamada.',
          'error',
        );
      }
    });
  };

  render() {
    const {doctor, loading} = this.state;
    if (loading) {
      return <Spinner />;
    }
    return (
      <div className="container pt-5">
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="card shadow-sm profile-card-1 border-0">
              <img
                src={require('../../assets/img/Doctor-stock.jpg')}
                alt="profile-sample1"
                className="background img-fluid"
              />
              <img
                src={require('../../assets/img/female.png')}
                alt="profile-image"
                className="profile call-animation img-fluid mb-5"
              />
              {doctor && (
                <div className="text-center card-content card-body border-top-0">
                  <h2 className="text-dark mt-5">
                    ¡Espera, en un momento
                    {`${
                      doctor.publicData?.gender === 'male'
                        ? ' el Dr. '
                        : ' la Dra. '
                    } `}
                    {doctor.publicData?.lastName}
                    {' '}
                    te atenderá!
                  </h2>
                  <div className="icon-block pb-5">
                    <span>
                      <i
                        onClick={this.handleCancelVideoconference}
                        className="fas fa-phone-slash"
                      />
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(WaitingScreen);

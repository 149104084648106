/* eslint-disable react/jsx-indent */
/* eslint-disable function-paren-newline */
/* eslint-disable comma-dangle */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import 'moment/locale/es';
import moment from 'moment';
import Spinner from '../Spinner/Spinner';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getProfileDoctorLoggedIn } from '../../api/doctor';
import { changeStatusDoctor } from '../../websokect/doctorWebsockets';
import {
  addProfileDoctorCvFile,
  addProfileImage,
} from '../../redux/actions/profile.action';
import imgAvatarTemp from '../../assets/img/avatars/avatar-doc-male.png';
import { upcomingScheduling } from '../../api/scheduling';
import { totalVideoconference } from '../../api/videoconference';

class HomeDoctorMovil extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      statusDoctor: '',
      listUpcomingScheduling: [],
      total: 0,
      totalVideocall: 0,
      totalChat: 0,
    };
  }

  componentDidMount() {
    const {
      auth: { token },
    } = this.props;
    upcomingScheduling({ token })
      .then((res) => {
        const { data } = res;
        this.setState({
          listUpcomingScheduling: data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    totalVideoconference({ token })
      .then((response) => {
        const { totalVideoconference, totalVideo, totalChat } = response;
        this.setState({
          total: totalVideoconference,
          totalVideocall: totalVideo,
          totalChat: totalChat,
        });
      })
      .catch((error) => console.log(error));
    getProfileDoctorLoggedIn({ token })
      .then((res) => {
        const { profile } = res;
        this.setState({
          statusDoctor: profile.status,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
        alert('Error al obtener los datos');
      });
  }

  handleClickStatus = (text) => {
    const {
      auth: { user, token },
    } = this.props;
    const { _id, role } = user;
    changeStatusDoctor({ doctorId: _id, role: role, status: text }, () => {
      getProfileDoctorLoggedIn({ token })
        .then((res) => {
          const { profile } = res;
          this.setState({
            statusDoctor: profile.status,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  handleUploadCvFile = () => {
    const { documents } = this.props;
    const { document } = window;
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.png, .jpg, .jpeg, .pdf';
    input.onchange = () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('file', file);
      addProfileDoctorCvFile(formData, documents);
    };
    input.click();
  };

  handleUploadImage = () => {
    const {
      profile: { image },
      addProfileImage,
    } = this.props;
    const { document } = window;
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.png, .jpg, .jpeg';
    input.onchange = () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('file', file);
      addProfileImage(formData, image);
    };
    input.click();
  };

  render() {
    const {
      loading,
      statusDoctor,
      listUpcomingScheduling,
      total,
      totalChat,
      totalVideocall,
    } = this.state;
    const {
      auth: { user },
      profile: {
        dni,
        country,
        cellphone,
        bornAt,
        university,
        experience,
        college,
        languages,
        specialty,
        subSpecialties,
        about,
        master,
        documents,
        rating,
      },
    } = this.props;
    if (loading) {
      return <Spinner />;
    }
    return (
      <div>
        <div className="container-fluid display-table">
          <div className="col-md-12 col-sm-12 display-table-cell v-align">
            <div className="row">
              <div className="col-md-7 col-sm-12 col-xs-12 gutter">
                <div className="card text-center shadow-sm border-0">
                  <div className="card-body border-0">
                    <div className="row blue-card">
                      <div className="col-12">
                        <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip-bottom">Cambiar foto</Tooltip>
                          }
                        >
                          <button
                            type
                            className="avatar-doctor-button-container"
                            onClick={this.handleUploadImage}
                          >
                            <div
                              style={{
                                backgroundImage: `${
                                  user.image === null ||
                                  user.image === '' ||
                                  user.image === undefined
                                    ? `url(${imgAvatarTemp})`
                                    : `url(/api/profile/image/${user.image})`
                                }`,
                                backgroundSize: 'cover',
                                borderColor: '#001689',
                              }}
                              className="media-object img-fluid img-circle img-doc-p "
                            />
                          </button>
                        </OverlayTrigger>
                      </div>
                      <div className="col-12 center">
                        <h5 className="text-secondarymdv mt-3">
                          {`Bienvenid${
                            user.gender === 'male' ? 'o Dr.' : 'a Dra.'
                          }`}
                        </h5>
                        <h5 className="text-primary mt-1 mb-0">
                          Perfil&nbsp;
                          <b className="center">
                            {`${user.firstName.split(
                              ' ',
                              1
                            )} ${user.lastName.split(' ', 1)}`}
                          </b>
                        </h5>
                        <h6>
                          <span className="badge bg-light text-secondarymdv">
                            {specialty}
                          </span>
                        </h6>
                        <small
                          className="text-primary"
                          style={{ fontSize: '20px' }}
                        >
                          {rating}/5 <i className="fas fa-star star-y"></i>
                        </small>
                      </div>
                    </div>

                    <div className="row center-rw">
                      <label className="check-green">
                        <span>Disponible &nbsp;</span>
                        <input
                          type="radio"
                          name="options1"
                          value="available"
                          checked={statusDoctor === 'available'}
                          onClick={() => this.handleClickStatus('available')}
                        />
                      </label>
                      <label className="check-red">
                        <span>Ocupado &nbsp;</span>
                        <input
                          type="radio"
                          name="options2"
                          value="occupied"
                          checked={statusDoctor === 'occupied'}
                          onClick={() => this.handleClickStatus('occupied')}
                        />
                      </label>
                      <label className="check-yellow">
                        <span>No Disponible &nbsp;</span>
                        <input
                          type="radio"
                          name="options3"
                          value="not available"
                          checked={statusDoctor === 'not available'}
                          onClick={() =>
                            this.handleClickStatus('not available')
                          }
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-6 mb-4">
                    <Link to="/dashboard/agenda">
                      <div className="card text-center shadow-sm border-0 h-100">
                        <div className="card-body border-0">
                          <img
                            src={require('../../assets/img/icons/cita-medica.svg')}
                            alt="loginlogo"
                            className="icon-card"
                            data-aos="fade-right"
                          />
                          <small className="text-primary">Ver Agenda</small>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-6 mb-4">
                    <Link to="/dashboard/details">
                      <div className="card text-center shadow-sm border-0 h-100">
                        <div className="card-body border-0">
                          <img
                            src={require('../../assets/img/icons/interview.png')}
                            alt="loginlogo"
                            className="icon-card"
                            data-aos="fade-right"
                          />
                          <small className="text-primary">
                            Detalle Consulta
                          </small>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-6">
                    <Link to="/dashboard/calls">
                      <div className="card text-center shadow-sm border-0 h-100">
                        <div className="card-body border-0">
                          <img
                            src={require('../../assets/img/icons/hablar.svg')}
                            alt="loginlogo"
                            className="icon-card"
                            data-aos="fade-right"
                          />
                          <small className="text-primary">
                            Detalle Llamada
                          </small>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-6">
                    <Link to="/dashboard/patients">
                      <div className="card text-center shadow-sm border-0 h-100">
                        <div className="card-body border-0">
                          <img
                            src={require('../../assets/img/icons/informe-medico.svg')}
                            alt="loginlogo"
                            className="icon-card"
                            data-aos="fade-right"
                          />
                          <small className="text-primary">
                            Detalle Pacientes
                          </small>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="row about-list">
                  <div className="col-12 col-sm-12 col-md-6">
                    <div className="card mb-3 p-1 border-0 shadow-sm">
                      <div className="card-body border-0">
                        <h5 className="font-weight-bold mt-2 text-primary">
                          Perfil Personal
                        </h5>
                        <h6 className="text-primary">
                          Nombre Completo: &nbsp;
                          <small class="text-muted">{`${user.firstName.split(
                            ' ',
                            1
                          )} ${user.lastName.split(' ', 1)}`}</small>
                        </h6>
                        <h6 className="text-primary">
                          Correo Electrónico: &nbsp;
                          <small class="text-muted text-truncate">
                            {user.email}
                          </small>
                        </h6>
                        <h6 className="text-primary">
                          N° Identidad: &nbsp;
                          <small class="text-muted">{dni}</small>
                        </h6>
                        <h6 className="text-primary">
                          Fecha de Nacimiento: &nbsp;
                          <small class="text-muted">
                            {moment(bornAt).locale('es').format('L')}
                          </small>
                        </h6>
                        <h6 className="text-primary">
                          Nacionalidad: &nbsp;
                          <small class="text-muted">{country}</small>
                        </h6>
                        <h6 className="text-primary">
                          Género: &nbsp;
                          <small class="text-muted">
                            {user.gender === 'male' ? 'Masculino' : 'Femenino'}
                          </small>
                        </h6>
                        <h6 className="text-primary">
                          País: &nbsp;
                          <small class="text-muted">{country}</small>
                        </h6>
                        <h6 className="text-primary">
                          Tel.: &nbsp;
                          <small class="text-muted">{cellphone}</small>
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-6 ">
                    <div className="card mb-3 p-1 border-0 shadow-sm">
                      <div className="card-body border-0">
                        <h5 className="font-weight-bold mt-2 text-primary">
                          Perfil Profesional
                        </h5>
                        {/* <div className="row font-blue">
                          <h1>Perfil Profesional</h1>
                        </div> */}
                        <h6 className="text-primary">
                          Universidad: &nbsp;
                          <small class="text-muted">{university}</small>
                        </h6>
                        <h6 className="text-primary">
                          Experiencia: &nbsp;
                          <small class="text-muted">
                            {' '}
                            {`${experience} ${
                              Number(experience) === 1 ? 'año' : 'años'
                            }`}
                          </small>
                        </h6>
                        <h6 className="text-primary">
                          N° Colegiatura: &nbsp;
                          <small class="text-muted">{college}</small>
                        </h6>
                        <h6 className="text-primary">
                          Idiomas: &nbsp;
                          <small class="text-muted">
                            {' '}
                            {languages.length > 0
                              ? languages.map((language, index) => (
                                  <span key={index}>
                                    {`${language}${
                                      index !== languages.length - 1 ? ', ' : ''
                                    }`}
                                  </span>
                                ))
                              : 'Sin especificar'}
                          </small>
                        </h6>
                        <h6 className="text-primary">
                          Maestría: &nbsp;
                          <small class="text-muted"> {master}</small>
                        </h6>
                        <h6 className="text-primary">
                          Sub especialidadades: &nbsp;
                          <small class="text-muted">
                            {' '}
                            {subSpecialties.length > 0
                              ? subSpecialties.map((sub, index) => (
                                  <span key={index}>
                                    {`${sub}${
                                      index !== subSpecialties.length - 1
                                        ? ', '
                                        : ''
                                    }`}
                                  </span>
                                ))
                              : 'Sin especificar'}
                          </small>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-5 col-sm-12 col-xs-12 gutter">
                <div className="sales report mb-4">
                  <div className="row mar-2">
                    <h2 className="text-primary">Mis Cifras</h2>
                  </div>
                  <div className="counter">
                    <div className="row">
                      <div className="col-4 col-lg-4">
                        <div className="count-data text-center">
                          <h6
                            className="count h2 font-blue"
                            data-to="150"
                            data-speed="150"
                          >
                            {total}
                          </h6>
                          <p className="m-0px font-w-600">Consultas</p>
                        </div>
                      </div>
                      <div className="col-4 col-lg-4">
                        <div className="count-data text-center">
                          <h6
                            className="count h2 font-blue"
                            data-to="850"
                            data-speed="850"
                          >
                            {totalVideocall}
                          </h6>
                          <p className="m-0px font-w-600">Llamadas</p>
                        </div>
                      </div>
                      <div className="col-4 col-lg-4">
                        <div className="count-data text-center">
                          <h6
                            className="count h2 font-blue"
                            data-to="190"
                            data-speed="190"
                          >
                            {totalChat}
                          </h6>
                          <p className="m-0px font-w-600">Chats</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sales report mb-3">
                  <div className="row mar-2">
                    <h2 className="text-primary">Próximas Citas</h2>
                  </div>
                  {listUpcomingScheduling.length > 0 ? (
                    listUpcomingScheduling.map(
                      ({ patient, day, month, year, hour }) => (
                        <>
                          <div className="media">
                            <a className="pull-left" href="#">
                              <img
                                className="media-object img-circle img-doc-2"
                                src={
                                  patient.image
                                    ? `/api/profile/image/${patient.image}`
                                    : require('../../assets/img/avatars/avatar-doc-male.png')
                                }
                              />
                            </a>
                            <div className="media-body">
                              <div className="row margr-2">
                                <span>
                                  Cita con paciente{' '}
                                  {`${patient.firstName} ${patient.lastName}`}
                                </span>
                              </div>
                              <div className="row margr-2">
                                <span>
                                  {`${moment(
                                    new Date(year, month - 1, day)
                                  ).format('LLL')}, ${hour}`}
                                </span>
                              </div>
                            </div>

                            <div className="media-languaje">
                              <div className="row">
                                <i
                                  className="far fa-calendar-alt i-plus-d"
                                  aria-hidden="true"
                                ></i>
                                <span className="i-plus-l">
                                  {' '}
                                  &nbsp; Pendiente{' '}
                                </span>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </>
                      )
                    )
                  ) : (
                    <p className="pl-3">No hay Datos...</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile.profile,
});

export default connect(mapStateToProps, {
  addProfileDoctorCvFile,
  addProfileImage,
})(HomeDoctorMovil);

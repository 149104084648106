/* eslint-disable global-require */
/* eslint-disable no-shadow */
/* eslint-disable arrow-parens */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {connect} from 'react-redux';
import '../../stylesheets/scheduling.css';

import {useHistory} from 'react-router-dom';
import {
  openModalScheduleDoctorList,
  closeModalScheduleDoctorList,
  openModalAddScheduling,
  closeModalAddScheduling,
} from '../../redux/actions/ui.action';
import {selectConsultory} from '../../redux/actions/consultories.action';

import {getReference} from '../../redux/actions/references';

const days = {
  1: 'Lunes',
  2: 'Martes',
  3: 'Miercoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sábado',
  7: 'Domingo',
};

const CardConsultingRoom = ({selectOffice, office, selectConsultory}) => (
  <div className="card border-0 shadow-sm m-1" style={{borderRadius: '1rem'}}>
    <div className="card-body border-0">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
          <div className="mb-2 mt-2">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="m-0 text-primary text-uppercase">
                    {' '}
                    <i className="fas fa-hospital-alt" />
                    {' '}
                    {office.name}
                  </h5>
                  {' '}
                  <div className="d-flex">
                    {/* <h5 className="text-secondarymdv font-weight-bold">#5</h5> */}
                  </div>
                </div>
                <p className="mb-0 text-black-50 font-weight-bold">
                  <i className="fas fa-directions" />
                  {' '}
                  {office.direction}
                  <span className="ml-1">| Tegucigalpa </span>
                </p>
                <p className="text-black-50 text-justify">{office.description}</p>
                <p className="mb-0 text-secondary text-right">
                  <i className="fas fa-clock" />
                  {' '}
                  {`${days[office.initialDay] || ''} a ${
                    days[office.finalDay] || ''
                  }`}
                  {' '}
                  |
                  {' '}
                  {/* eslint-disable-next-line max-len */}
                  {`${office.initialHour ? `${office.initialHour}:00` : ''}${
                    office.initialAmPm || ''
                  } - ${office.finalHour ? `${office.finalHour}:00` : ''}${
                    office.finalAmPm || ''
                  }`}
                </p>
                {/* <p className="m-0 text-secondarymdv text-right text-uppercase">
                  <b>Farmacia:</b>
                  {' '}
                  K122 KIELSA TGU UNO LOARQUE
                </p> */}
                {' '}
                <div className="float-right mt-2">
                  <button
                    type="button"
                    className="btn btn-info btn-block"
                    style={{
                      borderRadius: '0.8rem',
                    }}
                    onClick={() => {
                      selectOffice(office);
                      selectConsultory(office);
                    }}
                  >
                    <small className="font-weight-bold">
                      <i className="fas fa-check-circle mr-2" />
                      Seleccionar Consultorio
                  </small>
                  </button>
                </div>
                {' '}
              </div>
              {' '}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
const mapStateToProps = (state) => ({
  isPresential: state.scheduling.presentialConsultation,
  isOnline: state.scheduling.onlineConsultation,
});

export default connect(mapStateToProps, {
  closeModalScheduleDoctorList,
  openModalScheduleDoctorList,
  openModalAddScheduling,
  closeModalAddScheduling,
  getReference,
  selectConsultory,
})(CardConsultingRoom);

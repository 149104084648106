/* eslint-disable no-shadow */
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Spinner from '../../../Components/Spinner/Spinner';
import {getSchedule} from '../../../redux/actions/scheduling.action';
import Scheduling from '../../../Components/Patient/Scheduling/Scheduling';
import {validUserPatient} from '../../../api/user';
import NoValidPatient from '../../NoValidPatient';

import '../../../stylesheets/scheduling.css';

const SchedulingPage = ({getSchedule, loaded, token}) => {
  const [loading, setLoading] = useState(true);
  const [validPatient, setValidPatient] = useState(true);
  useEffect(() => {
    getSchedule('patient');
    validUserPatient({token}).then(() => {
      setValidPatient(true);
      setLoading(false);
    }).catch(() => {
      setValidPatient(false);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Spinner />;
  }

  if (!validPatient) {
    return <NoValidPatient />;
  }

  if (loaded) {
    return (
      <div>
        <Scheduling />
      </div>
    );
  } else {
    return <Spinner />;
  }
};
// eslint-disable-next-line arrow-parens
const mapStateToProps = (state) => ({
  loaded: state.scheduling.loaded,
  token: state.auth.token,
});
export default connect(mapStateToProps, {getSchedule})(SchedulingPage);

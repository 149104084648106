import React from "react";

const ModalTermConditions = () => (
  <div
    className="modal fade"
    id="ModalTermConditions"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="ModalTermConditions"
    aria-hidden="true"
  >
    <div
      className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl"
      role="document"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h3
            className="modal-title text-primary m-auto"
            id="ModalTermConditions"
          >
            TÉRMINOS Y CONDICIONES
          </h3>
        </div>
        <div className="modal-body text-justify">
          <h5 className="text-secondarymdv font-weight-bold">
            TÉRMINOS Y CONDICIONES HISTORIA CLÍNICA
          </h5>
          <p>
            La historia clínica creada para cada usuario/paciente del sitio
            virtual www.kielsaclinica.com. y atención presencial por parte de
            KIELSA CLÍNICA Y LAB. se regirá bajo las disposiciones contenidas en
            el Código de Salud de la República de Honduras, demás normas legales
            aplicables y vigentes en la República de Honduras, así como por las
            estipulaciones que se indican en el presente documento, previas las
            siguientes definiciones:
            <br />
            <br />
            1. Historia Clínica: documento privado, obligatorio y sometido a
            reserva, en el cual se registran cronológicamente las condiciones de
            salud del paciente, los actos médicos y los demás procedimientos
            ejecutados por el equipo de salud que interviene en su atención.
            Dicho documento únicamente puede ser conocido por terceros previa
            autorización del paciente o en los casos previstos por la ley.
            <br />
            <br />
            2. Estado de salud: El estado de salud del paciente se registra en
            los datos e informes acerca de la condición somática, psíquica,
            social, cultural, económica y medioambiental que pueden incidir en
            la salud del usuario.
            <br />
            <br />
            3. Profesional de la Salud: Es el Usuario/Médico que teniendo un
            amplio estudio, experiencia y conocimiento sobre el área de la salud
            de la que tratará al paciente, el cual se encuentra adscrito a la
            plataforma virtual www.kielsaclinica.com y está avalado para prestar
            servicios de salud en Honduras .
            <br />
            <br />
            4. Historia Clínica para efectos archivísticos: Se entiende como el
            expediente conformado por el conjunto de documentos en los que se
            efectúa el registro obligatorio del estado de salud, los actos
            médicos y demás procedimientos ejecutados por el equipo de salud que
            interviene en la atención de un paciente, el cual tiene el carácter
            de reservado.
            <br />
            <br />
            5. Características que debe tener la Historia Clínica: Integralidad,
            secuencialidad, racionalidad científica, disponibilidad y
            oportunidad.
            <br />
            <br />
            Los profesionales de la salud que intervienen directamente en la
            atención a un Usuario/Paciente tienen la obligación de registrar sus
            observaciones, conceptos, decisiones y resultados de las acciones en
            salud desarrolladas en una consulta médica en la historia clínica de
            cada paciente, los datos incluidos en la historia clínica se
            reputarán como datos sensibles y tendrán un tratamiento de
            confidencialidad, conforme a la política de tratamiento de datos
            personales. Dichos datos solo podrán ser compartidos e
            intercambiados bajo la expresa autorización de su titular y con el
            único objetivo de llevar a cabo una consulta completa en miras al
            beneficio y orientación del Usuario/Paciente.
            <br />
            <br />
            El intercambio de información con demás profesionales de la salud
            adscritos a KIELSA CLÍNICA Y LAB queda estrictamente restringido al
            objeto de la consulta, cuando sea de carácter necesario y que derive
            un beneficio u orientación para el usuario/paciente.
            <br />
            <br />
            KIELSA FARMACEUTICA, S. A. DE C. V. será el responsable de que la
            información contenida en cada historia clínica sea almacenada de
            forma correcta, guardando estándares de confidencialidad, a la que
            solo el personal de Salud contratado y/o autorizado como Prestador
            de Salud de KIELSA CLÍNICA & LAB tendrán acceso parcial o pleno para
            encontrar, guardar, complementar y compartir con fines estrictamente
            profesionales y en beneficio del Usuario/paciente los datos
            sensibles que el documento de historia clínica contiene.
            <br />
            <br />
            En calidad de Usuario/Paciente, declaro haber entendido los
            conceptos y lineamientos previamente definidos, así como los
            alcances del mismo, por lo que expresamente autorizo a los
            Profesionales de la Salud contratado y/o autorizado como Prestador
            de Salud de KIELSA CLÍNICA & LAB que se encuentran adscritos a la
            plataforma de www.kielsaclinica.com para que accedan a mi historia
            clínica, puedan igualmente adicionar datos o elementos necesarios a
            mi condición de salud, datos derivados de la cita generada de forma
            presencial o mediante la plataforma virtual www.kielsaclinica.com.
            <br />
            <br />
            <h5 className="text-secondarymdv font-weight-bold">
              TÉRMINOS Y CONDICIONES DE USO – PACIENTES
            </h5>
            Este documento describe los términos y condiciones generales (los
            «Términos y Condiciones Generales») aplicables al acceso y uso de
            los servicios ofrecidos por médicos, («los Servicios») dentro de la
            modalidad de consulta médica presencial o bien mediante la
            plataforma virtual (www.kielsaclinica.com), (“el Sitio») que forman
            parte de la estructura de KIELSA CLÍNICA & LAB.
            <br />
            <br />
            Cualquier persona que desee acceder y/o suscribirse y/o usar los
            Servicios podrá hacerlo sujetándose a los Términos y Condiciones
            Generales y las Políticas de Privacidad, junto con todas las demás
            políticas y principios que rigen KIELSA CLÍNICA & LAB y que son
            incorporados al presente directamente o por referencia o que son
            explicados y/o detallados en otras secciones del Sitio. En
            consecuencia, todas las visitas, consultas y comunicaciones que se
            realicen a KIELSA CLÍNICA & LAB. así como sus efectos jurídicos,
            quedarán regidos por estas reglas y sometidos a la legislación
            aplicable en Honduras. Los presentes términos y condiciones aplican
            tanto a la consulta Médica presencial como al Servicio de
            telemedicina ofrecido en esta plataforma.
            <br />
            <br />
            Los Términos y Condiciones, así como las Políticas de Privacidad se
            aplicarán y se entenderán como parte integral de todos los actos que
            se ejecuten como parte de la prestación de Servicios de Salud
            mediante la consulta presencial y/o plataforma virtual
            www.kielsaclinica.com comprendidos en este sitio entre los usuarios
            de este sitio.
            <br />
            <br />
            Quien no acepte estos términos y condiciones generales, los cuales
            tienen un carácter obligatorio y vinculante, deberá abstenerse de
            utilizar el sitio y/o los servicios. El Usuario debe leer, entender
            y aceptar todas las condiciones establecidas en los Términos y
            Condiciones Generales y en las Políticas de Privacidad, así como en
            los demás documentos incorporados a los mismos por referencia,
            previas a su inscripción como Usuario/paciente de KIELSA CLÍNICA Y
            LAB y del portal www.kielsaclinica.com
            <br />
            <br />
            KIELSA CLÍNICA Y LAB es un servicio proporcionado y administrado por
            KIELSA FARMACEUTICA, S. A. DE C. V., sociedad comercial constituida
            bajo las leyes Honduras, con domicilio en la ciudad de Tegucigalpa,
            M.D.C.
            <br />
            <br />
            1. Aceptación: Recibir atención de Salud en cualquier
            establecimiento de KIELSA CLÍNICA & LAB Constituye como aceptación
            plena de las condiciones establecidas en los Términos y Condiciones
            Generales y en las Políticas de Privacidad (mismo que se presentara
            al paciente de forma digital para que pueda leer y aceptar previo a
            la realización de la consulta atención en Salud) El registro e
            inscripción que un usuario hiciera en el Sitio
            www.kielsaclinica.com, implica la aceptación plena de las
            condiciones establecidas en los Términos y Condiciones Generales y
            en las Políticas de Privacidad, por lo que el Usuario se obligará a
            cumplir expresamente con las mismas, no pudiendo alegar el
            desconocimiento de tales Términos y Condiciones Generales y de la
            Política de Privacidad.
            <br />
            <br />
            2. Acuerdo total: Las Partes acuerdan que estos términos y
            condiciones prevalecerán sobre cualquier otro acuerdo y/o convenio
            anterior, verbal o escrito, celebrado entre ellas con respecto a la
            prestación del Servicio de KIELSA CLÍNICA & LAB . Si hubiere alguna
            discrepancia entre estos términos y condiciones y cualquier otra
            comunicación, suscrita entre el usuario/paciente, lo previsto en
            estos Términos y Condiciones prevalecerá, pero únicamente por lo que
            se refiere a dicha discrepancia, sin llegar a afectar las demás
            cláusulas de los presentes Términos y Condiciones.
            <br />
            <br />
            3. Modificaciones: Cualquier modificación a estos términos y
            condiciones deberá constar por escrito y deberá contener una nueva
            aceptación por parte del usuario. No obstante, lo anterior, La
            empresa se reserva el derecho de hacer modificaciones a estos
            términos y condiciones y comunicarlas mediante la actualización del
            sitio web. El uso que el usuario hiciere del Servicio de KIELSA
            CLÍNICA & LAB con posterioridad a dichas modificaciones, constituye
            conocimiento y aceptación de las mismas.
            <br />
            <br />
            4. Capacidad: El usuario declara ser una persona natural, mayor de
            edad y tener plena capacidad de goce y de ejercicio para obligarse a
            la totalidad de los presentes Términos y Condiciones y que no existe
            dolo, fuerza ni ningún vicio en el consentimiento. Queda prohibida
            la contratación de servicios a través de KIELSA CLÍNICA Y LAB por
            parte de menores de edad, debiendo obtener debidamente y con
            anterioridad, el consentimiento de sus padres, tutores o
            representantes legales, los cuales serán considerados como
            responsables de los actos que lleven a cabo los menores a su cargo,
            conforme a la normativa vigente.
            <br />
            <br />
            5. Registro, inscripción y uso de la cuenta: El acceso a
            www.kielsaclinica.com por parte de los Usuarios tiene carácter libre
            y gratuito, no obstante, algunos de los servicios y contenidos
            ofrecidos por KIELSA CLÍNICA & LAB o terceros a través del Sitio Web
            www.kielsaclinica.com pueden encontrarse sujetos a la contratación
            previa del servicio o producto y al pago de una cantidad de dinero
            en la forma que se determine en las correspondientes Condiciones
            Particulares, en cuyo caso se pondrán a su disposición de forma
            clara.
            <br />
            <br />
            Es obligatorio completar el formulario de inscripción en todos sus
            campos con datos válidos y reales para poder utilizar los servicios
            que KIELSA CLÍNICA & LAB ofrecen de forma presencial o mediante el
            portal. El futuro Usuario deberá completarlo con su información
            personal de manera exacta (en el caso de pacientes que se presentan
            a consulta presencial y que no poseen usuario/cuenta registrado en
            la plataforma web, el personal del sitio de atención brindara al
            paciente orientación para el llenado de su ficha virtual y para la
            creación del expediente que será usado para todas las consultas
            presenciales y/o orientaciones virtuales que el paciente requiera;
            de forma tal que todo paciente atendido presencial o virtualmente
            contara por obligación con una cuenta/usuario en la plataforma web),
            precisa y verdadera («Datos Personales») y asume el compromiso de
            actualizar los Datos Personales conforme resulte necesario. KIELSA
            CLINICA & LAB podrá utilizar diversos medios para identificar a sus
            Usuarios, pero NO se responsabiliza por la certeza de los Datos
            Personales provistos. Los Usuarios garantizan y responden, en
            cualquier caso, de la veracidad, exactitud, vigencia y autenticidad
            de los Datos Personales ingresados.
            <br />
            <br />
            KIELSA CLÍNICA & LAB se reserva el derecho de solicitar algún
            comprobante y/o dato adicional a efectos de corroborar los Datos
            Personales, así como de suspender temporal o definitivamente a
            aquellos Usuarios cuyos datos no hayan podido ser confirmados. En
            estos casos de inhabilitación, sin que ello genere algún derecho a
            resarcimiento.
            <br />
            <br />
            La Cuenta/usuario es personal, única e intransferible y está
            prohibido que un mismo Usuario inscriba o posea más de una Cuenta.
            En caso que se detecte distintas Cuentas que contengan datos
            coincidentes o relacionados, KIELSA CLÍNICA & LAB podrá cancelar,
            suspender o inhabilitarlas.
            <br />
            <br />
            El Usuario será responsable por todas las operaciones efectuadas en
            su Cuenta, pues el acceso a la misma está restringido al ingreso y
            uso de su Clave de Seguridad, de conocimiento exclusivo del Usuario.
            El Usuario se compromete a notificar a KIELSA CLÍNICA & LAB en forma
            inmediata y por medio idóneo y fehaciente, cualquier uso no
            autorizado de su Cuenta, así como el ingreso por terceros no
            autorizados a la misma. Se aclara que está prohibida la venta,
            cesión o transferencia de la Cuenta bajo ningún título. El Usuario
            será el único responsable de mantener la seguridad de su cuenta, no
            podrá revelar a ninguna otra persona la contraseña usada para
            utilizar el servicio, siendo el único responsable de cualquier mal
            uso dado a su contraseña.
            <br />
            <br />
            Cuando sea necesario que el Usuario se registre o aporte datos
            personales para poder acceder a alguno de los servicios específicos,
            la recogida, el tratamiento y, en su caso, la cesión o el acceso de
            los datos personales de los Usuarios será de aplicación lo dispuesto
            en la Política de Privacidad aplicable a este portal web.
            <br />
            <br />
            6. Utilización de la página Los contenidos incluidos en
            www.kielsaclinica.com se facilitan únicamente a consumidores o
            usuarios finales. Cualquier uso comercial no autorizado de los
            mismos, o su reventa, quedan prohibidos, salvo que se cuente con la
            previa autorización escrita de KIELSA FARMACEUTICA, S. A. DE C. V.
            <br />
            <br />
            El acceso, navegación y uso de www.kielsaclinica.com es
            responsabilidad del Usuario, por lo que el Usuario se compromete a
            observar diligente y fielmente cualquier instrucción adicional
            impartida a través de los contenidos del sitio web www.KIELSA
            CLÍNICA & LAB .com
            <br />
            <br />
            Por tanto, el Usuario se obliga a usar los contenidos de forma
            diligente, correcta y lícita y, en particular, se compromete a
            abstenerse de:
            <br />
            <br />
            <br />* Utilizar los contenidos con fines o efectos contrarios a la
            ley, a la moral y a las buenas costumbres generalmente aceptadas o
            al orden público;
            <br />* Reproducir o copiar, distribuir, permitir el acceso del
            público a través de cualquier modalidad de comunicación pública,
            transformar o modificar los contenidos.
            <br />* Emplear los contenidos y, en particular, la información de
            cualquier clase obtenida a través de la página o de los servicios
            para remitir publicidad, comunicaciones con fines de venta directa o
            con cualquier otra clase de finalidad comercial, mensajes no
            solicitados dirigidos a una pluralidad de personas con independencia
            de su finalidad, así como a abstenerse de comercializar o divulgar
            de cualquier modo dicha información.
            <br />
            <br />
            6.1 Agendamiento de citas El usuario/paciente podrá agendar una cita
            para atención Medica presencial o bien para Orientación virtual con
            el medico que solicite según disponibilidad de horarios y agendas,
            que sean ofrecidas en el portal www.kielsaclinica.com, o de forma
            presencial en las Clínicas que forman parte de KIELSA CLÍNICA & LAB
            con el personal de la clínica que deberá incluirlas en la agenda del
            paciente y del Médico (mediante el sistema), para ello deberá tener
            en cuenta la fecha para la cual desea la cita.
            <br />
            <br />
            – Si al momento de solicitar la cita esta se agenda con una
            antelación mínimo de cuarenta y ocho (48) horas, el médico
            seleccionado por el paciente tendrá veinticuatro (24) horas para
            confirmar la cita.
            <br />
            – Si al momento de solicitar la cita se requiere que esta se efectúe
            en menos de cuarenta y ocho horas (48) horas el médico podrá
            confirmar la cita hasta dos (2) horas antes de la hora de la cita,
            en caso de que no sea confirmada la cita por el medico solicitado,
            la cita no se realizará. Lo anterior permite establecer que, el
            médico podrá determinar la disponibilidad que tenga para atender o
            no la cita programada para un periodo menor de cuarenta y ocho (48)
            horas a partir de la solicitud de la misma.
            <br />
            – Las citas se llevarán a cabo por fracciones de tiempo, cada
            fracción tendrá un tiempo de duración Máximo de veinte (20) minutos.
            El paciente podrá agendar la cita por el tiempo que considere
            necesario para realizar su consulta, siempre y cuando el tiempo y la
            hora sean aceptados por el médico con quien requiere la consulta. En
            todo caso ninguna cita podrá agendarse por un tiempo inferior a una
            fracción de tiempo.
            <br />
            – En caso que el paciente llegue tarde a la conexión o a la cita, o
            cuando la consulta termine sin que se hubiere completado la
            atención/orientación no habrá lugar al reembolso del dinero o bien
            en el número de atenciones a las que el paciente tiene derecho según
            el tipo de consulta o plan que el mismo haya contratado. Si por el
            contrario el médico no atendió la cita a tiempo, el paciente tendrá
            derecho al reembolso del tiempo o de la consulta que dejó de obtener
            el servicio.
            <br />
            – El paciente recibirá una notificación a través de la información
            de contacto brindada en el portal de www.kielsaclinica.com de todos
            los movimientos efectuados en relación a su cuenta, tales como
            confirmación, rechazo o cancelación de cita.
            <br />
            <br />
            6.2 Políticas de cancelación
            <br />
            <br />
            – Para las citas que se agendan con cuarenta y ocho (48) horas de
            antelación, el paciente podrá solicitar la cancelación de la cita,
            de forma gratuita y sin lugar a recargos, con al menos veinticuatro
            (24) horas de anticipación a la hora de la cita, en caso de que el
            pago ya se hubiera realizado (paquetes virtuales que cuentan con
            número limitado de cnsultas) y el paciente la cancele dentro del
            plazo previamente expresado el paciente podrá elegir que se le
            asigne una futura cita o bien que se restaure el derecho a la
            consulta cancelada para ser utilizada en otro momento. En caso de
            solicitar la cancelación durante las veinticuatro (24) horas previas
            a la hora de la cita o en caso de no llegar a la cita presencial o
            en línea el paciente deberá pagar el costo total de la cita que
            programó, si la misma fue previamente pagada no recibirá el
            reembolso del dinero, en caso de los paquetes no se restablecerá el
            acceso a esa consulta para ser reprogramada posteriormente.
            <br />
            – Es necesario establecer que el médico podrá cancelar la cita
            programada, caso en el cual deberá informarle al paciente mediante
            la plataforma virtual y advertirle tal anomalía. En caso de que el
            paciente haya efectuado el pago este podrá solicitar el reintegro
            del derecho a esta consulta. KIELSA CLÍNICA & LAB verificará la
            justificación del médico para cancelar la cita.
            <br />
            – Las citas que se agenden para realizarse en las cuarenta y ocho
            (48) horas siguientes a la solicitud y que sean confirmadas por el
            médico no podrán cancelarse.
            <br />
            <br />
            7. Tipos de servicios que ofrece KIELSA CLÍNICA & LAB.
            <br />
            7.1 Consulta Médica que es de carácter presencial y se brindará de
            acuerdo a disponibilidad de médicos al momento de llegar al centro
            Asistencial bajo 3 principios básicos:
            <br />
            <br />
            a.1) Prioridad a emergencias según lo estipula Ley; el paciente
            presencial debe esperar si acude una emergencia a la Clínica Nos
            reservamos el derecho de atención supeditado a presencia de
            Emergencias Clínicas.
            <br />
            <br />
            a.2) Se atenderá al paciente que agenda en línea en el horario que
            selecciono (ya sea una orientación Virtual o consulta médica
            presencial), el paciente presencial que acude a consulta sin cita
            debe ser informado al momento de su contacto con personal en las
            clínicas la disponibilidad en ese momento de acuerdo a la agenda del
            médico de ese establecimiento (esta disponibilidad debe contemplar
            la combinación de la agenda virtual y presencial y considera un
            tiempo máximo de 20 min por paciente).
            <br />
            <br />
            a.3) Paciente presencial cuando el médico está disponible se
            atenderá en orden de llegada.
            <br />
            <br />
            7.2 La Orientación Virtual cuenta con orientación médica las 24
            horas esta orientación virtual no sustituye la atención presencial y
            que en caso de emergencia debe llamar al 911. Este tipo de servicio
            se regirá mediante los siguientes principios:
            <br />
            <br />
            b.1) Pacientes agendados se atenderán en el horario seleccionado con
            medico seleccionado salvo situación de emergencia.
            <br />
            <br />
            b.2) Las orientaciones virtuales sin agenda sujeto a disponibilidad
            de Médicos en línea al momento de la comunicación.
            <br />
            <br />
            8. Licencia sobre las comunicaciones
            <br />
            En el caso de que el Usuario envíe información de cualquier tipo a
            KIELSA CLÍNICA Y LAB, el mismo declara, garantiza y acepta que tiene
            derecho a hacerlo libremente, que dicha información no infringe
            ningún derecho de un tercero y que dicha información no es
            perjudicial para terceros.
            <br />
            <br />
            El Usuario reconoce asumir la responsabilidad y dejará indemne a por
            cualquier comunicación que suministre personalmente o a su nombre a
            KIELSA CLINICA Y LAB alcanzando dicha responsabilidad sin
            restricción alguna la exactitud, legalidad, originalidad y
            titularidad de la misma.
            <br />
            <br />
            KIELSA CLINICA & LAB declara que ha adoptado todas las medidas
            necesarias, dentro de sus posibilidades y del estado de la
            tecnología, para garantizar el funcionamiento de
            www.kielsaclinica.com, y ha dispuesto la tecnología necesaria para
            evitar la existencia y transmisión de virus y demás componentes
            dañinos a los equipos electrónicos de los Usuarios.
            <br />
            <br />
            9. Enlaces
            <br />
            En www.kielsaclinica.com, el Usuario podrá encontrar enlaces a otras
            páginas web mediante diferentes botones, links, banners, etc., los
            cuales son gestionados por terceros. KIELSA CLINICA & LAB no tiene
            facultad, ni medios humanos ni técnicos para conocer, controlar ni
            aprobar toda la información, contenidos, productos o servicios
            facilitados por otros sitios web a los que se establecen enlaces
            desde www.kielsaclinica.com En consecuencia, KIELSACLINICA Y LAB no
            puede asumir ningún tipo de responsabilidad por cualquier aspecto
            relativo a la página web a la que se establece un enlace desde el
            portal de www.kielsaclinica.com, en concreto, a título enunciativo y
            no taxativo, sobre su funcionamiento, acceso, datos, información,
            archivos, calidad y fiabilidad de sus productos y servicios, sus
            propios enlaces y/o cualquiera de sus contenidos, en general.
            <br />
            <br />
            El establecimiento de cualquier tipo de enlace por parte de
            www.kielsaclinica.com a otro sitio web ajeno no implica que exista
            algún tipo de relación, colaboración o dependencia entre KIELSA
            FARMACEUTICA, S. A. DE C. V. y el responsable del sitio web ajeno.
            <br />
            <br />
            11. Medios de pago
            <br />
            Los servicios ofrecidos por KIELSA CLÍNICA & LAB de forma presencial
            o en el Sitio, salvo que se señale una forma diferente para casos
            particulares, sólo pueden ser pagados con los medios que en cada
            caso específicamente se indiquen. El uso de tarjetas de crédito y en
            general de cualquier medio de pago que implique la entrega de
            información financiera por parte del usuario, será tratado con
            confidencialidad y diligencia, así mismo se regirá bajo las reglas
            existentes en relación con su emisor, y a lo pactado en los
            respectivos Contratos de Apertura y Reglamento de Uso. En caso de
            contradicción, predominará lo expresado en ese último instrumento.
            Tratándose de tarjetas bancarias aceptadas en el Sitio, los aspectos
            relativos a éstas, tales como la fecha de emisión, caducidad, cupo,
            bloqueos, etc., se regirán por el respectivo Contrato de Apertura y
            Reglamento de Uso, de tal forma que las Empresas no tendrán
            responsabilidad por cualquiera de los aspectos señalados. El Sitio
            podrá indicar determinadas condiciones de compra según el medio de
            pago que se utilice por el usuario.
            <br />
            <br />
            12. Notificaciones
            <br />
            <br />
            Toda comunicación o notificación entre las partes deberá constar por
            escrito, estando las partes de acuerdo que el intercambio de datos
            electrónicos también será un medio válido de comunicación entre
            ellas. Cualquier comunicación se considerará entregada y recibida:
            <br />
            <br />
            a) si se entrega personalmente a su destinatario, en el momento de
            ser recibida.
            <br />
            <br />
            b) si se envía por correo, en la fecha de entrega a su dirección de
            remisión;
            <br />
            <br />
            c) si se envía mediante correo electrónico, en el momento del
            despacho de dicha comunicación, siempre que se haya recibido
            confirmación electrónica u otra del recibo del despacho.
            <br />
            <br />
            d) Mensajería de texto y/o mensajería mediante el servicio WhatsApp
            (vía números autorizados por KIELSA CLINICA & LAB)
            <br />
            <br />
            13. Privacidad de la Información: Para utilizar los Servicios
            ofrecidos por KIELSA CLÍNICA & LAB, los usuarios deberán facilitar
            determinados datos de carácter personal. La información personal
            será procesada, almacenada y tramitada para aquellas operaciones que
            se desprendan de la utilización del portal web, tales como: en bases
            de datos conforme a las leyes hondureñas, los servidores o medios
            magnéticos que mantienen altos estándares de seguridad y protección
            tanto física como tecnológica. Para más información sobre la
            privacidad de los Datos Personales y casos en los que será revelada
            la información personal, se pueden consultar nuestras Políticas de
            Privacidad.
            <br />
            <br />
            14. Jurisdicción Las relaciones establecidas entre KIELSA CLINICA &
            LAB y el Usuario se regirán por lo dispuesto en la normativa
            hondureña vigente. No obstante, para los casos en los que la
            normativa prevea la posibilidad a las partes de someterse a un
            fuero, KIELSA CLINICA & LAB y el Usuario, con renuncia expresa a
            cualquier otro fuero que pudiera corresponderles, y convienen que
            cualquier controversia o conflicto entre las partes relacionado
            directa o indirectamente con este documento, inclusive de su
            naturaleza, interpretación, cumplimiento, ejecución o terminación
            del mismo, se resolverá mediante el procedimiento de arbitraje, de
            conformidad con el reglamento del Centro de Conciliación y Arbitraje
            de la Cámara de Comercio e Industria de Tegucigalpa. En caso de que
            alguna de las partes interponga Recurso de Nulidad contra el Laudo
            Arbitral proferido por el Tribunal Arbitral respectivo, este recurso
            se tramitará y decidirá ante un Nuevo Tribunal Arbitral que se
            nombrará e instalará de conformidad a lo dispuesto en el Reglamento
            del Centro de Conciliación y Arbitraje de la Cámara de Comercio e
            Industria de Tegucigalpa y la Ley de Conciliación y Arbitraje
            vigente en Honduras. El Tribunal de alzada se constituirá únicamente
            para conocer del Recurso de Nulidad interpuesto.
            <br />
            <br />
            <h5 className="text-secondarymdv font-weight-bold">
              AUTORIZACIÓN PARA EL TRATAMIENTO DE DATOS PERSONALES
            </h5>
            Mediante este documento declaro que he sido informado por KIELSA
            CLINICA Y LAB de lo siguiente:
            <br />
            <br />
            a) QUE KIELSA CLINICA Y LAB. actuará como Responsable del
            Tratamiento de Datos Personales de los cuales soy titular y que
            podrá recolectar, usar, almacenar y suprimir mis datos personales de
            acuerdo con su Política de Tratamiento de la Información (la “PTI”)
            disponible en www.kielsaclinica.com la cual declaro que he
            consultado.
            <br />
            <br />
            b) QUE mis derechos como Titular de los datos son los previstos en
            la Constitución de la Republica de Honduras y la Ley Sobre Justicia
            Constitucional, especialmente el derecho al Hábeas Data (i. a
            conocer, actualizar, rectificar y suprimir mi información personal,
            así como el derecho a revocar el consentimiento otorgado para el
            Tratamiento de Datos Personales).
            <br />
            <br />
            c) QUE los derechos pueden ser ejercidos a través de los canales
            gratuitos dispuestos por KIELSA CLÍNICA & LAB, señalados en su PTI.
            <br />
            <br />
            d) QUE el Área de Privacidad designada para la protección de Datos
            Personales es Servicio al Cliente y puede ser contactada a través
            del correo electrónico info@kielsaclinica.com
            <br />
            <br />
            e) QUE es de carácter facultativo entregar información cuando se
            soliciten Datos Sensibles o de niños, niñas o adolescentes.
            <br />
            <br />
            f) QUE los derechos del Menor como Titular de los datos son los
            previstos en la Constitución de la Republica de Honduras y la Ley
            Sobre Justicia Constitucional, especialmente el derecho al Hábeas
            Data (i. a conocer, actualizar, rectificar y suprimir mi información
            personal, así como el derecho a revocar el consentimiento otorgado
            para el tratamiento de Datos Personales del Menor).
            <br />
            <br />
            g) QUE el Tratamiento de los Datos Personales del Menor se hará
            respetando el interés superior de los niños, niñas y adolescentes y,
            en la medida de lo posible, teniendo en cuenta la opinión del Menor
            considerando factores de madurez, autonomía, capacidad para entender
            el fin de dicho tratamiento.
            <br />
            <br />
            h) QUE KIELSA CLINICA & LAB. está comprometida y toma las medidas
            adecuadas para asegurar la confidencialidad, libertad, seguridad,
            veracidad, transparencia, acceso y circulación restringida de mis
            Datos Personales y se reserva el derecho de modificar su PTI en
            cualquier momento. Cualquier cambio será informado y publicado
            oportunamente en la página web.
            <br />
            <br />
            i) QUE KIELSA CLINICA & LAB podrá enviarme notificaciones a los
            datos de contacto que he brindado a través de la plataforma virtual
            que tengan como fin desarrollar el objeto de mi inscripción al sitio
            web, el agendamiento y toma de citas de telemedicina
            <br />
            <br />
            Considerando lo anterior, autorizo de manera voluntaria, previa,
            expresa, informada e inequívoca a KIELSA CLINICA & LAB para tratar
            mis Datos Personales de acuerdo con su PTI y para las finalidades
            relacionadas con su objeto social y sus obligaciones legales,
            contractuales y laborales, específicamente descritos en la PTI de
            KIELSA CLINICA & LAB, las cuales están establecidas en el portal
            www.kielsaclinica.com
            <br />
            <br />
          </p>
        </div>
        <div class="modal-footer p-2">
          <button
            type="button"
            class="btn btn-info mr-3"
            data-dismiss="modal"
            style={{ borderRadius: "0.8rem" }}
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default ModalTermConditions;

import { useState } from 'react';
import {
  getProductByCode,
  createGiftPackageLog,
  getGiftCardValueByCode,
} from '../../../../api/giftCard';
import createAlert from '../../../../redux/actions/alert.action';
import Swal from 'sweetalert2';
const useGifcardIndexState = () => {
  const [code, setCode] = useState('');
  const [products, setProducts] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const handleChangeCode = ({ target }) => {
    setCode(target.value);
  };

  const handleSubmitCheckCode = (e) => {
    e.preventDefault();
    setLoading(true);
    setCode('');
    //getProductByCode(code)
    getGiftCardValueByCode(code)
      .then((res) => {
        setLoading(false);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Exito!',
          message:
            'La giftcard se ha activado de forma correcta!. se ha acreditado su valor al balance de tu cuenta.',
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => {
        createAlert('Error!', err?.response?.data?.message, 'error');
        setProducts([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCreatePackage = (productId, code) => {
    setLoading(true);
    createGiftPackageLog(code, productId)
      .then((res) => {
        createAlert('Canjeado!!', res.data.message, 'success').then(() => {
          window.location.href = '/dashboard/consulta';
        });
      })
      .catch((err) => {
        createAlert('Error!', err?.response?.data?.message, 'error');
        setProducts([]);
        setCode('');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClearproducts = () => {
    setProducts([]);
    setCode('');
  };

  return {
    handleSubmitCheckCode,
    code,
    setCode,
    setLoading,
    handleChangeCode,
    isLoading,
    products,
    handleCreatePackage,
    handleClearproducts,
  };
};

export default useGifcardIndexState;

import React from "react";
import { connect } from "react-redux";
import { usePackage } from "./state/usePackages";
import { useUI } from "../../../hooks/useUI";
import createAlert from "../../../redux/actions/alert.action";
import useFarinterSelector from "../../../hooks/useFarinterSelector";
import {
  giveEmployee,
  downEmployee,
  updateStatusEmployee,
  updatePackageEmployee,
  removePackageEmployee,
  changeModalDependentEmployee,
  getDependentEmployee,
} from "../../../redux/actions/auth.action";
import ModalEditEmployee from "../Modals/ModalEditEmployee";
import ListDependentsEmployeeModal from "../Modals/ListDependentsEmployeeModal";
import { useItemState } from "./state/useItemState";
import { useShowModal } from "./state/useShowModal";
import CarnetModal from "../../commons/Carnet/CarnetModal";
import AddDependentModal from "../../Patient/AddDependentModal";
import { getUserByEmailOrCellphoneOrDniByDoctor } from "../../../redux/actions/auth.action";
import { useDispatch } from "react-redux";
import moment from "moment";
import Swal from "sweetalert2";
const ItemListEmployeeTable = ({
  datos,
  giveEmployee,
  selection,
  downEmployee,
  updateStatusEmployee,
  token,
  updatePackageEmployee,
  removePackageEmployee,
  showModalDependents,
  changeModalDependentEmployee,
  getDependentEmployee,
}) => {
  const { handleSelectedUser, handleSelectedProfile } = useItemState();
  const { auth } = useFarinterSelector();
  const { user } = auth;
  const { modalCarnet } = useUI();
  const { packages } = usePackage();
  const dispatch = useDispatch();
  const {
    modalDataEmployee,
    setModalDataEmployee,
    showModalAddDependents,
    setShowModalAddDependents,
  } = useShowModal();

  const getEmployee = (item) => {
    if (!isSelected(item.dni)) {
      const _id = item._id;
      const dni = item.dni;
      const idPackage = item.package[0]?.packageKtm._id
        ? item.package[0].packageKtm._id
        : packages[0]._id;

      const formData = {
        _id,
        dni,
        idPackage,
      };

      giveEmployee(formData);
    } else {
      downEmployee(item.dni);
    }
  };

  const updateStatus = async (item) => {
    let newStatus = null;
    if (item?.status == true) {
      downEmployee(item.dni);
      newStatus = false;
    } else {
      newStatus = true;
    }
    const id = item._id;
    const idEnterprise = item?.idCompany;
    try {
      await updateStatusEmployee(id, newStatus, idEnterprise);
    } catch (e) {
      await createAlert("Error", "No se pudo cambiar el status", "error");
    }
  };

  const isSelected = (id) => {
    const ret = selection.filter((item) => item.dni === id);
    return ret.length > 0 ? true : false;
  };
  const actualTime = moment(new Date()).format("YYYY-MM-DD");

  const changeP = (item, _id, dni, idCompany, existPackage) => {
    if (existPackage) {
      updatePackageEmployee(_id, item, token, idCompany);
    } else {
      downEmployee(dni);
      const idPackage = item;
      const formData = {
        _id,
        dni,
        idPackage,
      };

      giveEmployee(formData);
    }
  };

  const getCarnet = (email) => {
    dispatch(
      getUserByEmailOrCellphoneOrDniByDoctor({
        email,
      })
    );
  };

  const canChange = (data) => {
    return data.package[0]?.createdAt || isSelected(data.dni) ? false : true;
  };

  const DeleteMedicalPackage = (idUser, idCompany) => {
    Swal.fire({
      icon: "warning",
      title: "¿Estás seguro desea eliminar este paquete activo?",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "NO",
      cancelButtonText: "SI",
    }).then((res) => {
      const { isConfirmed } = res;
      if (!isConfirmed) {
        removePackageEmployee(idUser, token, idCompany);
      }
    });
  };

  const usingModalDependents = (dataEmployee) => {
    getDependentEmployee(dataEmployee._id);
    setModalDataEmployee(dataEmployee);
    setShowModalAddDependents(!showModalAddDependents);
  };

  return (
    <>
      <ListDependentsEmployeeModal
        show={showModalAddDependents}
        onClose={() => setShowModalAddDependents(!showModalAddDependents)}
      />
      <AddDependentModal
        dataEmployee={modalDataEmployee}
        employeedUser={true}
        show={showModalDependents}
        onClose={() => changeModalDependentEmployee(!showModalDependents)}
      />
      {modalCarnet && <CarnetModal />}
      {datos.map((data, index) => (
        <tr
          className={
            isSelected(data.dni) ? "shadow-sm p-5 m-5 bg-white rounded" : ""
          }
        >
          <td scope="row">
            <div className="form-check">
              <input
                className="form-check-input-center"
                role="button"
                type="checkbox"
                checked={isSelected(data.dni) ? true : false}
                onClick={() => getEmployee(data)}
                id={index}
                style={{ width: 15, height: 15 }}
                disabled={
                  data.package[0]?.createdAt || data.status == false
                    ? true
                    : false
                }
              />
            </div>
          </td>
          <td>
            <div className="text-dark text-start">
              <h6>{data.dni}</h6>
            </div>
          </td>
          <td>
            <div className="text-dark text-start">
              <h6>{data.email}</h6>
            </div>
          </td>
          <td>
            <div className="text-dark text-start">
              <h6>{data.firstName}</h6>
            </div>
          </td>
          <td>
            <div className="text-dark text-start">
              <h6>{data.lastName}</h6>
            </div>
          </td>
          <td>
            <div className="text-dark text-start">
              <h6>{user.firstName}</h6>
            </div>
          </td>
          <td>
            <div className="text-dark text-start">
              <select
                className="form-control bg-light"
                onChange={(e) =>
                  changeP(
                    e.target.value,
                    data._id,
                    data.dni,
                    data.idCompany,
                    data.package[0]?.createdAt
                  )
                }
                disabled={canChange(data)}
              >
                {data.package[0]?.packageKtm.name ? (
                  <option selected value={data.package[0]?.packageKtm._id}>
                    {data.package[0]?.packageKtm.name}
                  </option>
                ) : null}
                {packages.map((doc) => (
                  <>
                    {doc?.planType ? (
                      <>
                        {doc?.planType === "COMPANY" &&
                        doc?.price !== 0 ? null : (
                          <option value={doc._id}>
                            {doc?.numberReference !== 6716001531
                              ? doc?.name
                              : doc?.name + "- Empleados"}
                          </option>
                        )}
                      </>
                    ) : (
                      <option value={doc._id}>{doc.name}</option>
                    )}
                  </>
                ))}
              </select>
            </div>
          </td>
          <td>
            <div className="text-dark  text-center">
              {data.package[0]?.createdAt && data.package[0]?.dueDate ? (
                <h6>
                  {data.package[0]?.createdAt.substring(0, 10)} -{" "}
                  {data.package[0]?.dueDate.substring(0, 10)}
                </h6>
              ) : (
                <h6>No posee plan</h6>
              )}
            </div>
          </td>
          <td>
            <div className="text-dark  text-start">
              {data.package[0]?.createdAt && data.package[0]?.dueDate ? (
                <h6>
                  {" "}
                  {data.package[0].dueDate > actualTime ? (
                    <h6>Plan activo</h6>
                  ) : (
                    <h6>Plan inactivo</h6>
                  )}
                </h6>
              ) : (
                <h6>No posee plan</h6>
              )}
            </div>
          </td>
          <td>
            <div className="text-dark  text-start">
              <h6>{data?.status == true ? "activo" : "inactivo"}</h6>
            </div>
          </td>
          <td>
            <div className="text-dark  text-start">
              <div
                className="dropdown"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  className="btn "
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  {data.package[0]?.createdAt && data.package[0]?.dueDate ? (
                    <a
                      className="dropdown-item"
                      role="button"
                      key={data._id}
                      onClick={() => usingModalDependents(data)}
                    >
                      Agregar dependiente
                    </a>
                  ) : null}
                  <a
                    className="dropdown-item"
                    role="button"
                    key={data._id}
                    onClick={() => {
                      getCarnet(data.email);
                    }}
                  >
                    Carnet
                  </a>
                  <a
                    className="dropdown-item"
                    data-toggle="modal"
                    role="button"
                    key={data._id}
                    data-target="#ModalEditEmployee"
                    onClick={() => {
                      handleSelectedUser(data._id);
                      handleSelectedProfile(data._id);
                    }}
                  >
                    Editar
                  </a>
                  <a
                    className="dropdown-item"
                    role="button"
                    href="#"
                    onClick={() => updateStatus(data)}
                  >
                    {data?.status == true ? (
                      <>Bloquear empleado</>
                    ) : (
                      <>Desbloquear empleado</>
                    )}
                  </a>
                  {data.package[0]?.createdAt && data.package[0]?.dueDate ? (
                    <a
                      className="dropdown-item text-danger"
                      role="button"
                      href="#"
                      onClick={() =>
                        DeleteMedicalPackage(data._id, data.idCompany)
                      }
                    >
                      Eliminar Plan Activo
                    </a>
                  ) : null}
                </div>
              </div>
            </div>
          </td>
        </tr>
      ))}
      <ModalEditEmployee />
    </>
  );
};

const mapStateToProps = (state) => ({
  showModalDependents: state.enterprise.usingAddDependentModal,
  datos: state.enterprise.listEmployees,
  selection: state.enterprise.selectedEmployee,
  token: state.auth.token,
});
export default connect(mapStateToProps, {
  giveEmployee,
  downEmployee,
  updateStatusEmployee,
  updatePackageEmployee,
  removePackageEmployee,
  changeModalDependentEmployee,
  getDependentEmployee,
})(ItemListEmployeeTable);

import React from 'react';
import { Spinner } from 'react-bootstrap';
import loaderGif from '../../assets/img/gif/loadinfo.gif';

const Loading = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    }}
  >
    <img alt="loader" src={loaderGif} />
  </div>
);

export default Loading;

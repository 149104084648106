import React from 'react';
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import PackageDetails from './PackageDetails/PackageDetails';
import useGifcardIndexState from './state/useGifcardIndexState';
import { BiArrowBack } from 'react-icons/all';

const GiftCardIndex = () => {
  const {
    handleSubmitCheckCode,
    code,
    handleChangeCode,
    setCode,
    setLoading,
    isLoading,
  } = useGifcardIndexState();
  return (
    <Container>
      <Row style={{ paddingTop: 100 }}>
        <Col
          xs={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h3 style={{ fontWeight: 400, color: '#041689' }}>
            ¿Tienes algun regalo?
          </h3>
        </Col>
        <Col
          xs={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h3 style={{ fontWeight: 600, color: '#041689' }}>Canjéalo aquí</h3>
        </Col>
        <Col xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Form style={{ width: '40%' }} onSubmit={handleSubmitCheckCode}>
            <Form.Group>
              <Form.Control
                type="text"
                name={code}
                value={code}
                onChange={handleChangeCode}
              />
              <Form.Text style={{ textAlign: 'left' }}>
                Ingresa el código de tu giftcard el cual recibiste en tu correo
                electrónico o en tu celular
              </Form.Text>
            </Form.Group>
          </Form>
        </Col>
        <Col
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={handleSubmitCheckCode}
            style={{ width: 150 }}
            disabled={code.trim().length < 6}
          >
            {isLoading ? (
              <Spinner
                style={{
                  width: 20,
                  height: 20,
                  color: '#ffff',
                  fontSize: 8,
                }}
                animation="border"
              />
            ) : (
              'Canjear'
            )}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default GiftCardIndex;

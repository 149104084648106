import React from 'react';
import createAlert from '../../redux/actions/alert.action';
import {addScheduleDoctor} from '../../api/scheduling';
import {hours} from '../../utils/scheduling';

function ChatAgenda({
  token, patient, idVideo, schedule, sendSchedule,
}) {
  return (
    <div className="w-100">
      {
        !schedule ? (
          <form
            onSubmit={(event) => {
              event.preventDefault();
              const reason = event.target.reason.value;
              const date = event.target.date.value;
              const hour = event.target.hour.value;
              addScheduleDoctor({
                reason,
                date,
                token,
                hour,
                patient,
                idVideo,
              })
                .then((res) => {
                  const {data} = res;
                  createAlert('¡Correcto!', 'Se agrego la consulta.', 'success');
                  sendSchedule(data);
                })
                .catch((error) => {
                  if (error.status === 'error') {
                    createAlert('¡Error!', error.info, 'error');
                  } else {
                    createAlert(
                      '¡Error!',
                      'Lo sentimos no se agendó la cita.',
                      'error',
                    );
                  }
                });
            }}
          >
            <div className="form-group">
              <textarea
                className="form-control textarea-properties"
                placeholder="Motivo de la cita*"
                name="reason"
              />
            </div>
            <div className="form-group">
              <span style={{display: 'flex'}}>Fecha de cita</span>
              <input
                type="date"
                className="form-control center"
                placeholder="Fecha de cita"
                name="date"
              />
            </div>
            <div
              style={{
                display: 'flex',
                borderRadius: 5,
                width: 100,
                flexDirection: 'column',
              }}
            >
              <span style={{display: 'flex'}}>Hora cita</span>
              <select
                id="exampleFormControlSelect1"
                name="hour"
                placeholde="Hora"
                style={{
                  padding: 5,
                  borderRadius: 10,
                  border: '1px solid #D4D9E3',
                  outline: 'none',
                }}
              >
                {hours.map((hour) => (
                  <option value={hour}>{hour}</option>
                ))}
              </select>
            </div>
            <div className="form-group text-center">
              <button
                type="submit"
                className="btn btn-primary mx-auto"
                style={{width: '200px'}}
              >
                ENVIAR
              </button>
            </div>
          </form>
        ) : (
          <>
            <p>
              {`${schedule.reason}, ${schedule.date}, ${schedule.hour}`}
            </p>
          </>
        )
      }
    </div>
  );
}

export default ChatAgenda;

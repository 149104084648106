/* eslint-disable global-require */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable arrow-parens */
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { Form, FormControl, Spinner } from "react-bootstrap";
// import moment from 'moment';
import { AiFillDelete } from "react-icons/all";
import { IconContext } from "react-icons";
import { NavLink } from "react-router-dom";
import toastr from "toastr";
import CardModal from "./History/CardModal";
import { countries } from "../../utils/countries";
import { tipo_sangres } from "../../utils/blood_types";
import {
  setDefaultCreditCard,
  deleteCreditCard,
} from "../../redux/actions/credit.card.action";
import {
  getDependentPatient,
  removeDependentPatient,
} from "../../api/dependentPatient";
import {
  updateProfile,
  resetpassword,
} from "../../redux/actions/profile.action";
import createAlert from "../../redux/actions/alert.action";
import visaImg from "../../assets/img/visa.svg";
import mastercardImg from "../../assets/img/mastercard.svg";
import arrayRelations from "../../utils/relations";

const UserPerfilMovil = ({
  user: { firstName, lastName, email, gender, role },
  profile: {
    cellphone,
    dni,
    country,
    bornAt,
    weight,
    height,
    blood_type,
    dialCode,
  },
  updateProfile,
  token,
  resetpassword,
  cards,
  setDefaultCreditCard,
  deleteCreditCard,
}) => {
  const [formData, setFormData] = useState({
    nombre: firstName,
    apellido: lastName,
    correo: email,
    tel: cellphone,
    altura: height,
    peso: weight,
    tipo_sangre: blood_type === undefined ? tipo_sangres[0].name : blood_type,
    pais: country === undefined ? countries[0].name : country,
    genero: gender === "male" ? "Masculino" : "Femenino",
    identidad: dni,
    oldPass: "",
    newPass: "",
    rPass: "",
    dial: dialCode,
    born: bornAt === undefined ? "" : bornAt.slice(0, 10),
  });
  const [uid, setUid] = useState("");
  const [patients, setPatients] = useState([]);
  const [loadingPatient, setLoadingPatient] = useState(true);

  const handleChange = (e) => {
    if (e.target.name === "pais") {
      const tempCountry = countries.filter(
        (country) => country.name === e.target.value
      );
      setFormData({
        ...formData,
        dial: tempCountry[0].dial_code,
        pais: tempCountry[0].name,
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    getDependentPatient({ token })
      .then((res) => {
        const { data } = res;
        setPatients(data);
        setLoadingPatient(false);
      })
      .catch((error) => {
        if (error.info) {
          toastr.error(error.info);
        }
        setLoadingPatient(false);
      });
  }, []);

  const updateDependentesPatient = () => {
    getDependentPatient({ token })
      .then((res) => {
        const { data } = res;
        setPatients(data);
        setLoadingPatient(false);
      })
      .catch((error) => {
        if (error.info) {
          toastr.error(error.info);
        }
        setLoadingPatient(false);
      });
  };

  const handleCheckChange = (id) => {
    setDefaultCreditCard(id);
  };

  const [openModal, setOpenModal] = useState(false);
  const {
    nombre,
    apellido,
    correo,
    tel,
    altura,
    peso,
    tipo_sangre,
    pais,
    genero,
    identidad,
    oldPass,
    newPass,
    rPass,
    dial,
    born,
  } = formData;

  const updateData = (e) => {
    e.preventDefault();
    updateProfile(formData, role);
  };

  const changepass = (e) => {
    e.preventDefault();
    if (oldPass !== "" && newPass !== "" && rPass !== "") {
      if (newPass === rPass) {
        resetpassword(oldPass, newPass);
      } else {
        createAlert("¡Error!", "¡Las contraseñas no coinciden!", "error");
      }
    } else {
      console.log("¡Datos vacíos!");
    }
  };

  const handleKeypress = (e) => {
    const invalidChars = ["-", "+", "e", "E"];
    if (invalidChars.indexOf(e.key) !== -1) {
      e.preventDefault();
    }
  };

  return (
    <div className="container-fluid">
      <CardModal open={openModal} setOpen={setOpenModal} />
      <h5 className="text-primary mt-5 mb-0">
        Perfil
        <b className="text-secondarymdv">
          {` ${firstName.split(" ", 1)} ${lastName.split(" ", 1)}`}
        </b>
      </h5>
      <small className="text-primary" style={{ fontSize: "11px" }}>
        Mis Consultas, diagnósticos y transacciones.
      </small>
      <div className="row row-cols-2 justify-content-center mt-3">
        <div className="col mb-4">
          <a
            data-toggle="modal"
            data-target="#modalinformationpatient"
            href="#"
          >
            <div
              className="card text-center shadow-sm border-0 h-100"
              style={{ borderRadius: "1rem" }}
            >
              <div className="p-2">
                <img
                  src={require("../../assets/img/icons/usuario.svg")}
                  className="card-img-top pb-2 pt-1"
                  alt="user"
                  style={{ width: "43px" }}
                />
                <br />
                <small
                  className="text-primary m-0 p-0"
                  style={{ fontSize: "11px" }}
                >
                  Información Personal
                </small>
              </div>
            </div>
          </a>
        </div>
        <div className="col mb-4">
          <a data-toggle="modal" data-target="#modalpasspatient" href="#">
            <div
              className="card text-center shadow-sm border-0 h-100"
              style={{ borderRadius: "1rem" }}
            >
              <div className="p-2">
                <img
                  src={require("../../assets/img/vectores/unlock.svg")}
                  className="card-img-top pb-1 pt-1"
                  alt="user"
                  style={{ width: "35px" }}
                />
                <br />
                <small
                  className="text-primary m-0"
                  style={{ fontSize: "11px" }}
                >
                  Cambiar Contraseña
                </small>
              </div>
            </div>
          </a>
        </div>
        {/* <div className="col mb-4">
          <a data-toggle="modal" data-target="#modalmdvpoints" href="#">
            <div
              className="card text-center shadow-sm border-0 h-100"
              style={{borderRadius: '1rem'}}
            >
              <div className="p-2">
                <img
                  src={require('../../assets/img/vectores/pig-coints.svg')}
                  className="card-img-top pb-1 pt-1"
                  alt="user"
                  style={{width: '50px'}}
                />
                <br />
                <small
                  className="text-primary m-0"
                  style={{fontSize: '11px'}}
                >
                  Puntos Mi Doctor Virtual
                </small>
              </div>
            </div>
          </a>
        </div> */}
        <div className="col mb-4">
          <a
            data-target="#exampleModal"
            data-toggle="modal"
            data-whatever="@getbootstrap"
            onClick={() => setOpenModal(true)}
          >
            <div
              className="card text-center shadow-sm border-0 h-100"
              style={{ borderRadius: "1rem" }}
            >
              <div className="p-2">
                <img
                  src={require("../../assets/img/vectores/cards.svg")}
                  className="card-img-top pb-1 pt-1"
                  alt="user"
                  style={{ width: "50px" }}
                />
                <br />
                <small
                  className="text-primary m-0"
                  style={{ fontSize: "11px" }}
                >
                  Agregar Métodos de pago
                </small>
              </div>
            </div>
          </a>
        </div>
        <div className="col mb-4">
          <NavLink to="/dashboard/historial">
            <div
              className="card text-center shadow-sm border-0 h-100"
              style={{ borderRadius: "1rem" }}
            >
              <div className="p-2">
                <img
                  src={require("../../assets/img/vectores/history-medical.svg")}
                  className="card-img-top pb-1 pt-1"
                  alt="user"
                  style={{ width: "50px" }}
                />
                <br />
                <small
                  className="text-primary m-0"
                  style={{ fontSize: "11px" }}
                >
                  Ver Historial Médico
                </small>
              </div>
            </div>
          </NavLink>
        </div>
        <div className="col mb-4">
          <NavLink to="/dashboard/telellamada">
            <div
              className="card text-center shadow-sm border-0 h-100"
              style={{ borderRadius: "1rem" }}
            >
              <div className="p-2">
                <img
                  src={require("../../assets/img/vectores/atentionmedical.svg")}
                  className="card-img-top pb-1 pt-1"
                  alt="user"
                  style={{ width: "50px" }}
                />
                <br />
                <small
                  className="text-primary m-0"
                  style={{ fontSize: "11px" }}
                >
                  Atención Médica
                </small>
              </div>
            </div>
          </NavLink>
        </div>
      </div>
      <div className="row row-cols-1 justify-content-center mt-2">
        <div className="col mb-4">
          <div className="p-1">
            <h5 className="text-primary mb-3 text-center">Mis Tarjetas</h5>
            {cards.length > 0 ? (
              cards.map((item) => (
                <div
                  key={item._id}
                  className={`credit-card-container ${
                    item.selected ? "credit-card-selected" : null
                  }`}
                >
                  <img
                    src={item.type === "visa" ? visaImg : mastercardImg}
                    alt="card"
                    className="credit-card-image"
                  />
                  <h6 className="card-item-name text-primary">{item.name}</h6>
                  <p className="card-item-prefix">{`termina en   *** ${item.numberPrefix}`}</p>
                  <label className="checkbox-card-item" id="card-item-checkbox">
                    <input
                      type="checkbox"
                      checked={item.selected}
                      onChange={() => handleCheckChange(item._id)}
                    />
                  </label>
                  <IconContext.Provider
                    value={{
                      size: "20px",
                      color: "red",
                    }}
                  >
                    <AiFillDelete onClick={() => deleteCreditCard(item._id)} />
                  </IconContext.Provider>
                </div>
              ))
            ) : (
              <p className="text-center">
                No tienes agregado ningún método de pago.
              </p>
            )}
          </div>
        </div>
      </div>
      {/* <!-- Modal user information patient--> */}
      <div
        className="modal fade"
        id="modalinformationpatient"
        tabIndex="-1"
        aria-labelledby="modalinformationpatientLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-center text-primary
                font-weight-light mx-auto"
                id="modalinformationpatientLabel"
              >
                Información Personal
              </h5>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label
                  htmlFor="nombre"
                  className="font-weight-light text-secondarymdv"
                >
                  Nombres
                </label>
                <input
                  type="text"
                  className="form-control form-control-user"
                  placeholder="Nombre"
                  name="nombre"
                  value={nombre}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="apellidos"
                  className="font-weight-light text-secondarymdv"
                >
                  Apellidos
                </label>
                <input
                  type="text"
                  className="form-control form-control-user"
                  id="lastName3"
                  placeholder="Apellido"
                  name="apellido"
                  value={apellido}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="correo"
                  className="font-weight-light text-secondarymdv"
                >
                  Correo Electrónico
                </label>
                <input
                  type="email"
                  className="form-control form-control-user"
                  id="email"
                  placeholder="Correo Electrónico"
                  name="correo"
                  value={correo}
                  disabled
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="born"
                  className="font-weight-light text-secondarymdv"
                >
                  Fecha de Nacimiento
                </label>
                <input
                  type="date"
                  className="form-control form-control-user"
                  placeholder="Fecha de Nacimiento"
                  name="born"
                  value={born}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="pais"
                  className="font-weight-light text-secondarymdv"
                >
                  País
                </label>
                <Form.Control
                  as="select"
                  className="form-control form-control-user"
                  style={{ marginBottom: 15 }}
                  onChange={(e) => handleChange(e)}
                  name="pais"
                  value={pais}
                  disabled
                >
                  {countries.map((country, key) => (
                    <option key={country.code}>{country.name}</option>
                  ))}
                </Form.Control>
              </div>
              <div className="form-group">
                <label
                  htmlFor="tel"
                  className="font-weight-light text-secondarymdv"
                >
                  Télefono
                </label>
                <div className="input-group">
                  <div
                    className="input-group-prepend"
                    value={dial}
                    name="dial"
                    onChange={(e) => handleChange(e)}
                    disabled
                  >
                    <span
                      className="input-group-text border-left-rounded"
                      id="basic-addon2"
                    >
                      {dial}
                    </span>
                  </div>
                  <input
                    className="form-control"
                    placeholder="Telefono"
                    name="tel"
                    value={tel}
                    onChange={(e) => handleChange(e)}
                    disabled
                  />
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor="genero"
                  className="font-weight-light text-secondarymdv"
                >
                  Género
                </label>
                <select
                  className="form-control form-control-user"
                  id="exampleFormControlSelect1"
                  placeholder="Genero"
                  value={genero}
                  name="genero"
                  onChange={(e) => handleChange(e)}
                >
                  <option>Femenino</option>
                  <option>Masculino</option>
                </select>
              </div>
              <div className="form-group">
                <label
                  htmlFor="identidad"
                  className="font-weight-light text-secondarymdv"
                >
                  Número de Identidad
                </label>
                <input
                  type="number"
                  className="form-control form-control-user"
                  id="dni"
                  placeholder="Número de Identidad"
                  name="identidad"
                  value={identidad}
                  onChange={(e) => handleChange(e)}
                  disabled
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="altura"
                  className="font-weight-light text-secondarymdv"
                >
                  Altura M
                </label>
                <input
                  type="number"
                  className="form-control form-control-user"
                  defaultValue={altura || 0}
                  pattern="^[0-9]"
                  min="1"
                  step=".01"
                  name="altura"
                  value={altura}
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleKeypress(e)}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="peso"
                  className="font-weight-light text-secondarymdv"
                >
                  Peso lb
                </label>
                <input
                  type="number"
                  className="form-control form-control-user"
                  defaultValue={peso || 0}
                  pattern="^[0-9]"
                  min="1"
                  step=".01"
                  name="peso"
                  value={peso}
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleKeypress(e)}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="tipo_sangre"
                  className="font-weight-light text-secondarymdv"
                >
                  Tipo de Sangre
                </label>
                <select
                  className="form-control form-control-user"
                  placeholder="Tipo de Sangre"
                  value={tipo_sangre}
                  name="tipo_sangre"
                  onChange={(e) => handleChange(e)}
                >
                  {tipo_sangres.map((sangre) => (
                    <option key={sangre.value} value={sangre.value}>
                      {sangre.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="row justify-content-center">
                <div className="col-12">
                  <label
                    htmlFor="dependientes"
                    className="font-weight-bold text-secondary"
                  >
                    {loadingPatient
                      ? "Dependientes"
                      : "No tiene dependientes agregados."}
                  </label>
                  {loadingPatient ? (
                    <Spinner color="primary" />
                  ) : (
                    patients.map((user) => (
                      <div className="card" key={user._id}>
                        <div className="p-2">
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                              <h6 className="text-primary">
                                {`${user.data.firstName} ${user.data.lastName}`}
                                <br />
                                <small className="text-black-50">
                                  {
                                    arrayRelations.find(
                                      ({ value }) => value === user.relation
                                    ).label
                                  }
                                </small>
                              </h6>
                              <span>
                                <div className="d-flex">
                                  {/* <button type="button" className="btn btn-light btn-sm mr-2">
                                            <i className="fa fa-edit text-secondarymdv" aria-hidden="true" />
                                          </button> */}
                                  <button
                                    type="button"
                                    className="btn btn-light btn-sm"
                                    onClick={() => {
                                      Swal.fire({
                                        text: "¿Desea eliminar este registro?",
                                        confirmButtonText: "Si",
                                        showCancelButton: true,
                                        cancelButtonText: "No",
                                      }).then((res) => {
                                        if (res.isConfirmed) {
                                          removeDependentPatient({
                                            token: token,
                                            _id: user._id,
                                          })
                                            .then(() => {
                                              updateDependentesPatient();
                                            })
                                            .catch((error) => {
                                              if (error.info) {
                                                createAlert(
                                                  "¡Error!",
                                                  error.info,
                                                  "error"
                                                );
                                              } else {
                                                createAlert(
                                                  "¡Error!",
                                                  "Lo sentimos no se pudo eliminar el dato.",
                                                  "error"
                                                );
                                              }
                                            });
                                        }
                                      });
                                    }}
                                  >
                                    <i
                                      className="fas fa-trash text-danger"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-link btn-sm font-weight-bold mr-2"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={updateData}
                >
                  Actualizar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal user password patient--> */}
      <div
        className="modal fade"
        id="modalpasspatient"
        tabIndex="-1"
        aria-labelledby="modalpasspatientLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-center text-primary
                font-weight-light mx-auto"
                id="modalpasspatientLabel"
              >
                Cambio de Contraseña
              </h5>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label className="font-weight-light text-secondarymdv">
                  Contraseña Anterior
                </label>
                <input
                  type="password"
                  className="form-control center"
                  id="inputPassword1"
                  placeholder="****"
                  name="oldPass"
                  value={oldPass}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="form-group">
                <label className="font-weight-light text-secondarymdv">
                  Nueva Contraseña
                </label>
                <input
                  type="password"
                  className="form-control center"
                  id="inputPassword2"
                  placeholder="***"
                  name="newPass"
                  value={newPass}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="form-group">
                <label className="font-weight-light text-secondarymdv">
                  Repetir Contraseña
                </label>
                <input
                  type="password"
                  className="form-control center"
                  id="inputPassword"
                  placeholder="***"
                  name="rPass"
                  value={rPass}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-link btn-sm font-weight-bold mr-2"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={changepass}
                >
                  Actualizar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal user points mdv patient--> */}
      {/* <div
        className="modal fade"
        id="modalmdvpoints"
        tabIndex="-1"
        aria-labelledby="modalmdvpointsLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-center text-primary
                font-weight-light mx-auto"
                id="modalmdvpointsLabel"
              >
                Puntos Kielsa Clínica & Lab
              </h5>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control text-center"
                  placeholder="Ingrese el codigo aqui"
                  value={uid}
                  onChange={(event) => setUid(event.target.value)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-link btn-sm font-weight-bold mr-2"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Cancelar
                </button>
                <button
                  onClick={() => {
                    exchangeCoupons({token, uid})
                      .then(() => {
                        createAlert(
                          '¡Correcto!',
                          'Se ha canjeado de manera exitosa el cupón',
                          'success',
                        );
                      })
                      .catch((error) => {
                        // console.log(error);
                        if (error.info) {
                          createAlert('¡Error!', error.info, 'error');
                        } else {
                          createAlert(
                            '¡Error!',
                            'El cupón ya fue canjeado',
                            'error',
                          );
                        }
                      });
                  }}
                  type="button"
                  className="btn btn-primary btn-sm"
                >
                  Canjear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  profile: state.profile.profile,
  cards: state.creditCards.items,
});

export default connect(mapStateToProps, {
  updateProfile,
  resetpassword,
  setDefaultCreditCard,
  deleteCreditCard,
})(UserPerfilMovil);

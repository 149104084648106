import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { getGraphicIncome } from '../../redux/actions/graphic.action';
import {
  getQueryRetreats,
  getAmountAvailable,
} from '../../redux/actions/retreat';
import createAlert from '../../redux/actions/alert.action';

function DoctorProfits({
  income,
  getGraphicIncome,
  getQueryRetreats,
  amount,
  getAmountAvailable,
  billeteras,
}) {
  const [formData, setFormData] = useState({
    desde: new Date().toISOString().slice(0, 10),
    hasta: new Date().toISOString().slice(0, 10),
  });
  let totalIncome = 0;
  let countIncome = 0;
  let lunes = 0;
  let martes = 0;
  let miercoles = 0;
  let jueves = 0;
  let viernes = 0;
  let sabado = 0;
  let domingo = 0;
  const { desde, hasta } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (desde <= hasta) {
      getGraphicIncome(desde, hasta);
      getQueryRetreats(desde, hasta);
      getAmountAvailable();
    } else {
      createAlert(
        'Error',
        'La fecha desde Desbe ser menor o igual a la fecha Hasta',
        'error'
      );
    }
  };

  income.data.forEach((element) => {
    if (element._id == 1) {
      domingo = element.totalIncome;
    }
    if (element._id == 2) {
      lunes = element.totalIncome;
    }
    if (element._id == 3) {
      martes = element.totalIncome;
    }
    if (element._id == 4) {
      miercoles = element.totalIncome;
    }
    if (element._id == 5) {
      jueves = element.totalIncome;
    }
    if (element._id == 6) {
      viernes = element.totalIncome;
    }
    if (element._id == 7) {
      sabado = element.totalIncome;
    }
    totalIncome = totalIncome + element.totalIncome;
    countIncome = countIncome + element.total;
  });

  const data = {
    labels: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    datasets: [
      {
        label: 'Ingresos',
        data: [domingo, lunes, martes, miercoles, jueves, viernes, sabado],
        backgroundColor: [
          'rgb(91, 44, 111)',
          'rgb(24, 106, 59)',
          'rgb(21, 67, 96)',
          'rgb(146, 43, 33)',
          'rgb(244, 255, 51)',
          'rgb(95, 106, 106)',
          'rgb(95, 75, 75)',
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  return (
    <div>
      <div className="container-fluid display-table">
        <div className="col-md-12 col-sm-12 display-table-cell v-align">
          <div className="user-dashboard">
            <div className="sales">
              <h2>Ingresos</h2>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12 col-xs-12 gutter">
                <div className="sales scroll-doctor">
                  <div className="row">
                    <div className="btn-group-g">
                      <label className="labl-g">Desde: </label>
                      <input
                        type="date"
                        name="desde"
                        value={desde}
                        onChange={(e) => handleChange(e)}
                      ></input>
                    </div>
                    <div className="btn-group-g">
                      <label className="labl-g">Hasta: </label>
                      <input
                        type="date"
                        name="hasta"
                        value={hasta}
                        onChange={(e) => handleChange(e)}
                      ></input>
                    </div>
                    <div className="btn-group-g">
                      <button
                        type="submit"
                        className="btn btn-g"
                        onClick={handleSubmit}
                      >
                        Ver
                      </button>
                    </div>
                  </div>
                  <div className="row card-amount center-div">
                    <h4 className="text-dash center">L. {totalIncome}</h4>
                    <h5 className="text-dash center">
                      Consultas: {countIncome}
                    </h5>
                  </div>
                </div>

                <div className="sales report">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="btn-wallet marb3">
                        <h6 className="text-dash center">
                          <i className="fas fa-wallet font-blue" /> &nbsp;Mi
                          Billetera
                        </h6>
                        <h6 className="text-dash center">{amount}</h6>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="btn-wal marb3">
                        <Link className="" to="/dashboard/retiros">
                          <h6 className="text-dash center white">
                            <i className="fas fa-credit-card" /> &nbsp;Solicitud
                            de Retiros
                          </h6>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <Bar data={data} options={options} />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-xs-12 gutter">
                <div className="sales">
                  <div className="row  mar-2">
                    <div className="col-md-8">
                      {' '}
                      <h2>Movimiento de mi billetera</h2>
                    </div>
                  </div>
                  <div className="row mar-2">
                    <table className="greyGridTable">
                      <thead>
                        <tr>
                          <th>Fecha</th>
                          <th># de consulta</th>
                          <th>Tipo</th>
                          <th>Valor</th>
                          <th>Balance</th>
                        </tr>
                      </thead>
                      <tfoot />
                      <tbody>
                        {billeteras['consultas'].length > 0 ? (
                          billeteras.consultas.map((consulta, index) => (
                            <tr>
                              <td>{consulta.creationDate}</td>
                              <td>{index + 1}</td>
                              <td>
                                <i
                                  className="fas fa-money-bill font-green"
                                  aria-hidden="true"
                                />
                              </td>
                              <td>{consulta.moneyDoctor}</td>
                              <td>{consulta.moneyDoctor}</td>
                            </tr>
                          ))
                        ) : (
                          <tr></tr>
                        )}
                        {billeteras['retiros'].length > 0 ? (
                          billeteras.retiros.map((retiro, index) => (
                            <tr>
                              <td>{retiro.creationDate}</td>
                              <td>{index + 1}</td>
                              <td>
                                <i
                                  className="fas fa-money-bill font-red"
                                  aria-hidden="true"
                                />
                              </td>
                              <td>{retiro.amount * -1}</td>
                              <td>{retiro.amount * -1}</td>
                            </tr>
                          ))
                        ) : (
                          <tr></tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  income: state.graphic.graphicIncome,
  amount: state.retreat.amountAvailable,
  billeteras: state.retreat.queryRetreats,
});

export default connect(mapStateToProps, {
  getGraphicIncome,
  getQueryRetreats,
  getAmountAvailable,
})(DoctorProfits);

/* eslint-disable max-len */
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Historial from "../../pages/Patient/Dasboard/Historial";
import {
  deleteProfileReference,
  deleteReference,
  getReference,
  getProfileReference,
} from "../../redux/actions/references";
import {
  addSearchPatientId,
  deleteReferenceSearchPatient,
} from "../../redux/actions/profile.action";

const ModalMedicalHistory = ({
  getReferenceApp,
  getProfileReferenceApp,
  patientId,
  addSearchPatientIdApp,
}) => {
  const dispatch = useDispatch();
  const { location } = useHistory();

  useEffect(() => {
    if (location.pathname !== "dashboard/historial") {
      dispatch(deleteReferenceSearchPatient());

      if (patientId) {
        getReferenceApp(patientId);
        getProfileReferenceApp(patientId);
        addSearchPatientIdApp(patientId);
      }
    }
  }, [dispatch, patientId]);

  return (
    <div
      className="modal fade"
      id="ModalMedicalPatient"
      tabIndex="-1"
      aria-labelledby="ModalMedicalPatientLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header border-0 text-center mx-auto">
            <h5
              className="modal-title text-primary font-weight-bold mt-3"
              id="ModalMedicalPatientLabel"
            >
              Historial Médico del Paciente
              <br />
              <small className="mb-3 text-black-50">
                Un historial médico completo y preciso, asegura que nuestros
                especialistas en la salud puedan tener la informacion necesaria
                para brindarte una mejor orientacion.
              </small>
            </h5>
          </div>
          <div className="modal-body">
            <Historial />
          </div>
          <div className="modal-footer">
            <div className="d-flex">
              <button
                type="button"
                className="btn btn-link btn-sm text-secondarymdv font-weight-bold"
                data-dismiss="modal"
                onClick={() => {
                  deleteReference();
                  deleteProfileReference();
                }}
              >
                Cerrar
              </button>
              {/* <button type="button" className="btn btn-primary btn-sm">Actualizar</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  deleteReference,
  deleteProfileReference,
  getProfileReferenceApp: getProfileReference,
  getReferenceApp: getReference,
  addSearchPatientIdApp: addSearchPatientId,
})(ModalMedicalHistory);

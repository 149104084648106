/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { addDependentUser,addDependentUserEmployee } from '../../api/dependentPatient';

import createAlert from '../../redux/actions/alert.action';

const AddDependentModal = ({ show, onClose, token, employeedUser, dataEmployee }) => {
  const [loading, setLoading] = useState(false);
  const [idType, setIdType] = useState('national');
  const [relation, setRelation] = useState('son');
  const [email, setEmail] = useState('');
  const setFormat = () => {
    if (idType === 'national') {
      return '####-####-#####';
    } else if (idType === 'rtn') {
      return '####-####-######';
    }
  };

  return (
    <Modal size="xl" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Creando Usuario Dependiente</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            if (relation === 'other' && !e.target.otherName.value) {
              setLoading(false);
              return alert('Otros es requerido');
            }
            if(employeedUser){
              try {
                const res = await addDependentUserEmployee({
                  token: token,
                  firstName: e.target.firstName.value,
                  lastName: e.target.lastName.value,
                  bornAt: e.target.bornAt.value,
                  dni: e.target.dni.value.replace(/-/g, '').replace(/_/g, ''),
                  email: e.target.email.value,
                  gender: e.target.gender.value,
                  idType: idType,
                  otherName: e.target.otherName?.value,
                  password: e.target.password.value,
                  relation: relation,
                  loggedUser: dataEmployee
                });
                console.log('la respuensta al dependiente es: ', res);
                onClose();
                Swal.fire({
                  icon: 'success',
                  title: 'Se creo exitosamente el usuario dependiente',
                });
              } catch (error) {
                if (error.response) {
                  createAlert(
                    'Error!',
                    error.response?.data?.message,
                    error.response?.data?.status
                  );
                  setLoading(false);
                }
              }
            }else {
              try {
                const res = await addDependentUser({
                  token: token,
                  firstName: e.target.firstName.value,
                  lastName: e.target.lastName.value,
                  bornAt: e.target.bornAt.value,
                  dni: e.target.dni.value.replace(/-/g, '').replace(/_/g, ''),
                  email: e.target.email.value,
                  gender: e.target.gender.value,
                  idType: idType,
                  otherName: e.target.otherName?.value,
                  password: e.target.password.value,
                  relation: relation,
                });
                console.log('la respuensta al dependiente es: ', res);
                onClose();
                Swal.fire({
                  icon: 'success',
                  title: 'Se creo exitosamente el usuario dependiente',
                });
              } catch (error) {
                if (error.response) {
                  createAlert(
                    'Error!',
                    error.response?.data?.message,
                    error.response?.data?.status
                  );
                  setLoading(false);
                }
              }
            }
            setLoading(false);
          }}
        >
          <Row style={{ paddingTop: 15 }}>
            <Col md={6} xs={12}>
              <label
                className="text-secondarymdv font-weight-light"
                style={{ textAlign: 'center', margin: 0, width: '100%' }}
              >
                Nombre
              </label>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control center"
                  id="inputEmail"
                  name="firstName"
                />
              </div>
              <label
                className="text-secondarymdv font-weight-light"
                style={{ textAlign: 'center', margin: 0, width: '100%' }}
              >
                Apellido
              </label>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control center"
                  id="inputEmail"
                  name="lastName"
                />
              </div>
              <label
                className="text-secondarymdv font-weight-light"
                style={{ textAlign: 'center', margin: 0, width: '100%' }}
              >
                Identidad
              </label>
              <div className="form-group">
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  name="idType"
                  value={idType}
                  onChange={(e) => setIdType(e.target.value)}
                  placeholde="Tipo de identificación"
                >
                  <option value="national">ID nacional</option>
                  <option value="foreign">ID extranjero</option>
                  <option value="rtn">RTN</option>
                </select>
              </div>
              <div className="form-group">
                {idType === 'foreign' ? (
                  <input
                    type="text"
                    className="form-control center"
                    name="dni"
                    onBlur={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                ) : (
                  <NumberFormat
                    className="form-control center"
                    format={`${setFormat()}`}
                    onBlur={(e) => {
                      const text = e.target.value.split('-').join('');
                      setEmail(text);
                    }}
                    mask="_"
                    name="dni"
                  />
                )}
              </div>
              <div className="form-group">
                <label
                  className="text-secondarymdv font-weight-light"
                  style={{ textAlign: 'center', margin: 0, width: '100%' }}
                >
                  Relación
                </label>
                <select
                  id="relationPatient"
                  className="form-control w-100"
                  style={{ width: '140px' }}
                  name="relation"
                  value={relation}
                  onChange={(e) => setRelation(e.target.value)}
                >
                  <option value="son">Hij@</option>
                  <option value="grandpa">Abuelo</option>
                  <option value="grandma">Abuela</option>
                  <option value="mother">Madre</option>
                  <option value="father">Padre</option>
                  <option value="uncle">Tí@</option>
                  <option value="other">Otro</option>
                </select>
                {relation === 'other' && (
                  <input
                    type="text"
                    className="form-control center mt-2"
                    placeholder="Ingrese el nombre de la relacion"
                    name="otherName"
                  />
                )}
              </div>
            </Col>
            <Col md={6} xs={12}>
              <label
                className="text-secondarymdv font-weight-light"
                style={{ textAlign: 'center', margin: 0, width: '100%' }}
              >
                Fecha de nacimiento
              </label>
              <div className="form-group">
                <input
                  type="date"
                  className="form-control center"
                  placeholder="Fecha de Nacimiento"
                  name="bornAt"
                />
              </div>
              <label
                className="text-secondarymdv font-weight-light"
                style={{ textAlign: 'center', margin: 0, width: '100%' }}
              >
                Género
              </label>
              <div className="form-group">
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  name="gender"
                  placeholde="Género"
                >
                  <option value="female">Femenino</option>
                  <option value="male">Masculino</option>
                </select>
              </div>
              <label
                className="text-secondarymdv font-weight-light"
                style={{ textAlign: 'center', margin: 0, width: '100%' }}
              >
                Correo Temporal
              </label>
              <div className="form-group">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control center"
                    id="inputEmail"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Ingrese un usuario"
                  />
                  <span className="input-group-text" id="basic-addon2">
                    @dependientekielsa.com
                  </span>
                </div>
              </div>
              <label
                className="text-secondarymdv font-weight-light"
                style={{ textAlign: 'center', margin: 0, width: '100%' }}
              >
                Contraseña
              </label>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control center"
                  id="inputEmail"
                  name="password"
                />
              </div>
            </Col>
          </Row>
          <div className="text-center">
            <button
              disabled={loading}
              type="submit"
              className="btn btn-primary"
            >
              {loading ? 'Procesando ...' : 'Agregando'}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(AddDependentModal);

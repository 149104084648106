/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable global-require */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable arrow-parens */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
// import { Link } from 'react-router-dom';
import { addShoppingItem } from '../../redux/actions/shopping.cart.action';
// import './History/history.css';
import { getAllPackage } from '../../api/packagektm';
import { addPackageKtm } from '../../redux/actions/packagektm.action';
import { addTelemedicinePlan } from '../../redux/actions/telemedicine.plan.action';
import { getTelemedicinePlans } from '../../api/telemedicine.plan';
import { createCashPackageKtm } from '../../api/cash.packagektm';
import { createCashPlan } from '../../api/packagektm';
import usePlans from '../../hooks/plans/usePlans';
function CheckPayment({
  token,
  history,
  addPackageKtm,
  addTelemedicinePlan,
  packagektm,
}) {
  //const [packages, setPackages] = useState([]);
  const [orderNumber, setOrderNumber] = useState('');
  //const [plans, setPlans] = useState([]);
  const [namePatient, setNamePatient] = useState('');
  const [emailPatient, setEmailPatient] = useState('');
  const [phonePatient, setPhonePatient] = useState('');
  /*useEffect(() => {
    getAllPackage({ token }).then((res) => {
      const { data } = res;
      setPackages(data);
    });
    getTelemedicinePlans().then((res) => {
      const { data } = res;
      setPlans(data);
    });
  }, []);*/
  const { plans } = usePlans();

  const handleSubmit = () => {
    //createCashPackageKtm
    createCashPlan({
      idPackageKtm: packagektm._id,
      namePatient,
      emailPatient,
      token,
      orderNumber,
      phonePatient,
    })
      .then((res) => {
        const { data } = res;
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Se creo el codigo del plan medico',
          showConfirmButton: false,
          timer: 1500,
        });
        localStorage.setItem('checkerPaymentCashPackage', JSON.stringify(data));
        /* addPackageKtm(undefined); */
        setNamePatient('');
        history.push('/dashboard/checker/payment');
        setEmailPatient('');
      })
      .catch((error) => {
        if (error.info) {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: error.info,
            showConfirmButton: false,
            timer: 1500,
          });
        } else if (error.response) {
          if (error.response.data?.info) {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: error.response.data?.info,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Lo sentimos no se pudo crear el plan',
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Lo sentimos no se pudo crear el plan',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };

  return (
    <div className="container-fluid mt-4">
      <h2 className="mt-2 text-center text-primary">
        Compra de planes médicos en efectivo
      </h2>
      <img
        alt="banner web planes"
        src={require('../../assets/img/banner-web-planes.jpg')}
        className="img-fluid center-div"
        loading="lazy"
      />
      <div className="container">
        <h5 className="mt-4 text-uppercase text-primary">Datos del cliente</h5>
        <div className="row mt-4 justify-content-center">
          <div className="col-sm-6">
            <div className="form-group">
              <label className="text-primary">Correo eléctronico</label>
              <input
                onChange={(e) => setEmailPatient(e.currentTarget.value)}
                value={emailPatient}
                name="email"
                type="email"
                className="form-control"
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="text-primary">Nombre completo</label>
              <input
                onChange={(e) => setNamePatient(e.currentTarget.value)}
                value={namePatient}
                name="name"
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="text-primary">Ingrese N° de orden</label>
              <NumberFormat
                onChange={(e) => setOrderNumber(e.currentTarget.value)}
                value={orderNumber}
                format="###-###-##-########"
                name="name"
                mask="_"
                className="form-control"
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="text-primary">Ingrese N° teléfono</label>
              <input
                onChange={(e) => setPhonePatient(e.currentTarget.value)}
                value={phonePatient}
                name="name"
                type="text"
                className="form-control"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pr-5 pl-5">
        {/* <h2 className="text-primary mt-5 mb-5 text-center">
         Planes Medicos
        </h2> */}
        <ul
          className="nav nav-pills nav-justified mt-5"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <a
              className="nav-link active"
              id="pills-planpersonal-tab"
              data-toggle="pill"
              href="#pills-planpersonal"
              role="tab"
              aria-controls="planpersonal"
              aria-selected="true"
            >
              Plan Personal
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link"
              id="pills-planfamiliar-tab"
              data-toggle="pill"
              href="#pills-planfamiliar"
              role="tab"
              aria-controls="planfamiliar"
              aria-selected="false"
            >
              Plan Familiar
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link"
              id="pills-plangrupal-tab"
              data-toggle="pill"
              href="#pills-plangrupal"
              role="tab"
              aria-controls="plangrupal"
              aria-selected="false"
            >
              Plan Grupal
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link"
              id="pills-telemedicina-tab"
              data-toggle="pill"
              href="#pills-telemedicina"
              role="tab"
              aria-controls="telemedicina"
              aria-selected="false"
            >
              Sesiones Telemedicina
            </a>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          {/* tab plan personal */}
          <div
            className="tab-pane pt-5 fade show active"
            id="pills-planpersonal"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="row justify-content-center row-cols-1 row-cols-sm-2 row-cols-md-4">
              {plans.length === 0 ? (
                <div className="col">
                  <h5 className="text-center text-black-50">
                    No hay planes disponibles.
                  </h5>
                </div>
              ) : (
                <>
                  {plans
                    .sort((a, b) => a.price - b.price)
                    .map((doc) => {
                      if (
                        doc.type === 'medical' &&
                        doc.coverage === 'individual'
                      ) {
                        return (
                          <div key={doc._id} className="col mb-3">
                            <div
                              className="card border-0 shadow-sm animate__animated animate__fadeIn"
                              style={{ borderRadius: '1rem' }}
                            >
                              <div className="card-header-title text-center">
                                <span
                                  className="h5 w-70 mx-auto px-4 pb-1 pt-1 bg-yellow text-primary shadow-sm"
                                  style={{
                                    borderBottomRightRadius: '0.8rem',
                                    borderBottomLeftRadius: '0.8rem',
                                  }}
                                >
                                  {doc.name}
                                </span>
                                {/* <h4 className="card-title text-primary mt-2 mb-0 text-center">
                            {doc.name}
                          </h4> */}
                                <h2 className="text-white font-weight-bold mt-3 mb-3">
                                  <b style={{ fontSize: '17px' }}>L.</b>
                                  {doc.price
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </h2>
                              </div>
                              <div
                                className="card-body border-0"
                                style={{ marginTop: '110px' }}
                              >
                                <ul className="list-group list-group-flush">
                                  {doc.products.map((product) => (
                                    <li
                                      className="list-group-item d-flex p-1"
                                      style={{
                                        fontSize: '15px',
                                        color: '#797979',
                                      }}
                                    >
                                      <img
                                        src={require('../../assets/img/vectores/comprobado2.svg')}
                                        alt="check"
                                        width="20"
                                        className="img-fluid mr-2"
                                      />{' '}
                                      {product}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              <div
                                className="card-footer bg-white border-0 text-center"
                                style={{ borderRadius: '1rem' }}
                              >
                                <button
                                  type="button"
                                  onClick={() => {
                                    addPackageKtm(doc);
                                  }}
                                  className="btn btn-primary col-md-8 mb-3"
                                >
                                  {packagektm?._id === doc._id
                                    ? 'Seleccionado'
                                    : 'Seleccionar'}
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                </>
              )}
            </div>
          </div>
          {/* fin tab plan personal */}
          {/* tab plan familiar */}
          <div
            className="tab-pane pt-5 fade show"
            id="pills-planfamiliar"
            role="tabpanel"
            aria-labelledby="pills-familiar-tab"
          >
            <div className="row justify-content-center row-cols-1 row-cols-sm-2 row-cols-md-4">
              {plans.length === 0 ? (
                <div className="col">
                  <h5 className="text-center text-black-50">
                    No hay planes disponibles.
                  </h5>
                </div>
              ) : (
                <>
                  {plans
                    .sort((a, b) => a.price - b.price)
                    .map((doc) => {
                      if (
                        doc.type === 'medical' &&
                        doc.coverage === 'familiar'
                      ) {
                        return (
                          <div key={doc._id} className="col mb-3">
                            <div
                              className="card border-0 shadow-sm animate__animated animate__fadeIn"
                              style={{ borderRadius: '1rem' }}
                            >
                              <div className="card-header-title text-center">
                                <span
                                  className="h5 w-70 mx-auto px-4 pb-1 pt-1 bg-yellow text-primary shadow-sm"
                                  style={{
                                    borderBottomRightRadius: '0.8rem',
                                    borderBottomLeftRadius: '0.8rem',
                                  }}
                                >
                                  {doc.name}
                                </span>
                                {/* <h4 className="card-title text-primary mt-2 mb-0 text-center">
                            {doc.name}
                          </h4> */}
                                <h2 className="text-white font-weight-bold mt-3 mb-3">
                                  <b style={{ fontSize: '17px' }}>L.</b>
                                  {doc.price
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </h2>
                              </div>
                              <div
                                className="card-body border-0"
                                style={{ marginTop: '110px' }}
                              >
                                <ul className="list-group list-group-flush">
                                  {doc.products.map((product) => (
                                    <li
                                      className="list-group-item d-flex p-1"
                                      style={{
                                        fontSize: '15px',
                                        color: '#797979',
                                      }}
                                    >
                                      <img
                                        src={require('../../assets/img/vectores/comprobado2.svg')}
                                        alt="check"
                                        width="20"
                                        className="img-fluid mr-2"
                                      />{' '}
                                      {product}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              <div
                                className="card-footer bg-white border-0 text-center"
                                style={{ borderRadius: '1rem' }}
                              >
                                <button
                                  type="button"
                                  onClick={() => {
                                    addPackageKtm(doc);
                                  }}
                                  className="btn btn-primary col-md-8 mb-3"
                                >
                                  {packagektm?._id === doc._id
                                    ? 'Seleccionado'
                                    : 'Seleccionar'}
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                </>
              )}
            </div>
          </div>
          {/* fin tab plan familiar */}
          {/* tab plan grupal */}
          <div
            className="tab-pane pt-5 fade show"
            id="pills-plangrupal"
            role="tabpanel"
            aria-labelledby="pills-plangrupal-tab"
          >
            <div className="row justify-content-center row-cols-1 row-cols-sm-2 row-cols-md-4">
              {plans.length === 0 ? (
                <div className="col">
                  <h5 className="text-center text-black-50">
                    No hay planes disponibles.
                  </h5>
                </div>
              ) : (
                <>
                  {plans
                    .sort((a, b) => a.price - b.price)
                    .map((doc) => {
                      if (doc.type === 'medical' && doc.coverage === 'group') {
                        return (
                          <div key={doc._id} className="col mb-3">
                            <div
                              className="card border-0 shadow-sm animate__animated animate__fadeIn"
                              style={{ borderRadius: '1rem' }}
                            >
                              <div className="card-header-title text-center">
                                <span
                                  className="h5 w-70 mx-auto px-4 pb-1 pt-1 bg-yellow text-primary shadow-sm"
                                  style={{
                                    borderBottomRightRadius: '0.8rem',
                                    borderBottomLeftRadius: '0.8rem',
                                  }}
                                >
                                  {doc.name}
                                </span>
                                {/* <h4 className="card-title text-primary mt-2 mb-0 text-center">
                            {doc.name}
                          </h4> */}
                                <h2 className="text-white font-weight-bold mt-3 mb-3">
                                  <b style={{ fontSize: '17px' }}>L.</b>
                                  {doc.price
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </h2>
                              </div>
                              <div
                                className="card-body border-0"
                                style={{ marginTop: '110px' }}
                              >
                                <ul className="list-group list-group-flush">
                                  {doc.products.map((product) => (
                                    <li
                                      className="list-group-item d-flex p-1"
                                      style={{
                                        fontSize: '15px',
                                        color: '#797979',
                                      }}
                                    >
                                      <img
                                        src={require('../../assets/img/vectores/comprobado2.svg')}
                                        alt="check"
                                        width="20"
                                        className="img-fluid mr-2"
                                      />{' '}
                                      {product}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              <div
                                className="card-footer bg-white border-0 text-center"
                                style={{ borderRadius: '1rem' }}
                              >
                                <button
                                  type="button"
                                  onClick={() => {
                                    addPackageKtm(doc);
                                  }}
                                  className="btn btn-primary col-md-8 mb-3"
                                >
                                  {packagektm?._id === doc._id
                                    ? 'Seleccionado'
                                    : 'Seleccionar'}
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                </>
              )}
            </div>
          </div>
          {/* fin tab plan grupal */}
          {/* tab de plan de telemedicina */}
          <div
            className="tab-pane fade"
            id="pills-telemedicina"
            role="tabpanel"
            aria-labelledby="pills-telemedicina-tab"
          >
            <div className="d-none d-sm-block">
              <h2 className="text-primary center pb-5">
                Elige la opción que mejor te convenga de{' '}
                <b className="text-secondarymdv">Orientación Médica Virtual</b>
              </h2>
            </div>
            <div className="d-block d-sm-none">
              <h5 className="text-primary center mb-4 font-weight-light">
                Elige la opción que mejor te convenga de{' '}
                <b>Orientación Médica Virtual</b>
              </h5>
            </div>
            <div className="row justify-content-center mb-2">
              {plans
                .sort((a, b) => a.price - b.price)
                .map((item) => {
                  if (item.type === 'sessions_telemedicine') {
                    return (
                      <div
                        key={item._id}
                        className="col-12 col-md-3 col-sm-3 mb-3"
                      >
                        <div
                          className="card border-0 shadow-sm animate__animated animate__fadeIn"
                          style={{ borderRadius: '1rem' }}
                        >
                          <div
                            className="text-center bg-primarymdv"
                            style={{ borderRadius: '1rem' }}
                          >
                            <span
                              className="h5 w-70 mx-auto px-4 pb-1 pt-1 bg-yellow text-primary shadow-sm"
                              style={{
                                borderBottomRightRadius: '0.8rem',
                                borderBottomLeftRadius: '0.8rem',
                              }}
                            >
                              {item.name === '4 Sesiones'
                                ? 'Ilimitadas'
                                : item.name}
                            </span>

                            <h2 className="text-white font-weight-bold mt-3 mb-3">
                              <b style={{ fontSize: '17px' }}>L.</b>
                              {item.price}
                            </h2>
                            <button
                              type="button"
                              className="btn btn-light rounded-pill text-primary font-weight-bold col-10 col-md-7 mb-4"
                              onClick={() => {
                                addTelemedicinePlan(item);
                                history.push('/dashboard/telemedicine/invoice');
                              }}
                            >
                              Seleccionar
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              {/* <div className="col-12 col-md-7 col-sm-7">
                <div className="d-none d-sm-block">
                  <h2 className="text-primary center mb-4">
                    Elige la opción que mejor te convenga
                  </h2>
                </div>
                <div className="d-block d-sm-none">
                  <h5 className="text-primary center mb-4 font-weight-light">
                    Elige la opción que mejor te convenga
                  </h5>
                </div>
                {prices?.map(
                  (item) =>
                    item.type === "specialty" && (
                      <div
                        className="row mb-3"
                        key={item._id}
                        onClick={() => addShoppingItem(item._id)}
                      >
                        <div className="col-12 col-md-12 col-sm-12">
                          <div className="btn-udoc card shadow-sm border-0 text-center">
                            {item.quantity === 1 ? (
                              <div className="justify-content-center">
                                <img
                                  src={require("../../assets/img/vectores/doctor.svg")}
                                  className="img-fluid"
                                  alt="user"
                                  style={{ width: "50px" }}
                                />
                              </div>
                            ) : item.quantity === 2 ? (
                              <div className="d-flex justify-content-center">
                                <img
                                  src={require("../../assets/img/vectores/doctor.svg")}
                                  className="img-fluid mr-1"
                                  alt="user"
                                  style={{ width: "50px" }}
                                />
                                <img
                                  src={require("../../assets/img/vectores/doctor.svg")}
                                  className="img-fluid"
                                  alt="user"
                                  style={{ width: "50px" }}
                                />
                              </div>
                            ) : (
                              <div className="d-flex justify-content-center">
                                <img
                                  src={require("../../assets/img/vectores/doctor.svg")}
                                  className="img-fluid mr-1"
                                  alt="user"
                                  style={{ width: "50px" }}
                                />
                                <img
                                  src={require("../../assets/img/vectores/doctor.svg")}
                                  className="img-fluid mr-1"
                                  alt="user"
                                  style={{ width: "50px" }}
                                />
                                <img
                                  src={require("../../assets/img/vectores/doctor.svg")}
                                  className="img-fluid"
                                  alt="user"
                                  style={{ width: "50px" }}
                                />
                              </div>
                            )}
                            <h6 className="text-secondarymdv mt-2 mb-0">
                              {`${item.quantity} Consulta${
                                item.quantity === 1 ? "" : "s"
                              } L. ${item.price}`}
                            </h6>
                            <small className="text-black-50 m-0 p-0">
                              {`Tu precio es de L. ${item.price} si eliges ${
                                item.quantity
                              } consulta${item.quantity === 1 ? "" : "s"}`}
                            </small>
                          </div>
                        </div>
                      </div>
                    )
                )}
              </div> */}
            </div>
          </div>
          {/* tab de planes de telemedicina */}
        </div>
      </div>
      <div className="text-center mb-4">
        <button
          onClick={handleSubmit}
          type="button"
          className="btn btn-primary"
        >
          Realizar Compra
        </button>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  prices: state.prices.items,
  token: state.auth.token,
  packagektm: state.packagektm.packageKtm,
});
export default connect(mapStateToProps, {
  addShoppingItem,
  addPackageKtm,
  addTelemedicinePlan,
})(CheckPayment);

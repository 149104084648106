import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAllEnterprises } from '../../../api/getAllEnterprises';
import createAlert from '../../../redux/actions/alert.action';
import {
  registerPatient,
  registerPatientCompany,
} from '../../../redux/actions/auth.action';
import { countries } from '../../../utils/countries';
import { emailRegx } from '../../../utils/regex';
import { useLoaderButton } from './useLoaderButton';

export const useFormData = () => {
  const [listCompanies, setListCompanies] = useState([]);
  const [enterpriseSelected, setEnterpriseSelected] = useState('');
  const [domainSelected, setDomainSelected] = useState(null);
  const { loaderButton, setLoaderButton } = useLoaderButton();

  const dispatch = useDispatch();

  useEffect(() => {
    getAllEnterprises().then((res) => {
      setListCompanies(res.data);
    });
  }, []);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirm: '',
    email: '',
    role: 'patient',
    gender: 'female',
    dni: '',
    idType: 'national',
    bornAt: '',
    registerType: 'email',
    cellphone: '',
    country: countries[0].name,
    dialCode: countries[0].dial_code,
  });
  const [terms, setTerms] = useState(false);

  const {
    firstName,
    lastName,
    password,
    passwordConfirm,
    email,
    gender,
    dni,
    idType,
    bornAt,
    registerType,
    cellphone,
    dialCode,
    country,
  } = formData;

  const handleChange = (e) => {
    if (e.target.name === 'country') {
      const tempCountry = countries.filter(
        (pais) => pais.name === e.target.value
      );
      setFormData({
        ...formData,
        dialCode: tempCountry[0].dial_code,
        country: tempCountry[0].name,
        email: '',
        password: '',
        passwordConfirm: '',
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmitCompanyRegister = async (e) => {
    e.preventDefault();
    setLoaderButton(true);

    if (!emailRegx.test(`${email}${domainSelected}`)) {
      setLoaderButton(false);
      return createAlert(
        'Error!',
        'Email no cumple con los requisitos',
        'error'
      );
    }

    const tempDni = dni.replace(/-/g, '').replace(/_/g, '');
    if (
      (idType === 'national' && tempDni.length !== 13) ||
      (idType === 'rtn' && tempDni.length !== 14) ||
      (idType === 'foreign' && dni.length < 8)
    ) {
      setLoaderButton(false);
      return createAlert('¡Error!', 'Número de identidad incorrecto.', 'error');
    }

    const formTempData = {
      firstName,
      lastName,
      email: `${email}${domainSelected}`,
      registerType: 'email',
      idType,
      dni: idType === 'foreign' ? dni : tempDni,
      bornAt,
      registerType: 'email',
      gender: gender,
      role: 'patient',
    };

    dispatch(registerPatientCompany(formTempData)).finally(() => {
      setLoaderButton(false);
    });
  };

  const handleSetFormatDni = () => {
    if (idType === 'national') {
      return '####-####-#####';
    } else if (idType === 'rtn') {
      return '####-####-######';
    }
  };

  return {
    handleChange,
    firstName,
    lastName,
    password,
    passwordConfirm,
    email,
    gender,
    dni,
    idType,
    bornAt,
    registerType,
    cellphone,
    dialCode,
    country,
    formData,
    handleSetFormatDni,
    listCompanies,
    enterpriseSelected,
    setEnterpriseSelected,
    domainSelected,
    setDomainSelected,
    terms,
    setTerms,
    handleSubmitCompanyRegister,
    loaderButton,
  };
};

import { useState } from "react";
export const usePasswordShown = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  return {
    passwordShown,
    setPasswordShown,
    togglePassword,
  };
};

import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {toPng} from 'html-to-image';
import Jspdf from 'jspdf';
import logo from '../../assets/img/KCL.png';
import InforPatient from '../../Components/Checker/InfoPatient';
import {searchPatientEmail} from '../../api/cash.packagektm';
import CardDoctorCashPackageKtm from '../../Components/Checker/CardCheckerCashPackage';
import AddPatientChecker from '../../Components/Checker/AddPatientChecker';

const CheckerPayment = ({history, token}) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(undefined);
  const [noPatient, setNoPatient] = useState(false);
  const [existsUser, setExistsUser] = useState(true);
  const data = JSON.parse(localStorage.getItem('checkerPaymentCashPackage'));
  useEffect(() => {
    if (data) {
      searchPatientEmail({token, email: data.emailPatient}).then((res) => {
        const {dataUser, role, noExists} = res;
        if (!noExists) {
          if (role === 'other') {
            setNoPatient(true);
          }
          setUser(dataUser);
        } else {
          setExistsUser(false);
        }
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);
  if (!data) {
    return <Redirect to="/dashboard" />;
  }
  const handleUpdateInfo = () => {
    if (data) {
      searchPatientEmail({token, email: data.emailPatient}).then((res) => {
        const {dataUser, role, noExists} = res;
        if (!noExists) {
          if (role === 'other') {
            setNoPatient(true);
          }
          setUser(dataUser);
        } else {
          setExistsUser(false);
        }
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };
  const date = new Date();
  return (
    <div className="mt-4">
      <div id="card-resume" className="card container">
        <div className="row">
          <div className="p-0">
            <div className="row p-5">
              <div className="col-md-6">
                <img className="w-50" src={logo} alt="logo" />
              </div>

              <div className="col-md-6">
                <p className="text-muted">
                  Fecha:
                  {`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}
                </p>
              </div>
            </div>
            <hr className="my-5" />

            <div className="row pb-5 p-5">
              <div className="col-md-6">
                <p className="font-weight-bold mb-4">Información del Cliente</p>
                <p className="mb-1">
                  {data.namePatient}
                </p>
                <p>
                  {data.emailPatient}
                </p>
              </div>

              <div className="col-md-6 pr-3">
                <p className="font-weight-bold mb-4">Información de Pago</p>
                <p className="mb-1">
                  <span className="text-muted">Tipo de Pago: </span>
                  {' '}
                  Presencial
                </p>
                <p className="mb-1">
                  <span className="text-muted">Codigo Canje: </span>
                  {' '}
                  {data.code}
                </p>
              </div>
            </div>

            <div className="row p-5">
              <div className="col-md-12">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="border-0 text-uppercase small font-weight-bold">Producto</th>
                      <th className="border-0 text-uppercase small font-weight-bold">Cantidad</th>
                      <th className="border-0 text-uppercase small font-weight-bold">Costo</th>
                      <th className="border-0 text-uppercase small font-weight-bold">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {data.package.name}
                      </td>
                      <td>1</td>
                      <td>
                        {data.package.price.toFixed(2)}
                      </td>
                      <td>
                        {data.package.price.toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row bg-dark text-white pt-4 pb-4 m-2">
              <div className="col-sm-4">
                <div className="mb-2">Total</div>
                <div className="h2 font-weight-light">
                  L.
                  {data.package.price.toFixed(2)}
                </div>
              </div>

              <div className="col-sm-4">
                <div className="mb-2">Descuento</div>
                <div className="h2 font-weight-light">0%</div>
              </div>

              <div className="col-sm-4">
                <div className="mb-2">Sub Total</div>
                <div className="h2 font-weight-light">
                  L.
                  {data.package.price.toFixed(2)}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="text-center row mt-4 mb-4">
        <div className="col-md-6">
          <button
            onClick={() => {
              history.push('/dashboard');
              localStorage.removeItem('checkerPaymentCashPackage');
            }}
            className="btn btn-success"
            type="button"
          >
            Continuar
          </button>
        </div>
        <div className="col-md-6">
          <button
            onClick={() => {
              toPng(document.getElementById('card-resume'))
                .then((dataUrl) => {
                  const doc = new Jspdf();
                  doc.addImage(dataUrl, 'PNG', 0, 2, 400, 300);
                  doc.save('Resumen.pdf');
                })
                .catch((error) => {
                  console.log(error);
                  alert('Lo sentimos ocurrio un error al descargar el HEA.');
                });
            }}
            className="btn btn-primary"
            type="button"
          >
            Imprimir
          </button>
        </div>
      </div>
      <div className="p-3 mb-4">
        {
          loading ? <p>Cargando ...</p> : (
            <div className="row text-center">
              {
                !user && !existsUser && (
                  <div className="col-md-6 col-xs-12">
                    <AddPatientChecker emailPatient={data.emailPatient} updateInfo={handleUpdateInfo} />
                  </div>
                )
              }
              {
                user && existsUser && (
                  <div className="col-md-6 col-xs-12">
                    <InforPatient userRef={user} loading={loading} />
                  </div>
                )
              }
              {
                user && existsUser && !noPatient && (
                  <div className="col-md-6 col-xs-12">
                    <CardDoctorCashPackageKtm userRef={user} />
                  </div>
                )
              }
            </div>
          )
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(CheckerPayment);

/* eslint-disable comma-dangle */
/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-parens */
import axios from 'axios';
import {
  DELETE_CREDIT_CARDS,
  ADD_CREDIT_CARDS,
  GET_CREDIT_CARDS,
  SET_DEFAULT_CREDIT_CARD,
} from '../types';
import {mastercard} from '../../utils/regex';
import createAlert from './alert.action';

export const getCreditCards = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/cards');
    dispatch({
      type: GET_CREDIT_CARDS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const setDefaultCreditCard = (id) => async (dispatch) => {
  try {
    const res = await axios.post(`/api/cards/set/${id}`);
    dispatch({type: SET_DEFAULT_CREDIT_CARD, payload: id});
    createAlert('¡Completado!', res.data.message, 'success');
    // createAlert(res.data.status, res.data.message, res.data.status);
  } catch (error) {
    console.log(error);
  }
};

export const deleteCreditCard = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/cards/${id}`);
    dispatch({type: DELETE_CREDIT_CARDS, payload: id});
    createAlert('¡Completado!', 'Tarjeta eliminada con éxito.', 'success');
    // createAlert(res.data.status, res.data.message, res.data.status);
  } catch (error) {
    console.log(error);
  }
};

export const addCreditCard = (number, name, expiry, cvc, selected) => async (
  dispatch
) => {
  // cambiar caducidad de la tarjeta a una fecha valida
  const tempExpiry = `${expiry.substring(0, 2)}/01/${expiry.substring(3, 5)}`;

  // definir el tipo de la tarjeta
  let type;
  if (number.match(mastercard)) {
    type = 'mastercard';
  } else {
    type = 'visa';
  }
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({
    type: type,
    number: number,
    name: name,
    expiry: tempExpiry,
    cvc: cvc,
    selected: selected,
  });

  try {
    const res = await axios.post('/api/cards', body, config);

    dispatch({
      type: ADD_CREDIT_CARDS,
      payload: res.data,
    });
    if (selected) {
      dispatch(setDefaultCreditCard(res.data._id));
    }
    createAlert('¡Correcto!', 'Tarjeta agregada con éxito.', 'success');
  } catch (err) {
    if (err.response !== null) {
      const errors = err.response.data;
      createAlert('¡Error!', errors.message, 'error');
    }
    console.log(err);
  }
};

import React from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import createAlert from '../../redux/actions/alert.action';
import {
  cancelSubscription,
  addSubscription,
  updateToSubscription,
} from '../../redux/actions/auth.action';

const MedicalPlanDetails = ({
  token,
  user,
  planRef,
  cancelSubscription,
  addSubscription,
  updateToSubscription,
}) => {
  const dateOrder = (date) => {
    return (
      date.substring(8, 10) +
      ' - ' +
      date.substring(5, 7) +
      ' - ' +
      date.substring(0, 4)
    );
  };
  const unSuscribe = (date) => {
    var text1 =
      'Te perderas los múltiples beneficios que ofrecemos \n Tu fecha de expiración sera hasta: ' +
      date;
    Swal.fire({
      title: '¿Estas seguro que deseas cancelar la subscripción?',
      text: text1,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'si',
      cancelButtonText: 'no',
    }).then((result) => {
      if (result.isConfirmed) {
        cancelSubscription(planRef._id);
      }
    });
  };

  const suscribe = () => {
    updateToSubscription(planRef._id);
  };

  return (
    <div
      className='container-fluid  col-sm-9 d-flex justify-content-center d-flex align-items-center'
      style={{ height: '100vh' }}
    >
      <div
        className='card border-0 shadow-sm animate__animated animate__fadeIn'
        style={{ borderRadius: '1rem' }}
      >
        <div className='card-header-title text-center p-1'>
          <span
            className='h5 w-70  mx-auto px-4 pb-3 pt-3 bg-yellow text-primary shadow-sm'
            style={{
              borderBottomRightRadius: '0.8rem',
              borderBottomLeftRadius: '0.8rem',
            }}
          >
            {planRef.isSuscription == true ? <>Suscripción de </> : <></>} Plan{' '}
            {planRef?.plan.name}
          </span>
        </div>
        <div className='card-body border-0' style={{ marginTop: '80px' }}>
          <label
            className='d-flex p-2'
            style={{ fontSize: '15px', color: '#797979' }}
          >
            {planRef.isSuscription == true ? (
              <>
                La fecha del próximo pago es:{' '}
                {dateOrder(planRef.dueDate.substring(0, 10))}
              </>
            ) : (
              <>
                La fecha de caducidad del pago es:{' '}
                {dateOrder(planRef.dueDate.substring(0, 10))}
              </>
            )}
          </label>
          <ul className='list-group list-group-flush'>
            {planRef?.plan.products.map((product) => (
              <li
                className='list-group-item d-flex p-2'
                style={{ fontSize: '15px', color: '#797979' }}
                key={product._id}
              >
                <img
                  src={require('../../assets/img/vectores/comprobado2.svg')}
                  alt='check'
                  width='20'
                  className='img-fluid mr-2'
                />{' '}
                {product}
              </li>
            ))}
          </ul>
        </div>
        <div
          className='card-footer bg-white border-0 p-4'
          style={{
            borderRadius: '1rem',
            paddingBottom: '0.1rem',
            marginBottom: '0.1rem',
          }}
        >
          <>
            {!user?.idCompany &&
              !user?.position &&
              !user.isRegisterByDomain && (
                <>
                  {planRef?.isSuscription ? (
                    <>
                      {planRef.isSuscription === true ? (
                        <button
                          onClick={() =>
                            unSuscribe(
                              dateOrder(planRef.dueDate.substring(0, 10))
                            )
                          }
                          type='button'
                          className='btn btn-primary col-md-8 mb-3 m-auto d-block'
                        >
                          Cancelar suscripción
                        </button>
                      ) : (
                        <button
                          onClick={() => suscribe()}
                          type='button'
                          className='btn btn-primary col-md-8 mb-3 m-auto d-block'
                        >
                          Suscribirse
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      onClick={() => suscribe()}
                      type='button'
                      className='btn btn-primary col-md-8 mb-3 m-auto d-block'
                    >
                      Suscribirse
                    </button>
                  )}
                </>
              )}
          </>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  planRef: state.reference.planRef,
  token: state.auth.token,
  user: state.auth.user,
});
export default connect(mapStateToProps, {
  cancelSubscription,
  addSubscription,
  updateToSubscription,
})(MedicalPlanDetails);

/* eslint-disable global-require */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-param-reassign */
import React from 'react';
// import toastr from 'toastr';
import $ from 'jquery';
import Swal from 'sweetalert2';
import {connect} from 'react-redux';
import {
  getOneVideoconference,
  endCallVideconference,
} from '../../api/videoconference';
import {
  observerMessagePatient,
  sendMessage,
  observerEndConsult,
  endChatConsult,
} from '../../websokect/chatWebsockers';
import Loader from '../Spinner/Spinner';
import {uploadChatFile, getListMessages} from '../../api/chat';
import textMinute from '../../extra/minutes';
import {videoconferencesSeconds} from '../../websokect/videoconferenceWebsocket';
import {
  observerDiagnosisConsult,
  observerReasonConsult,
  observerIndicationConsult,
  observerScheduleConsult,
} from '../../websokect/patientWebSocket';
import RatingModal from '../Modal/RatingModal';
import {addVerifyConsult} from '../../redux/actions/videoconference.action';
import {addConsult} from '../../utils/isConsult';

class ChatScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      doctor: undefined,
      message: '',
      loading: true,
      seconds: 0,
      rating: 0,
      limitSeconds: 0,
    };
  }

  componentDidMount() {
    const {user, token, match} = this.props;
    const {params} = match;
    const {roomId} = params;
    const {messages} = this.state;
    getListMessages({token: token, idVideo: roomId})
      .then((response) => {
        const {message} = response;
        this.setState({
          messages: message,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    getOneVideoconference({token: token, idVideo: roomId})
      .then((res) => {
        const {doctor: dataDoctor, videoconference, maxSecondPatient} = res;
        const {secondsPatient, secondsDoctor} = videoconference;
        let dataSeconds = 0;
        this.setState({
          limitSeconds: maxSecondPatient,
        });
        if (user?.role === 'patient') {
          this.setState({
            seconds: secondsPatient,
          });
          dataSeconds = secondsPatient;
        } else {
          this.setState({
            seconds: secondsDoctor,
          });
          dataSeconds = secondsDoctor;
        }
        setInterval(() => {
          dataSeconds += 1;
          this.setState({
            seconds: dataSeconds,
          });
          videoconferencesSeconds({
            roomId: roomId,
            second: dataSeconds,
            userId: user?._id,
          });
        }, 1000);
        this.setState({
          doctor: dataDoctor,
          loading: false,
        });
        observerReasonConsult({idVideo: roomId}, (reason) => {
          Swal.fire({
            position: 'top',
            icon: 'info',
            title: 'Motivo',
            text: reason,
            showConfirmButton: false,
            timer: 5000,
          });
          // toastr.info(reason, 'Motivo', {timeOut: 5000, positionClass: 'toast-bottom-right'});
        });
        observerDiagnosisConsult({idVideo: roomId}, (diagnosis) => {
          Swal.fire({
            position: 'top',
            icon: 'info',
            title: 'Diagnostico',
            text: diagnosis,
            showConfirmButton: false,
            timer: 5000,
          });
          // toastr.info(diagnosis, 'Diagnóstico', {timeOut: 5000, positionClass: 'toast-bottom-right'});
        });
        observerScheduleConsult({idVideo: roomId}, (schedule) => {
          Swal.fire({
            position: 'top',
            icon: 'info',
            title: 'Cita Proxima',
            text: `${schedule.reason}, ${schedule.date}, ${schedule.hour}`,
            showConfirmButton: false,
            timer: 5000,
          });
          // toastr.info(
          //   `${schedule.reason}, ${schedule.date}, ${schedule.hour}`,
          //   'Cita Próxima',
          //   {timeOut: 5000, positionClass: 'toast-bottom-right'},
          // );
        });
        observerIndicationConsult({idVideo: roomId}, (indication) => {
          Swal.fire({
            position: 'top',
            icon: 'info',
            title: 'Indicacion',
            text: `${indication.medicine}, ${indication.dose}, ${indication.frequency}`,
            showConfirmButton: false,
            timer: 3000,
          });
          // toastr.info(
          //   `${indication.medicine}, ${indication.dose}, ${indication.frequency}`,
          //   'Indicación',
          //   {timeOut: 5000, positionClass: 'toast-bottom-right'},
          // );
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
    observerMessagePatient({idVideo: roomId}, (data) => {
      messages.push(data);
      this.setState({
        messages,
      });
    });
    observerEndConsult({idVideo: roomId}, () => {
      Swal.fire({
        position: 'top',
        icon: 'info',
        text: 'El doctor ha salido de la consulta.',
        showConfirmButton: false,
        timer: 3000,
      });
    });
  }

  handleChangeRating = (number) => {
    this.setState({
      rating: number,
    });
  };

  sendMessagePatient = (event) => {
    const {user, match} = this.props;
    const {params} = match;
    const {roomId} = params;
    const {messages} = this.state;
    event.preventDefault();
    const message = event.target.message.value;
    if (message) {
      sendMessage(
        {
          idVideo: roomId,
          type: 'text',
          message: message,
          role: user.role,
          sendUser: user._id,
        },
        (data) => {
          if (data.status === 'success') {
            messages.push(data.data);
            this.setState({
              messages: messages,
              message: '',
            });
            const container = window.document.getElementById('container-messages');
            container.scrollTo(0, container.scrollHeight);
          }
        },
      );
    }
  };

  handleCloseVideoconference = (e) => {
    const {match, token} = this.props;
    const {rating} = this.state;
    const commentPatient = e.target.commentPatient.value;
    const {params} = match;
    const {roomId} = params;
    endCallVideconference({
      token: token,
      endCallDate: new Date(),
      idVideoconference: roomId,
      rating: rating,
      commentPatient: commentPatient,
    })
      .then(() => {
        /* alert('Ha finalizado la videoconferencia'); */
        addConsult();
        window.location.href = '/dashboard';
      })
      .catch((error) => {
        console.log(error);
        /* alert('Ocurrio un error al finalizar la videconferencia'); */
      });
  };

  handleOpenRatingModal = () => {
    const {
      token, match, addVerifyConsult, history,
    } = this.props;
    const {params} = match;
    const {roomId} = params;
    Swal.fire({
      icon: 'info',
      title: '¿Estás seguro de salir de la consulta?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((res) => {
      const {isConfirmed} = res;
      if (isConfirmed) {
        getOneVideoconference({token, idVideo: roomId})
          .then((resp) => {
            const {videoconference} = resp;
            addVerifyConsult(videoconference);
            endChatConsult({idVideo: roomId});
            history.push('/dashboard/rate');
          })
          .catch((error) => {
            console.log(error);
            alert('Ocurrió un error al finalizar el chat.');
          });
      }
    });
  };

  handleChangeMessage = (event) => {
    this.setState({
      message: event.target.value,
    });
  };

  handleUploadFile = () => {
    const {document} = window;
    const {token, match, user} = this.props;
    const {params} = match;
    const {roomId} = params;
    const {messages} = this.state;
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.png, .jpg, .jpeg, .pdf';
    input.onchange = () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('file', file);
      uploadChatFile({token: token, file: formData})
        .then((response) => {
          const {file: dataFile} = response;
          sendMessage(
            {
              idVideo: roomId,
              type: 'file',
              sendUser: user._id,
              idFile: dataFile.id,
              uniqueNameFile: dataFile.filename,
              nameFile: dataFile.originalname,
            },
            (data) => {
              if (data.status === 'success') {
                messages.push(data.data);
                this.setState({
                  messages: messages,
                  message: '',
                });
                const container = window.document.getElementById('container-messages');
                container.scrollTo(0, container.scrollHeight);
              }
            },
          );
        })
        .catch((error) => {
          console.log(error);
        });
    };
    input.click();
  };

  render() {
    const {user} = this.props;
    const {seconds, limitSeconds} = this.state;
    const {
      loading, messages, doctor, message,
    } = this.state;
    if (loading) {
      return <Loader />;
    }
    return (
      <div>
        <div className="container-fluid display-table">
          <div className="col-md-12 col-sm-12 display-table-cell v-align">
            <div className="user-dashboard">
              <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12 gutter">
                  <div className="sales">
                    {' '}
                    <div className="row  mar-2">
                      <div className="col-6">
                        {' '}
                        <h1 className="text-primary ml-2">Chat</h1>
                      </div>
                      <div className="col-6">
                        {' '}
                        <h3 className="f-right text-primary mt-3 mr-2">
                          {limitSeconds > seconds
                            ? textMinute(seconds)
                            : '¡Tiempo Límite!'}
                        </h3>
                      </div>
                    </div>
                    <div className="inbox_people">
                      <div className="row chatblue">
                        <div className="div-cnt-med center-div">
                          <img
                            className="media-object img-circle img-doc-3 center-div mt-3"
                            src={
                              doctor?.publicData.image
                                ? `/api/profile/image/${doctor?.publicData.image}`
                                : require('../../assets/img/avatars/avatar-doc-male.png')
                            }
                            alt="Imagen de Perfil"
                          />
                          <div className="center">
                            {doctor ? (
                              <>
                                <h4 className="white mar-2">
                                  {`${doctor.publicData.gender === 'malee'
                                    ? 'Dr.'
                                    : 'Dra.'
                                  } `}
                                  {doctor.publicData.firstName}
                                  {' '}
                                  {doctor.publicData.lastName}
                                </h4>
                                <span className="label-med">
                                  {doctor.profileData.specialty}
                                </span>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="row">&nbsp;</div>
                        </div>
                      </div>
                    </div>
                    <div className="row f-right mar-2">
                      <button
                        type="button"
                        className="btn btn-light btn-block border bg-danger text-white"
                        onClick={this.handleOpenRatingModal}
                        data-toggle="modal"
                        data-target="#exampleModal"
                        style={{borderRadius: '0.8rem', height: '77px'}}
                        data-whatever="@getbootstrap"
                      >
                        <i className="fas fa-sign-out-alt fa-2x" />
                      </button>
                    </div>
                  </div>
                  <div className="p-3">
                    <p className="text-secondarymdv font-weight-bold">
                      Importante: Si tiene alguna emergencia llamar al 911.
                    </p>
                  </div>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12 gutter">
                  <div className="sales">
                    {' '}
                    <div className="mesgs">
                      {limitSeconds > seconds ? (
                        <>
                          <div id="container-messages" className="msg_history">
                            <div className="incoming_msg">
                              <div className="d-flex flex-row align-items-center">
                                {' '}
                                <img
                                  src={require('../../assets/img/KCL.png')}
                                  alt="sunil"
                                  style={{
                                    width: '5rem',
                                    height: '5rem',
                                    objectFit: 'contain',
                                  }}
                                />
                                {' '}
                                <div className="received_msg">
                                  <div className="received_withd_msg">
                                    <p>
                                      {`${doctor.publicData.gender === 'malee'
                                        ? 'El Dr.'
                                        : 'La Dra.'
                                      } `}
                                      {' '}
                                      de Kielsa Clínica y Lab se comunicará contigo en breve,
                                      prepárate para recibir tu cita médica.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {messages.map((doc) => {
                              if (doc.sendUser === user._id) {
                                if (doc.type === 'text') {
                                  return (
                                    <div className="outgoing_msg">
                                      <div className="sent_msg">
                                        <p>{doc.message}</p>
                                        <span className="time_date">
                                          {' '}
                                          11:01 AM | Hoy
                                          {' '}
                                          <i className="fas fa-check-double doublecheck" />
                                        </span>
                                      </div>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div className="outgoing_msg">
                                      <div className="sent_msg">
                                        <p>
                                          <i className="fas fa-file-alt" />
                                          {' '}
                                          <a
                                            target="_blank"
                                            href={`/api/chat/file/${doc.uniqueNameFile}`}
                                          >
                                            {doc.nameFile}
                                          </a>
                                        </p>
                                        <span className="time_date">
                                          {' '}
                                          11:01 AM | Hoy
                                          {' '}
                                          <i className="fas fa-check-double doublecheck" />
                                        </span>
                                      </div>
                                    </div>
                                  );
                                }
                              } else if (doc.type === 'text') {
                                return (
                                  <div className="incoming_msg">
                                    <div className="incoming_msg_img">
                                      {' '}
                                      <img
                                        className="img-chat"
                                        src={
                                          doctor?.publicData.image
                                            ? `/api/profile/image/${doctor?.publicData.image}`
                                            : require('../../assets/img/avatars/avatar-doc-male.png')
                                        }
                                        alt="sunil"
                                      />
                                      {' '}
                                    </div>
                                    <div className="received_msg">
                                      <div className="received_withd_msg">
                                        <p>{doc.message}</p>
                                        <span className="time_date">
                                          {' '}
                                          11:01 AM | Hoy
                                          {' '}
                                          <i className="fas fa-check-double doublecheck" />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                );
                              } else {
                                return (
                                  <div className="incoming_msg">
                                    <div className="incoming_msg_img">
                                      {' '}
                                      <img
                                        className="img-chat"
                                        src={
                                          doctor?.publicData.image
                                            ? `/api/profile/image/${doctor?.publicData.image}`
                                            : require('../../assets/img/avatars/avatar-doc-male.png')
                                        }
                                        alt="sunil"
                                      />
                                      {' '}
                                    </div>
                                    <div className="received_msg">
                                      <div className="received_withd_msg">
                                        <p>
                                          <i className="fas fa-file-alt" />
                                          {' '}
                                          <a
                                            target="_blank"
                                            href={`/api/chat/file/${doc.uniqueNameFile}`}
                                          >
                                            {doc.nameFile}
                                          </a>
                                        </p>
                                        <span className="time_date">
                                          {' '}
                                          11:01 AM | Hoy
                                          {' '}
                                          <i className="fas fa-check-double doublecheck" />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                          <div className="type_msg">
                            <div className="align-i">
                              <button
                                type="button"
                                className="btn"
                                onClick={this.handleUploadFile}
                              >
                                <i className="fas fa-paperclip " />
                              </button>
                            </div>
                            <form
                              id="form-message"
                              onSubmit={this.sendMessagePatient}
                              className="input_msg_write align-input "
                            >
                              <input
                                value={message}
                                onChange={this.handleChangeMessage}
                                type="text"
                                className="write_msg"
                                name="message"
                                placeholder="Escribe un mensaje"
                              />
                              <button className="msg_send_btn" type="submit">
                                <i className="far fa-paper-plane" />
                              </button>
                            </form>
                          </div>
                        </>
                      ) : (
                        <div>
                          <h3 className="text-center">
                            Se ha llegado al tiempo limite de la consulta
                          </h3>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RatingModal
          handleSubmit={this.handleCloseVideoconference}
          ratingChanged={this.handleChangeRating}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps, {addVerifyConsult})(ChatScreen);

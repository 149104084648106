/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable arrow-parens */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { addShoppingItem } from '../../redux/actions/shopping.cart.action';
import './History/history.css';
import { getAllPackage, getAllPackageCompany } from '../../api/packagektm';
import { addPackageKtm } from '../../redux/actions/packagektm.action';
import { addTelemedicinePlan } from '../../redux/actions/telemedicine.plan.action';
import { getTelemedicinePlans } from '../../api/telemedicine.plan';
import Loader from '../../Components/Spinner/Spinner';
import usePlans from '../../hooks/plans/usePlans';
function CheckPayment({
  token,
  user,
  history,
  addPackageKtm,
  addTelemedicinePlan,
}) {
  const [loadingPage, setLoadinPage] = useState(false);
  // const [plans, setplans] = useState([]);
  // const [plans, setPlans] = useState([]);
  // const [companyMember, setCompanyMember] = useState(false);

  // const getAllPackageNormal = () => {
  //   getAllPackage({ token }).then((res) => {
  //     const { data } = res;
  //     setPackages(data);
  //     setCompanyMember(false);
  //     setLoadinPage(false);
  //   });
  // };

  const { plans } = usePlans();

  return (
    <div className='container-fluid'>
      <img
        alt='image-plans'
        src={require('../../assets/img/banner-web-planes.jpg')}
        className='img-fluid center-div mt-5'
      />
      {loadingPage ? (
        <Loader />
      ) : (
        <div className='pr-2 pl-2'>
          {user?.isRegisterByDomain ? (
            <>
              {false ? (
                <>
                  {/*Mapeo de planes en caso de estar registrado por dominio y tener planes especiales*/}
                  <div className='grid-layout  mt-5 mb-3 justify-content-center'>
                    {plans.map((doc) => (
                      <>
                        <div
                          className='card border-0 shadow-sm animate__animated animate__fadeIn'
                          style={{ borderRadius: '1rem' }}
                          key={doc._id}
                        >
                          <div className='card-header-title text-center'>
                            <span
                              className='h5 w-80 mx-auto pr-20 pl-20 pb-2 pt-2 bg-yellow text-primary shadow-sm'
                              style={{
                                borderBottomRightRadius: '0.8rem',
                                borderBottomLeftRadius: '0.8rem',
                                paddingLeft: '0.6rem',
                                paddingRight: '0.6rem',
                              }}
                            >
                              {doc.name}
                            </span>
                            <h2 className='text-white font-weight-bold mt-3 mb-3'>
                              <b style={{ fontSize: '17px' }}>L.</b>
                              {doc.price
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </h2>
                          </div>
                          <div
                            className='card-body border-0'
                            style={{ marginTop: '110px' }}
                          >
                            <ul className='list-group list-group-flush'>
                              {doc.products.map((product) => (
                                <li
                                  className='list-group-item d-flex p-1'
                                  style={{ fontSize: '15px', color: '#797979' }}
                                  key={product._id}
                                >
                                  <img
                                    src={require('../../assets/img/vectores/comprobado2.svg')}
                                    alt='check'
                                    width='20'
                                    className='img-fluid mr-2'
                                  />{' '}
                                  {product}
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div
                            className='card-footer bg-white border-0'
                            style={{
                              borderRadius: '1rem',
                              paddingBottom: '0.1rem',
                              marginBottom: '0.1rem',
                            }}
                          >
                            <button
                              type='button'
                              onClick={() => {
                                addPackageKtm(doc);
                                history.push('/dashboard/planes/invoice');
                              }}
                              className='btn btn-primary col-md-8 mb-3 m-auto d-block'
                            >
                              Comprar Ahora
                            </button>
                          </div>
                          {!user.isRegisterByDomain && !user.idCompany && (
                            <div
                              className='card-footer bg-white border-0'
                              style={{
                                borderRadius: '1rem',
                                paddingTop: '0.01rem',
                              }}
                            >
                              <button
                                style={{
                                  backgroundColor: 'rgb(255,221,0)',
                                  color: 'Black',
                                }}
                                type='button'
                                onClick={() => {
                                  addPackageKtm(doc);
                                  history.push('/dashboard/planes/suscription');
                                }}
                                className='btn btn-primary col-md-8 mb-3 m-auto d-block'
                              >
                                Suscribirse
                              </button>
                            </div>
                          )}
                        </div>
                      </>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  {/*Mapeo de planes en caso de estar registrado por dominio y no tener planes especiales*/}
                  <ul
                    className='nav nav-pills nav-justified mt-5'
                    id='pills-tab'
                    role='tablist'
                  >
                    <li className='nav-item' role='presentation'>
                      <a
                        className='nav-link active'
                        id='pills-planpersonal-tab'
                        data-toggle='pill'
                        href='#pills-planpersonal'
                        role='tab'
                        aria-controls='planpersonal'
                        aria-selected='true'
                      >
                        Plan Personal
                      </a>
                    </li>
                    <li className='nav-item' role='presentation'>
                      <a
                        className='nav-link'
                        id='pills-planfamiliar-tab'
                        data-toggle='pill'
                        href='#pills-planfamiliar'
                        role='tab'
                        aria-controls='planfamiliar'
                        aria-selected='false'
                      >
                        Plan Familiar
                      </a>
                    </li>
                    <li className='nav-item' role='presentation'>
                      <a
                        className='nav-link'
                        id='pills-plangrupal-tab'
                        data-toggle='pill'
                        href='#pills-plangrupal'
                        role='tab'
                        aria-controls='plangrupal'
                        aria-selected='false'
                      >
                        Plan Grupal
                      </a>
                    </li>
                    <li className='nav-item' role='presentation'>
                      <a
                        className='nav-link'
                        id='pills-telemedicina-tab'
                        data-toggle='pill'
                        href='#pills-telemedicina'
                        role='tab'
                        aria-controls='telemedicina'
                        aria-selected='false'
                      >
                        Sesiones Telemedicina
                      </a>
                    </li>
                  </ul>
                  <div className='tab-content' id='pills-tabContent'>
                    {/* tab plan personal */}
                    <div
                      className='tab-pane pt-5 fade show active'
                      id='pills-planpersonal'
                      role='tabpanel'
                      aria-labelledby='pills-home-tab'
                    >
                      <div className='grid-layout justify-content-center mb-3'>
                        {plans.length === 0 ? (
                          <div className='p-3'>
                            <h5 className='text-center text-black-50'>
                              No hay planes disponibles.
                            </h5>
                          </div>
                        ) : (
                          <>
                            {plans
                              .sort((a, b) => a.price - b.price)
                              .map((doc) => {
                                if (
                                  doc.type === 'medical' &&
                                  doc.coverage === 'individual'
                                ) {
                                  return (
                                    <div
                                      className='card border-0 shadow-sm animate__animated animate__fadeIn'
                                      style={{ borderRadius: '1rem' }}
                                      key={doc._id}
                                    >
                                      <div className='card-header-title text-center'>
                                        <span
                                          className='h5 w-70 mx-auto px-4 pb-1 pt-1 bg-yellow text-primary shadow-sm'
                                          style={{
                                            borderBottomRightRadius: '0.8rem',
                                            borderBottomLeftRadius: '0.8rem',
                                          }}
                                        >
                                          {doc.name}
                                        </span>
                                        <h2 className='text-white font-weight-bold mt-3 mb-3'>
                                          <b style={{ fontSize: '17px' }}>L.</b>
                                          {doc.price
                                            .toFixed(2)
                                            .toString()
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ','
                                            )}
                                        </h2>
                                      </div>
                                      <div
                                        className='card-body border-0'
                                        style={{ marginTop: '110px' }}
                                      >
                                        <ul className='list-group list-group-flush'>
                                          {doc.products.map((product) => (
                                            <li
                                              className='list-group-item d-flex p-1'
                                              style={{
                                                fontSize: '15px',
                                                color: '#797979',
                                              }}
                                              key={product._id}
                                            >
                                              <img
                                                src={require('../../assets/img/vectores/comprobado2.svg')}
                                                alt='check'
                                                width='20'
                                                className='img-fluid mr-2'
                                              />{' '}
                                              {product}
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                      <div
                                        className='card-footer bg-white border-0'
                                        style={{
                                          borderRadius: '1rem',
                                          paddingBottom: '0.1rem',
                                          marginBottom: '0.1rem',
                                        }}
                                      >
                                        <button
                                          type='button'
                                          onClick={() => {
                                            addPackageKtm(doc);
                                            history.push(
                                              '/dashboard/planes/invoice'
                                            );
                                          }}
                                          className='btn btn-primary col-md-8 mb-3 m-auto d-block'
                                        >
                                          Comprar Ahora
                                        </button>
                                      </div>
                                      <div
                                        className='card-footer bg-white border-0'
                                        style={{
                                          borderRadius: '1rem',
                                          paddingTop: '0.01rem',
                                        }}
                                      >
                                        {!user.isRegisterByDomain &&
                                          !user.idCompany && (
                                            <button
                                              style={{
                                                backgroundColor:
                                                  'rgb(255,221,0)',
                                                color: 'Black',
                                              }}
                                              type='button'
                                              onClick={() => {
                                                addPackageKtm(doc);
                                                history.push(
                                                  '/dashboard/planes/suscription'
                                                );
                                              }}
                                              className='btn btn-primary col-md-8 mb-3 m-auto d-block'
                                            >
                                              Suscribirse
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                          </>
                        )}
                      </div>
                    </div>
                    {/* fin tab plan personal */}
                    {/* tab plan familiar */}
                    <div
                      className='tab-pane pt-5 fade show'
                      id='pills-planfamiliar'
                      role='tabpanel'
                      aria-labelledby='pills-familiar-tab'
                    >
                      <div className='grid-layout justify-content-center mb-3'>
                        {plans.length === 0 ? (
                          <div className='p-3'>
                            <h5 className='text-center text-black-50'>
                              No hay planes disponibles.
                            </h5>
                          </div>
                        ) : (
                          <>
                            {plans
                              .sort((a, b) => a.price - b.price)
                              .map((doc) => {
                                if (
                                  doc.type === 'medical' &&
                                  doc.coverage === 'familiar'
                                ) {
                                  return (
                                    <div
                                      className='card border-0 shadow-sm animate__animated animate__fadeIn'
                                      style={{ borderRadius: '1rem' }}
                                      key={doc._id}
                                    >
                                      <div className='card-header-title text-center'>
                                        <span
                                          className='h5 w-70 mx-auto px-4 pb-1 pt-1 bg-yellow text-primary shadow-sm'
                                          style={{
                                            borderBottomRightRadius: '0.8rem',
                                            borderBottomLeftRadius: '0.8rem',
                                          }}
                                        >
                                          {doc.name}
                                        </span>
                                        <h2 className='text-white font-weight-bold mt-3 mb-3'>
                                          <b style={{ fontSize: '17px' }}>L.</b>
                                          {doc.price
                                            .toFixed(2)
                                            .toString()
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ','
                                            )}
                                        </h2>
                                      </div>
                                      <div
                                        className='card-body border-0'
                                        style={{ marginTop: '110px' }}
                                      >
                                        <ul className='list-group list-group-flush'>
                                          {doc.products.map((product) => (
                                            <li
                                              className='list-group-item d-flex p-1'
                                              style={{
                                                fontSize: '15px',
                                                color: '#797979',
                                              }}
                                              key={product._id}
                                            >
                                              <img
                                                src={require('../../assets/img/vectores/comprobado2.svg')}
                                                alt='check'
                                                width='20'
                                                className='img-fluid mr-2'
                                              />{' '}
                                              {product}
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                      <div
                                        className='card-footer bg-white border-0'
                                        style={{
                                          borderRadius: '1rem',
                                          paddingBottom: '0.1rem',
                                          marginBottom: '0.1rem',
                                        }}
                                      >
                                        <button
                                          type='button'
                                          onClick={() => {
                                            addPackageKtm(doc);
                                            history.push(
                                              '/dashboard/planes/invoice'
                                            );
                                          }}
                                          className='btn btn-primary col-md-8 mb-3 m-auto d-block'
                                        >
                                          Comprar Ahora
                                        </button>
                                      </div>
                                      <div
                                        className='card-footer bg-white border-0'
                                        style={{
                                          borderRadius: '1rem',
                                          paddingTop: '0.01rem',
                                        }}
                                      >
                                        {!user.isRegisterByDomain &&
                                          !user.idCompany && (
                                            <button
                                              style={{
                                                backgroundColor:
                                                  'rgb(255,221,0)',
                                                color: 'Black',
                                              }}
                                              type='button'
                                              onClick={() => {
                                                addPackageKtm(doc);
                                                history.push(
                                                  '/dashboard/planes/suscription'
                                                );
                                              }}
                                              className='btn btn-primary col-md-8 mb-3 m-auto d-block'
                                            >
                                              Suscribirse
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                          </>
                        )}
                      </div>
                    </div>
                    {/* fin tab plan familiar */}
                    {/* tab plan grupal */}
                    <div
                      className='tab-pane pt-5 fade show'
                      id='pills-plangrupal'
                      role='tabpanel'
                      aria-labelledby='pills-plangrupal-tab'
                    >
                      <div className='grid-layout justify-content-center mb-3'>
                        {plans.length === 0 ? (
                          <div className='p-3'>
                            <h5 className='text-center text-black-50'>
                              No hay planes disponibles.
                            </h5>
                          </div>
                        ) : (
                          <>
                            {plans
                              .sort((a, b) => a.price - b.price)
                              .map((doc) => {
                                if (
                                  doc.numberReference >= 9 &&
                                  doc.numberReference <= 12
                                ) {
                                  return (
                                    <div
                                      className='card border-0 shadow-sm animate__animated animate__fadeIn'
                                      style={{ borderRadius: '1rem' }}
                                      key={doc._id}
                                    >
                                      <div className='card-header-title text-center'>
                                        <span
                                          className='h5 w-70 mx-auto px-4 pb-1 pt-1 bg-yellow text-primary shadow-sm'
                                          style={{
                                            borderBottomRightRadius: '0.8rem',
                                            borderBottomLeftRadius: '0.8rem',
                                          }}
                                        >
                                          {doc.name}
                                        </span>
                                        <h2 className='text-white font-weight-bold mt-3 mb-3'>
                                          <b style={{ fontSize: '17px' }}>L.</b>
                                          {doc.price
                                            .toFixed(2)
                                            .toString()
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ','
                                            )}
                                        </h2>
                                      </div>
                                      <div
                                        className='card-body border-0'
                                        style={{ marginTop: '110px' }}
                                      >
                                        <ul className='list-group list-group-flush'>
                                          {doc.products.map((product) => (
                                            <li
                                              className='list-group-item d-flex p-1'
                                              style={{
                                                fontSize: '15px',
                                                color: '#797979',
                                              }}
                                              key={product._id}
                                            >
                                              <img
                                                src={require('../../assets/img/vectores/comprobado2.svg')}
                                                alt='check'
                                                width='20'
                                                className='img-fluid mr-2'
                                              />{' '}
                                              {product}
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                      <div
                                        className='card-footer bg-white border-0'
                                        style={{
                                          borderRadius: '1rem',
                                          paddingBottom: '0.1rem',
                                          marginBottom: '0.1rem',
                                        }}
                                      >
                                        <button
                                          type='button'
                                          onClick={() => {
                                            addPackageKtm(doc);
                                            history.push(
                                              '/dashboard/planes/invoice'
                                            );
                                          }}
                                          className='btn btn-primary col-md-8 mb-3 m-auto d-block'
                                        >
                                          Comprar Ahora
                                        </button>
                                      </div>
                                      <div
                                        className='card-footer bg-white border-0'
                                        style={{
                                          borderRadius: '1rem',
                                          paddingTop: '0.01rem',
                                        }}
                                      >
                                        {!user.isRegisterByDomain &&
                                          !user.idCompany && (
                                            <button
                                              style={{
                                                backgroundColor:
                                                  'rgb(255,221,0)',
                                                color: 'Black',
                                              }}
                                              type='button'
                                              onClick={() => {
                                                addPackageKtm(doc);
                                                history.push(
                                                  '/dashboard/planes/suscription'
                                                );
                                              }}
                                              className='btn btn-primary col-md-8 mb-3 m-auto d-block'
                                            >
                                              Suscribirse
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                          </>
                        )}
                      </div>
                    </div>
                    {/* fin tab plan grupal */}
                    {/* tab de plan de telemedicina */}
                    <div
                      className='tab-pane fade'
                      id='pills-telemedicina'
                      role='tabpanel'
                      aria-labelledby='pills-telemedicina-tab'
                    >
                      <div className='d-none d-sm-block'>
                        <h2 className='text-primary center pb-5'>
                          Elige la opción que mejor te convenga de{' '}
                          <b className='text-secondarymdv'>
                            Orientación Médica Virtual
                          </b>
                        </h2>
                      </div>
                      <div className='d-block d-sm-none'>
                        <h5 className='text-primary center mb-4 font-weight-light'>
                          Elige la opción que mejor te convenga de{' '}
                          <b>Orientación Médica Virtual</b>
                        </h5>
                      </div>
                      <div className='grid-layout justify-content-center mb-3'>
                        {plans
                          .sort((a, b) => a.price - b.price)
                          .map((item) => (
                            <div key={item._id}>
                              <div
                                className='card border-0 shadow-sm animate__animated animate__fadeIn'
                                style={{ borderRadius: '1rem' }}
                              >
                                <div
                                  className='text-center bg-primarymdv'
                                  style={{ borderRadius: '1rem' }}
                                >
                                  <span
                                    className='h5 w-70 mx-auto px-4 pb-1 pt-1 bg-yellow text-primary shadow-sm'
                                    style={{
                                      borderBottomRightRadius: '0.8rem',
                                      borderBottomLeftRadius: '0.8rem',
                                    }}
                                  >
                                    {item.name === '4 Sesiones'
                                      ? 'Ilimitadas'
                                      : item.name}
                                  </span>
                                  <h2 className='text-white font-weight-bold mt-3 mb-3'>
                                    <b style={{ fontSize: '17px' }}>L.</b>
                                    {item.price}
                                  </h2>
                                  <button
                                    type='button'
                                    className='btn btn-light rounded-pill text-primary font-weight-bold col-10 col-md-7 mb-4'
                                    onClick={() => {
                                      addTelemedicinePlan(item);
                                      history.push(
                                        '/dashboard/telemedicine/invoice'
                                      );
                                    }}
                                  >
                                    Comprar Ahora
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                    {/* tab de planes de telemedicina */}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {/*Mapeo de planes en caso de no estar registrado por dominio */}
              <ul
                className='nav nav-pills nav-justified mt-5'
                id='pills-tab'
                role='tablist'
              >
                <li className='nav-item' role='presentation'>
                  <a
                    className='nav-link active'
                    id='pills-planpersonal-tab'
                    data-toggle='pill'
                    href='#pills-planpersonal'
                    role='tab'
                    aria-controls='planpersonal'
                    aria-selected='true'
                  >
                    Plan Personal
                  </a>
                </li>
                <li className='nav-item' role='presentation'>
                  <a
                    className='nav-link'
                    id='pills-planfamiliar-tab'
                    data-toggle='pill'
                    href='#pills-planfamiliar'
                    role='tab'
                    aria-controls='planfamiliar'
                    aria-selected='false'
                  >
                    Plan Familiar
                  </a>
                </li>
                <li className='nav-item' role='presentation'>
                  <a
                    className='nav-link'
                    id='pills-plangrupal-tab'
                    data-toggle='pill'
                    href='#pills-plangrupal'
                    role='tab'
                    aria-controls='plangrupal'
                    aria-selected='false'
                  >
                    Plan Grupal
                  </a>
                </li>
                <li className='nav-item' role='presentation'>
                  <a
                    className='nav-link'
                    id='pills-telemedicina-tab'
                    data-toggle='pill'
                    href='#pills-telemedicina'
                    role='tab'
                    aria-controls='telemedicina'
                    aria-selected='false'
                  >
                    Sesiones Telemedicina
                  </a>
                </li>
              </ul>
              <div className='tab-content' id='pills-tabContent'>
                {/* tab plan personal */}
                <div
                  className='tab-pane pt-5 fade show active'
                  id='pills-planpersonal'
                  role='tabpanel'
                  aria-labelledby='pills-home-tab'
                >
                  <div className='grid-layout justify-content-center mb-3'>
                    {plans.length === 0 ? (
                      <div className='p-3'>
                        <h5 className='text-center text-black-50'>
                          No hay planes disponibles.
                        </h5>
                      </div>
                    ) : (
                      <>
                        {plans
                          .sort((a, b) => a.price - b.price)
                          .map((doc) => {
                            if (
                              doc.type === 'medical' &&
                              doc.coverage === 'individual'
                            ) {
                              return (
                                <div
                                  className='card border-0 shadow-sm animate__animated animate__fadeIn'
                                  style={{ borderRadius: '1rem' }}
                                  key={doc._id}
                                >
                                  <div className='card-header-title text-center'>
                                    <span
                                      className='h5 w-70 mx-auto px-4 pb-1 pt-1 bg-yellow text-primary shadow-sm'
                                      style={{
                                        borderBottomRightRadius: '0.8rem',
                                        borderBottomLeftRadius: '0.8rem',
                                      }}
                                    >
                                      {doc.name}
                                    </span>
                                    <h2 className='text-white font-weight-bold mt-3 mb-3'>
                                      <b style={{ fontSize: '17px' }}>L.</b>
                                      {doc.price
                                        .toFixed(2)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    </h2>
                                  </div>
                                  <div
                                    className='card-body border-0'
                                    style={{ marginTop: '110px' }}
                                  >
                                    <ul className='list-group list-group-flush'>
                                      {doc.products.map((product) => (
                                        <li
                                          className='list-group-item d-flex p-1'
                                          style={{
                                            fontSize: '15px',
                                            color: '#797979',
                                          }}
                                          key={product._id}
                                        >
                                          <img
                                            src={require('../../assets/img/vectores/comprobado2.svg')}
                                            alt='check'
                                            width='20'
                                            className='img-fluid mr-2'
                                          />{' '}
                                          {product}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                  <div
                                    className='card-footer bg-white border-0'
                                    style={{
                                      borderRadius: '1rem',
                                      paddingBottom: '0.1rem',
                                      marginBottom: '0.1rem',
                                    }}
                                  >
                                    <button
                                      type='button'
                                      onClick={() => {
                                        addPackageKtm(doc);
                                        history.push(
                                          '/dashboard/planes/invoice'
                                        );
                                      }}
                                      className='btn btn-primary col-md-8 mb-3 m-auto d-block'
                                    >
                                      Comprar Ahora
                                    </button>
                                  </div>
                                  <div
                                    className='card-footer bg-white border-0'
                                    style={{
                                      borderRadius: '1rem',
                                      paddingTop: '0.01rem',
                                    }}
                                  >
                                    {!user.isRegisterByDomain &&
                                      !user.idCompany && (
                                        <button
                                          style={{
                                            backgroundColor: 'rgb(255,221,0)',
                                            color: 'Black',
                                          }}
                                          type='button'
                                          onClick={() => {
                                            addPackageKtm(doc);
                                            history.push(
                                              '/dashboard/planes/suscription'
                                            );
                                          }}
                                          className='btn btn-primary col-md-8 mb-3 m-auto d-block'
                                        >
                                          Suscribirse
                                        </button>
                                      )}
                                  </div>
                                </div>
                              );
                            }
                          })}
                      </>
                    )}
                  </div>
                </div>
                {/* fin tab plan personal */}
                {/* tab plan familiar */}
                <div
                  className='tab-pane pt-5 fade show'
                  id='pills-planfamiliar'
                  role='tabpanel'
                  aria-labelledby='pills-familiar-tab'
                >
                  <div className='grid-layout justify-content-center mb-3'>
                    {plans.length === 0 ? (
                      <div className='p-3'>
                        <h5 className='text-center text-black-50'>
                          No hay planes disponibles.
                        </h5>
                      </div>
                    ) : (
                      <>
                        {plans
                          .sort((a, b) => a.price - b.price)
                          .map((doc) => {
                            if (
                              doc.type === 'medical' &&
                              doc.coverage === 'familiar'
                            ) {
                              return (
                                <div
                                  className='card border-0 shadow-sm animate__animated animate__fadeIn'
                                  style={{ borderRadius: '1rem' }}
                                  key={doc._id}
                                >
                                  <div className='card-header-title text-center'>
                                    <span
                                      className='h5 w-70 mx-auto px-4 pb-1 pt-1 bg-yellow text-primary shadow-sm'
                                      style={{
                                        borderBottomRightRadius: '0.8rem',
                                        borderBottomLeftRadius: '0.8rem',
                                      }}
                                    >
                                      {doc.name}
                                    </span>
                                    <h2 className='text-white font-weight-bold mt-3 mb-3'>
                                      <b style={{ fontSize: '17px' }}>L.</b>
                                      {doc.price
                                        .toFixed(2)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    </h2>
                                  </div>
                                  <div
                                    className='card-body border-0'
                                    style={{ marginTop: '110px' }}
                                  >
                                    <ul className='list-group list-group-flush'>
                                      {doc.products.map((product) => (
                                        <li
                                          className='list-group-item d-flex p-1'
                                          style={{
                                            fontSize: '15px',
                                            color: '#797979',
                                          }}
                                          key={product._id}
                                        >
                                          <img
                                            src={require('../../assets/img/vectores/comprobado2.svg')}
                                            alt='check'
                                            width='20'
                                            className='img-fluid mr-2'
                                          />{' '}
                                          {product}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                  <div
                                    className='card-footer bg-white border-0'
                                    style={{
                                      borderRadius: '1rem',
                                      paddingBottom: '0.1rem',
                                      marginBottom: '0.1rem',
                                    }}
                                  >
                                    <button
                                      type='button'
                                      onClick={() => {
                                        addPackageKtm(doc);
                                        history.push(
                                          '/dashboard/planes/invoice'
                                        );
                                      }}
                                      className='btn btn-primary col-md-8 mb-3 m-auto d-block'
                                    >
                                      Comprar Ahora
                                    </button>
                                  </div>
                                  <div
                                    className='card-footer bg-white border-0'
                                    style={{
                                      borderRadius: '1rem',
                                      paddingTop: '0.01rem',
                                    }}
                                  >
                                    {!user.isRegisterByDomain &&
                                      !user.idCompany && (
                                        <button
                                          style={{
                                            backgroundColor: 'rgb(255,221,0)',
                                            color: 'Black',
                                          }}
                                          type='button'
                                          onClick={() => {
                                            addPackageKtm(doc);
                                            history.push(
                                              '/dashboard/planes/suscription'
                                            );
                                          }}
                                          className='btn btn-primary col-md-8 mb-3 m-auto d-block'
                                        >
                                          Suscribirse
                                        </button>
                                      )}
                                  </div>
                                </div>
                              );
                            }
                          })}
                      </>
                    )}
                  </div>
                </div>
                {/* fin tab plan familiar */}
                {/* tab plan grupal */}
                <div
                  className='tab-pane pt-5 fade show'
                  id='pills-plangrupal'
                  role='tabpanel'
                  aria-labelledby='pills-plangrupal-tab'
                >
                  <div className='grid-layout justify-content-center mb-3'>
                    {plans.length === 0 ? (
                      <div className='p-3'>
                        <h5 className='text-center text-black-50'>
                          No hay planes disponibles.
                        </h5>
                      </div>
                    ) : (
                      <>
                        {plans
                          .sort((a, b) => a.price - b.price)
                          .map((doc) => {
                            if (
                              doc.type === 'medical' &&
                              doc.coverage === 'group'
                            ) {
                              return (
                                <div
                                  className='card border-0 shadow-sm animate__animated animate__fadeIn'
                                  style={{ borderRadius: '1rem' }}
                                  key={doc._id}
                                >
                                  <div className='card-header-title text-center'>
                                    <span
                                      className='h5 w-70 mx-auto px-4 pb-1 pt-1 bg-yellow text-primary shadow-sm'
                                      style={{
                                        borderBottomRightRadius: '0.8rem',
                                        borderBottomLeftRadius: '0.8rem',
                                      }}
                                    >
                                      {doc.name}
                                    </span>
                                    <h2 className='text-white font-weight-bold mt-3 mb-3'>
                                      <b style={{ fontSize: '17px' }}>L.</b>
                                      {doc.price
                                        .toFixed(2)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    </h2>
                                  </div>
                                  <div
                                    className='card-body border-0'
                                    style={{ marginTop: '110px' }}
                                  >
                                    <ul className='list-group list-group-flush'>
                                      {doc.products.map((product) => (
                                        <li
                                          className='list-group-item d-flex p-1'
                                          style={{
                                            fontSize: '15px',
                                            color: '#797979',
                                          }}
                                          key={product._id}
                                        >
                                          <img
                                            src={require('../../assets/img/vectores/comprobado2.svg')}
                                            alt='check'
                                            width='20'
                                            className='img-fluid mr-2'
                                          />{' '}
                                          {product}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                  <div
                                    className='card-footer bg-white border-0'
                                    style={{
                                      borderRadius: '1rem',
                                      paddingBottom: '0.1rem',
                                      marginBottom: '0.1rem',
                                    }}
                                  >
                                    <button
                                      type='button'
                                      onClick={() => {
                                        addPackageKtm(doc);
                                        history.push(
                                          '/dashboard/planes/invoice'
                                        );
                                      }}
                                      className='btn btn-primary col-md-8 mb-3 m-auto d-block'
                                    >
                                      Comprar Ahora
                                    </button>
                                  </div>
                                  <div
                                    className='card-footer bg-white border-0'
                                    style={{
                                      borderRadius: '1rem',
                                      paddingTop: '0.01rem',
                                    }}
                                  >
                                    {!user.isRegisterByDomain &&
                                      !user.idCompany && (
                                        <button
                                          style={{
                                            backgroundColor: 'rgb(255,221,0)',
                                            color: 'Black',
                                          }}
                                          type='button'
                                          onClick={() => {
                                            addPackageKtm(doc);
                                            history.push(
                                              '/dashboard/planes/suscription'
                                            );
                                          }}
                                          className='btn btn-primary col-md-8 mb-3 m-auto d-block'
                                        >
                                          Suscribirse
                                        </button>
                                      )}
                                  </div>
                                </div>
                              );
                            }
                          })}
                      </>
                    )}
                  </div>
                </div>
                {/* fin tab plan grupal */}
                {/* tab de plan de telemedicina */}
                <div
                  className='tab-pane fade'
                  id='pills-telemedicina'
                  role='tabpanel'
                  aria-labelledby='pills-telemedicina-tab'
                >
                  <div className='d-none d-sm-block'>
                    <h2 className='text-primary center pb-5'>
                      Elige la opción que mejor te convenga de{' '}
                      <b className='text-secondarymdv'>
                        Orientación Médica Virtual
                      </b>
                    </h2>
                  </div>
                  <div className='d-block d-sm-none'>
                    <h5 className='text-primary center mb-4 font-weight-light'>
                      Elige la opción que mejor te convenga de{' '}
                      <b>Orientación Médica Virtual</b>
                    </h5>
                  </div>
                  <div className='grid-layout justify-content-center mb-3'>
                    {plans
                      .sort((a, b) => a.price - b.price)
                      .map((item) => {
                        if (item.type === 'sessions_telemedicine') {
                          return (
                            <div key={item._id}>
                              <div
                                className='card border-0 shadow-sm animate__animated animate__fadeIn'
                                style={{ borderRadius: '1rem' }}
                              >
                                <div
                                  className='text-center bg-primarymdv'
                                  style={{ borderRadius: '1rem' }}
                                >
                                  <span
                                    className='h5 w-70 mx-auto px-4 pb-1 pt-1 bg-yellow text-primary shadow-sm'
                                    style={{
                                      borderBottomRightRadius: '0.8rem',
                                      borderBottomLeftRadius: '0.8rem',
                                    }}
                                  >
                                    {item.name}
                                  </span>
                                  <h2 className='text-white font-weight-bold mt-3 mb-3'>
                                    <b style={{ fontSize: '17px' }}>L.</b>
                                    {item.price}
                                  </h2>
                                  <button
                                    type='button'
                                    className='btn btn-light rounded-pill text-primary font-weight-bold col-10 col-md-7 mb-4'
                                    onClick={() => {
                                      addTelemedicinePlan(item);
                                      history.push(
                                        '/dashboard/telemedicine/invoice'
                                      );
                                    }}
                                  >
                                    Comprar Ahora
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>
                </div>
                {/* tab de planes de telemedicina */}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  prices: state.prices.items,
  token: state.auth.token,
  user: state.auth.user,
});
export default connect(mapStateToProps, {
  addShoppingItem,
  addPackageKtm,
  addTelemedicinePlan,
})(CheckPayment);

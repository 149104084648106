/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import {
  setTruePresentialComplete,
  addPresentialVitals,
} from '../../../redux/actions/presential.action';
import createAlert from '../../../redux/actions/alert.action';

const AddVitalSigns = ({
  setTruePresentialComplete,
  addPresentialVitals,
  vitals,
  vitalsData,
  user,
}) => {
  const handleSubmitComplete = () => {
    addPresentialVitals(formData);
  };

  const handleSubmitCompleteSave = () => {
    if (
      temperature !== '' &&
      finalPressure !== '' &&
      initialPressure !== '' &&
      breathingFrequency !== '' &&
      heartRate !== '' &&
      saturation !== '' &&
      weight !== '' &&
      height !== '' &&
      imc !== ''
    ) {
      addPresentialVitals(formData);
    } else {
      createAlert('Error', 'Todos los campos son requeridos', 'error');
    }
  };

  const [formData, setFormData] = useState({
    temperature: vitalsData ? vitalsData.temperature : '',
    initialPressure: vitalsData ? vitalsData.pressure.initial : '',
    finalPressure: vitalsData ? vitalsData.pressure.final : '',
    breathingFrequency: vitalsData ? vitalsData.breathingFrequency : '',
    heartRate: vitalsData ? vitalsData.heartRate : '',
    saturation: vitalsData ? vitalsData.saturation : '',
    weight: vitalsData ? vitalsData.weight : '',
    height: vitalsData ? vitalsData.height : '',
    imc: vitalsData ? vitalsData.imc : '',
  });

  useEffect(() => {}, vitals);

  const {
    temperature,
    initialPressure,
    finalPressure,
    breathingFrequency,
    heartRate,
    saturation,
    weight,
    height,
    imc,
  } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleBlur = (e) => {
    if (e.target.name === 'weight') {
      if (height) {
        const dataWeight = parseFloat(e.target.value);
        const dataHeight = parseFloat(height);
        const result = dataWeight / (dataHeight * dataHeight);
        setFormData({ ...formData, imc: result });
      }
    }
    if (e.target.name === 'height') {
      if (weight) {
        const dataWeight = parseFloat(weight);
        const dataHeight = parseFloat(e.target.value);
        const result = dataWeight / (dataHeight * dataHeight);
        setFormData({ ...formData, imc: result });
      }
    }
  };

  const handleKeypress = (e) => {
    const invalidChars = ['-', '+', 'e', 'E'];
    if (invalidChars.indexOf(e.key) !== -1) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <div className='profile-header'>
        <h3 className='text-white text-center pt-4'>Agregar signos vitales</h3>
      </div>
      <Row style={{ paddingTop: 15 }}>
        <Container>
          <form>
            <Row>
              <Col lg={6} md={12}>
                <div className='form-group'>
                  <label
                    style={{
                      width: '100%',
                      textAlign: 'left',
                    }}
                  >
                    Temperatura (C°)
                  </label>

                  <NumberFormat
                    type='text'
                    className='form-control'
                    style={{ margin: 0 }}
                    name='temperature'
                    value={temperature}
                    placeholder='0'
                    onChange={handleChange}
                    onKeyPress={handleKeypress}
                  />
                </div>
                <div className='form-group'>
                  <label
                    style={{
                      width: '100%',
                      textAlign: 'left',
                    }}
                  >
                    Presión Arterial (mmHg)
                  </label>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <NumberFormat
                      style={{ margin: 5, width: 80, padding: 5 }}
                      type='text'
                      className='form-control'
                      name='initialPressure'
                      value={initialPressure}
                      placeholder='0'
                      onChange={handleChange}
                      onKeyPress={handleKeypress}
                    />

                    <NumberFormat
                      style={{ margin: 5, width: 80, padding: 5 }}
                      type='text'
                      className='form-control'
                      name='finalPressure'
                      value={finalPressure}
                      placeholder='0'
                      onChange={handleChange}
                      onKeyPress={handleKeypress}
                    />
                  </div>
                </div>
                <div className='form-group'>
                  <label
                    style={{
                      width: '100%',
                      textAlign: 'left',
                    }}
                  >
                    Frecuencia Respiratoria (rpm)
                  </label>
                  <NumberFormat
                    style={{ margin: 0 }}
                    type='text'
                    className='form-control'
                    name='breathingFrequency'
                    value={breathingFrequency}
                    placeholder='0'
                    onChange={handleChange}
                    onKeyPress={handleKeypress}
                  />
                </div>

                <div className='form-group'>
                  <label
                    style={{
                      width: '100%',
                      textAlign: 'left',
                    }}
                  >
                    Frecuencia Cardíaca (ppm)
                  </label>
                  <NumberFormat
                    style={{ margin: 0 }}
                    type='text'
                    className='form-control'
                    name='heartRate'
                    value={heartRate}
                    placeholder='0'
                    onChange={handleChange}
                    onKeyPress={handleKeypress}
                  />
                </div>
              </Col>
              <Col md={12} lg={6}>
                <div className='form-group'>
                  <label
                    style={{
                      width: '100%',
                      textAlign: 'left',
                    }}
                  >
                    Saturación de Oxígeno (%)
                  </label>
                  <NumberFormat
                    style={{ margin: 0 }}
                    type='text'
                    className='form-control'
                    name='saturation'
                    value={saturation}
                    placeholder='0'
                    onChange={handleChange}
                    onKeyPress={handleKeypress}
                  />
                </div>
                <div className='form-group'>
                  <label
                    style={{
                      width: '100%',
                      textAlign: 'left',
                    }}
                  >
                    Peso (Kg)
                  </label>
                  <NumberFormat
                    style={{ margin: 0 }}
                    type='text'
                    className='form-control'
                    name='weight'
                    onBlur={handleBlur}
                    value={weight}
                    placeholder='0'
                    onChange={handleChange}
                    onKeyPress={handleKeypress}
                  />
                </div>
                <div className='form-group'>
                  <label
                    style={{
                      width: '100%',
                      textAlign: 'left',
                    }}
                  >
                    Estatura (Mts)
                  </label>
                  <NumberFormat
                    style={{ margin: 0 }}
                    type='text'
                    className='form-control'
                    name='height'
                    onBlur={handleBlur}
                    value={height}
                    placeholder='0'
                    onChange={handleChange}
                    onKeyPress={handleKeypress}
                  />
                </div>
                <div className='form-group'>
                  <label
                    style={{
                      width: '100%',
                      textAlign: 'left',
                    }}
                  >
                    Índice masa corporal (IMC)
                  </label>
                  <NumberFormat
                    style={{ margin: 0 }}
                    type='text'
                    className='form-control'
                    name='imc'
                    value={imc}
                    placeholder='0'
                    onChange={handleChange}
                    onKeyPress={handleKeypress}
                  />
                </div>
              </Col>
            </Row>
          </form>
          <div
            className='center'
            style={{ gap: '10px', display: 'flex', flexDirection: 'row' }}
          >
            <button
              type='submit'
              className='btn btn-primary mt-3 mb-3 m-auto col-12 col-md-4'
              onClick={handleSubmitCompleteSave}
            >
              Guardar
            </button>
            {user?.role === 'checker' && (
              <button
                type='submit'
                className='btn btn-primary mt-3 mb-3 m-auto col-12 col-md-4'
                onClick={handleSubmitComplete}
              >
                Saltar
              </button>
            )}
          </div>
        </Container>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  vitals: state.presential.vitalSings,
  vitalsData: state.presential.preClinic.vitalSings,
});
export default connect(mapStateToProps, {
  setTruePresentialComplete,
  addPresentialVitals,
})(AddVitalSigns);

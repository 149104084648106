import React from "react";

const ModalTermSubscription = () => (
    <div
        className="modal fade"
        id="ModalTermSubscription"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="ModalTermConditions"
        aria-hidden="true"
    >
        <div
            className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl"
            role="document"
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h3
                        className="modal-title text-primary m-auto"
                        id="ModalTermSubscription"
                    >
                        TÉRMINOS Y CONDICIONES
                    </h3>
                </div>
                <div className="modal-body text-justify">
                    
                </div>
                <div class="modal-footer p-2">
                    <button
                        type="button"
                        class="btn btn-info mr-3"
                        data-dismiss="modal"
                        style={{ borderRadius: "0.8rem" }}
                    >
                        Cerrar
                    </button>
                </div>
            </div>
        </div>
    </div>
)
export default ModalTermSubscription;
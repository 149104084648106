/* eslint-disable no-shadow */
/* eslint-disable arrow-parens */
import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Button, Dropdown } from 'react-bootstrap';
import ScheduleModal from './ScheduleDetailsModal';
import { getReference } from '../../../redux/actions/references';
import {
  showDetails,
  selectOnline,
  selectPresential,
} from '../../../redux/actions/scheduling.action';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../../stylesheets/scheduling.css';
import DoctorList from './DoctorList';
import {
  openModalScheduleDoctorList,
  openModalReagendar,
  openModalScheduleDetails,
} from '../../../redux/actions/ui.action';
import { resetConsultory } from '../../../redux/actions/consultories.action';
import AddSchedule from '../../../Components/Card/ModalAddconsulting/ModalAddConsulting';
import ModalReagendar from './ModalReagendar';

const localizer = momentLocalizer(moment);

const Scheduling = ({
  schedule,
  showDetails,
  getReference,
  modalDoctorSchedule,
  modalAddSchedule,
  modalReagendar,
  openModalScheduleDetails,
  modalScheduleDetails,
  resetConsultory,
  doctorReference,
}) => {
  const history = useHistory();

  const [events, setEvents] = useState([]);

  useEffect(() => {
    resetConsultory();
    if (schedule.length > 0) {
      const tempSchedule = [];
      schedule.forEach((item) => {
        // preparar los datos
        const initial = new Date(moment(item.date));
        const finish = new Date(moment(item.date));
        initial.setHours(Number(item.hour.substr(0, 2)));
        finish.setHours(Number(item.hour.substr(0, 2)));

        const tempFinishHour = Number(item.hour.substr(3, 4));

        if (tempFinishHour === 0) {
          initial.setMinutes(0);
          finish.setMinutes(20);
        } else if (tempFinishHour === 20) {
          initial.setMinutes(20);
          finish.setMinutes(40);
        } else {
          initial.setMinutes(40);
          finish.setMinutes(0);
          finish.setHours(Number(item.hour.substr(0, 2)) + 1);
        }

        const doc = {
          item: item,
          start: initial,
          end: finish,
          title: `${moment(item.date).format('DD/MM/YYYY')} ${item.hour} ${
            parseInt(item.hour.substr(0, 2), 10) >= 12 ? 'PM' : 'AM'
          }`,
          status: item.status,
        };
        tempSchedule.push(doc);
      });
      setEvents(tempSchedule);
    } else {
      setEvents([]);
    }
  }, [schedule]);

  const handleShowdetails = async (e) => {
    await getReference(e.item.doctor._id);
    showDetails(e.item);
    openModalScheduleDetails();
  };

  // estilos para cada uno de los items del calendario
  const handleEventStyle = (event) => {
    const toDay = new Date();
    const temp = moment(event.item.date, 'YYYY-MM-DD');
    const day = temp.format('D');
    const month = temp.format('M');
    const year = temp.format('YYYY');
    const selectedDate = new Date(
      year,
      parseInt(month, 10) - 1,
      day,
      parseInt(event.item.hour, 10)
    );

    const enabled = {
      backgroundColor: '#0c87e7',
      color: '#fff',
      borderRadius: '1.5rem',
      border: '1px solid #fff',
      fontSize: 12,
    };

    if (selectedDate.getTime() <= toDay.getTime()) {
      enabled.backgroundColor = '#CDCDCD';
    } else if (event.status === 'accept') {
      enabled.backgroundColor = '#5FCE7F';
    } else if (event.status === 'reject') {
      enabled.backgroundColor = '#C60005';
    }

    return {
      className: '',
      style: enabled,
    };
  };

  return (
    <Container className="container-scheduling" fluid>
      {modalDoctorSchedule && <DoctorList />}
      {modalAddSchedule && doctorReference !== null && <AddSchedule />}
      {modalReagendar && <ModalReagendar />}
      {modalScheduleDetails && <ScheduleModal />}
      <div className="row justify-content-between mb-5 mt-5">
        <div className="col-12 col-sm-5 col-md-5">
          {' '}
          {/* Web View */}
          <div className="d-none d-sm-block">
            <h2 className="text-primary">Tu Agenda de Citas </h2>
          </div>
          {/* Fin Web View */}
          {/* Mobile View */}
          <div className="d-block d-sm-none">
            <h5 className="text-primary mb-4 mt-1 font-weight-bold text-center">
              Tu Agenda de Citas
            </h5>
          </div>
          {/* Fin Mobile View */}
        </div>
        <div className="col-12 col-sm-5 col-md-5">
          <Dropdown>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="agendar-cita-boton"
            >
              Agendar cita
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  history.push('/dashboard/consulta-presencial');
                }}
              >
                Presencial
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  history.push('/dashboard/telellamada');
                }}
              >
                Telellamada
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <Row>
        <Col xs={12}>
          <Calendar
            className="bigCalendar-container mb-5"
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 800, width: '100%' }}
            messages={{
              next: 'sig',
              previous: 'ant',
              today: 'Hoy',
              month: 'Mes',
              week: 'Semana',
              day: 'Día',
            }}
            eventPropGetter={(event) => handleEventStyle(event)}
            onSelectEvent={(e) => handleShowdetails(e)}
          />
        </Col>
      </Row>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  schedule: state.scheduling.items,
  details: state.scheduling.details,
  selected: state.scheduling.selectedDate,
  modalDoctorSchedule: state.ui.doctorModalList.open,
  modalAddSchedule: state.ui.schedulingModal.open,
  modalReagendar: state.ui.modalReagendar.open,
  modalScheduleDetails: state.ui.modalScheduleDetails.open,
  doctorReference: state.reference.userRef,
});

export default connect(mapStateToProps, {
  showDetails,
  getReference,
  openModalScheduleDoctorList,
  selectPresential,
  selectOnline,
  openModalReagendar,
  openModalScheduleDetails,
  resetConsultory,
})(Scheduling);

/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable global-require */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-shadow */
/* eslint-disable arrow-parens */
import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';

import {
  Modal,
  Button,
  Accordion,
  Card,
  Container,
  Row,
  Col,
  Media,
} from 'react-bootstrap';

import { FiChevronDown, RiDeleteBin6Line } from 'react-icons/all';

import moment from 'moment';
import {
  deleteMedicine,
  addSelectedItem,
  deleteSelectedItem,
  addSelectedFamiliarItem,
  updateDeleteAllergies,
  openModalFamiliar,
  updateFamiliarAntecedent,
  updateHealth,
} from '../../redux/actions/patient.history.action';

import { updateDeleteVaccinate } from '../../redux/actions/vaccinate.action';
import {
  deleteSurgeries,
  addItemSurgeries,
  deleteItemSurgeries,
} from '../../redux/actions/surgeries.action';
import LifeStyle from './History/LifeStyle';
import Medicines from './History/AddMedicine';
import HealthHistory from './History/HealthHistory';
import Allergies from './History/Allergies';
import Clinical from './History/Clinical';
import Surgeries from './History/Surgeries';
import Exams from './History/Tests';
import Vaccinate from './History/Vaccinate';
import './History/history.css';
import Familiar from './History/Familiar';
import QueryHistory from './History/queryHistory';
import NursingNotes from './History/nursingNotes';
import { validUserPatientId } from '../../api/user';
import { updateContactPatient } from '../../redux/actions/auth.action';
import createAlert from '../../redux/actions/alert.action';
import { Spinner } from 'react-bootstrap';
import {
  clearHistory,
  deleteReference,
  deleteProfileReference,
} from '../../redux/actions/references';
// import clinico from '../../utils/clinical';

const ActivePlan = ({ patientId }) => {
  const dispatch = useDispatch();
  const [activePlan, setActivePlan] = useState(null);
  const [details, setDetails] = useState(null);
  useEffect(() => {
    validUserPatientId({
      patientId,
    })
      .then((res) => {
        setActivePlan(true);
        setDetails(res.plan);
      })
      .catch(() => {
        setActivePlan(false);
      });
  }, [patientId]);

  useEffect(
    () => () => {
      dispatch(clearHistory());
      dispatch(deleteReference());
      dispatch(deleteProfileReference());
    },
    []
  );

  if (activePlan === null) {
    return <></>;
  }

  if (activePlan) {
    return (
      <div className='mt-4'>
        <h5 className='text-primary font-weight-bold'>
          Plan:
          <span className='text-secondarymdv ml-1'>{details?.plan?.name}</span>
          <span
            className='bg-white text-danger p-2 rounded-pill badge float-right'
            style={{ fontSize: '14px' }}
          >
            <b className='text-dark'>{'Vencimiento: '}</b>
            {moment(details?.dueDate).format('LLLL')}
          </span>
        </h5>
        <h6 className='text-muted'>Cobertura: {details?.plan?.coverage}</h6>
        <div className='hr' />
        {/*<ul className="list-unstyled">
          {details?.plan?.products.map((product) => (
            <li
              className="list-group-item d-flex p-1 border-0 bg-light"
              style={{ fontSize: '15px', color: '#797979' }}
            >
              <img
                src={require('../../assets/img/vectores/comprobado2.svg')}
                alt="check"
                width="20"
                className="img-fluid mr-2"
              />
              {product}
            </li>
          ))}
          </ul>*/}
      </div>
    );
  } else {
    return <>No cuenta con plan activo</>;
  }
};

function PatientHistory({
  medicamentos,
  cirugias,
  deleteMedicine,
  addSelectedItem,
  deleteSelectedItem,
  antecedentesSalud,
  vacunas,
  estilo_vida,
  alergias,
  deleteSurgeries,
  addItemSurgeries,
  deleteItemSurgeries,
  userFamiliarAntecedents,
  addSelectedFamiliarItem,
  clinicos,
  patientIMG,
  patientProfile,
  updateDeleteVaccinate,
  selectedVaccinate,
  selectedAllergie,
  updateDeleteAllergies,
  openModalFamiliar,
  updateFamiliarAntecedent,
  selectedFamiliar,
  selectedHealth,
  updateHealth,
  user,
  userRef,
  updateContactPatient,
}) {
  const [history, setHistory] = useState({
    medicines: false,
    health: false,
    familiar: false,
    alergies: false,
    exams: false,
    vaccine: false,
    surgery: false,
    clinical: false,
    lifeStyle: false,
    queryHistory: false,
    nursingNotes: false,
  });
  // const [edit, setEdit] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [loaderButton, setLoaderButton] = useState(false);
  const [otherContact, setOtherContact] = useState('');
  const [medicineDelete, setMedicineDelete] = useState({
    medicineId: '',
    medicineName: '',
  });

  const {
    medicines,
    health,
    familiar,
    alergies,
    exams,
    vaccine,
    surgery,
    clinical,
    lifeStyle,
    queryHistory,
    nursingNotes,
  } = history;

  useEffect(() => {
    setOtherContact(patientProfile?.otherContact);
  }, [patientProfile]);
  const handleChange = (e) => {
    try {
      if (e.target.name === 'otherContact') {
        setOtherContact(e.target.value);
      }
    } catch (error) {}
  };
  const saveOtherContact = (e) => {
    try {
      if (otherContact !== '') {
        e.preventDefault();
        setLoaderButton(true);
        const idPatient = patientProfile._id;
        const res = updateContactPatient(idPatient, otherContact);
        setLoaderButton(false);
      }
    } catch (e) {
      setLoaderButton(false);
    }
  };
  const handldeShowDetails = (type) => {
    if (type === 'medicamentos') {
      setHistory({
        medicines: !medicines,
      });
    } else if (type === 'salud') {
      setHistory({
        health: !health,
      });
    } else if (type === 'familiares') {
      setHistory({
        familiar: !familiar,
      });
    } else if (type === 'alergias') {
      setHistory({
        alergies: !alergies,
      });
    } else if (type === 'examenes') {
      setHistory({
        exams: !exams,
      });
    } else if (type === 'vacunas') {
      setHistory({
        vaccine: !vaccine,
      });
    } else if (type === 'cirugias') {
      setHistory({
        surgery: !surgery,
      });
    } else if (type === 'clinica') {
      setHistory({
        clinical: !clinical,
      });
    } else if (type === 'vida') {
      setHistory({
        lifeStyle: !lifeStyle,
      });
    } else if (type === 'consultas') {
      setHistory({
        queryHistory: !queryHistory,
      });
    } else if (type === 'enfermeria') {
      setHistory({
        nursingNotes: !nursingNotes,
      });
    }
  };

  const { medicineId, medicineName } = medicineDelete;
  const handleDeleteMedicine = (id, name) => {
    setMedicineDelete({
      medicineId: id,
      medicineName: name,
    });
    setConfirm(true);
  };

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const handleSubmit = () => {
    deleteMedicine(medicineId);
    setConfirm(false);
  };

  const handleDeleteSurgeries = (id) => {
    deleteSurgeries(id);
  };
  const handleDeleteOtherVaccinate = (item) => {
    updateDeleteVaccinate(selectedVaccinate._id, item);
  };

  const handleDeleteOtherAllergie = (item) => {
    updateDeleteAllergies(selectedAllergie._id, item);
  };

  const handleDeleteOtherHealth = (item) => {
    const temp = selectedHealth.other.filter((h) => h !== item);
    updateHealth(selectedHealth._id, temp);
  };

  const handleDeleteOtherFamiliar = (item) => {
    const temp = selectedFamiliar.other.filter((f) => f !== item);
    const formData = {
      other: temp,
    };
    updateFamiliarAntecedent(selectedFamiliar._id, formData);
  };

  return (
    <div className='container-fluid mb-5'>
      {/* Web View */}
      {user?.role === 'patient' && (
        <div className='d-none d-sm-block'>
          <h2 className='text-primary mt-5'>Historial Médico </h2>
          <p className='mb-3'>
            Un historial médico completo y preciso, asegura que nuestros
            especialistas en la salud puedan tener la informacion necesaria para
            brindarte una mejor orientacion.
          </p>
        </div>
      )}
      {/* Fin Web View */}
      {/* Mobile View */}
      {user?.role === 'patient' && (
        <div className='d-block d-sm-none text-center'>
          <h5 className='text-primary mt-5 font-weight-bold'>
            Tu Historial Médico
          </h5>
          <small className='mb-3'>
            Un historial médico completo y preciso, asegura que nuestros
            especialistas en la salud puedan tener la informacion necesaria para
            brindarte una mejor orientacion.
          </small>
        </div>
      )}
      {/* Fin Mobile View */}

      {patientProfile != null ? (
        <div className='p-1'>
          <div className='d-flex align-items-center'>
            <img
              className='img-circle-2 img-fluid mr-4 align-self-center'
              src={
                patientIMG != null
                  ? `/api/profile/image/${patientIMG}`
                  : userRef?.gender === 'male'
                  ? require('../../assets/img/avatars/avatar-pat-male.png')
                  : require('../../assets/img/avatars/avatar-pat-female.png')
              }
            />
            <h4 className='font-weight-bold text-primary'>
              {`${userRef?.firstName} ${userRef?.lastName}`}
              <h5 className='mb-3'>
                <span className='badge text-secondary bg-white rounded-pill mr-1 p-1'>
                  <i className='fas fa-globe mr-1 text-secondarymdv' />
                  País:
                  {patientProfile.country}
                </span>
                <span className='badge text-secondary bg-white rounded-pill mr-1 p-1'>
                  <i className='fas fa-id-card mr-1 text-secondarymdv' />
                  Identidad:
                  {userRef?.dni}
                </span>
              </h5>
              <h5>
                <span className='badge text-secondary bg-white rounded-pill mr-1 p-1'>
                  <i className='fa fa-envelope mr-1 text-secondarymdv' />
                  Email:
                  {userRef.email ? userRef.email : ' sin espeficar'}
                </span>
              </h5>
              <h5>
                <span className='badge text-secondary bg-white rounded-pill mr-1 p-1'>
                  <i className='fas fa-vial mr-1 text-secondarymdv' />
                  Tipo Sangre:
                  {patientProfile.blood_type
                    ? patientProfile.blood_type
                    : ' sin espeficar'}
                </span>
                <span className='badge text-secondary bg-white rounded-pill mr-1 p-1'>
                  <i className='fas fa-phone-square-alt mr-1 text-secondarymdv' />
                  Teléfono:
                  {patientProfile.cellphone
                    ? patientProfile.cellphone
                    : ' sin especificar'}
                </span>
              </h5>
              <h5>
                <span className='badge text-secondary bg-white rounded-pill mr-1 p-1'>
                  <i className='fas fa-pager mr-2 text-secondarymdv' />
                  Edad:
                  {patientProfile.bornAt
                    ? ` ${moment().diff(
                        patientProfile.bornAt,
                        'years',
                        false
                      )} años`
                    : ' sin espeficar'}
                </span>
                <span className='badge text-secondary bg-white rounded-pill mr-1 p-1'>
                  <i className='fas fa-birthday-cake text-secondarymdv mr-2' />
                  Fecha nacimiento:
                  {patientProfile.bornAt
                    ? ` ${moment(patientProfile.bornAt).format('DD/MM/YYYY')}`
                    : ' sin especificar'}
                </span>
                <div
                  className='form-group'
                  style={{ marginBottom: '1rem', paddingBottom: '1rem' }}
                >
                  <span className='badge text-secondary bg-white rounded-pill mr-1 p-1'>
                    <i className='fas fa-phone-square-alt mr-1 text-secondarymdv' />
                    Otro contacto:
                  </span>

                  <input
                    type='text'
                    className='form-control left'
                    value={otherContact}
                    onChange={(e) => handleChange(e)}
                    name='otherContact'
                  />
                </div>
                <div style={{ marginTop: '1.5rem' }}>
                  <Button
                    style={{
                      justifyContent: 'flex-end',
                      marginTop: '0.5rem',
                      opacity: '100%',
                    }}
                    type='submit'
                    className='btn btn-primary font-weight-bold btn-block'
                    onClick={saveOtherContact}
                    disabled={loaderButton}
                  >
                    {loaderButton ? (
                      <Spinner
                        style={{
                          width: 20,
                          height: 20,
                          color: '#ffff',
                          fontSize: 8,
                        }}
                        animation='border'
                      />
                    ) : (
                      'Guardar contacto'
                    )}
                  </Button>
                </div>
              </h5>
            </h4>
          </div>
          <div className='hr' />
          <ActivePlan patientId={patientProfile.user} />
        </div>
      ) : (
        ''
      )}
      <div className='row'>
        <div className='col-12 col-md-5 col-sm-12 col-xs-12 gutter mt-3 mb-3'>
          <Container className='historial-container'>
            <Container>
              <Row>
                <Accordion className='acordeon-card'>
                  {/* Historial de consultas */}
                  <Card className='card-styles'>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey='1'
                      className='acordion-toogle'
                      onClick={() => {
                        handldeShowDetails('consultas');
                      }}
                    >
                      <h6 className='text-primary'>
                        Historial de Consultas Médicas
                      </h6>
                      <FiChevronDown className='icon-position' />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='1'>
                      <Card.Body />
                    </Accordion.Collapse>
                  </Card>
                  {/* Notas de enfermeria */}
                  <Card className='card-styles'>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey='2'
                      className='acordion-toogle'
                      onClick={() => {
                        handldeShowDetails('enfermeria');
                      }}
                    >
                      <h6 className='text-primary'> Notas de Enfermería </h6>
                      <FiChevronDown className='icon-position' />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='2'>
                      <Card.Body />
                    </Accordion.Collapse>
                  </Card>

                  {/* Resultados de estudios */}
                  {/* <Card className="card-styles">
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="3"
                      className="acordion-toogle"
                      onClick={() => {
                        handldeShowDetails('examenes');
                      }}
                    >
                      <h6 className="text-primary">Resultados de Estudios</h6>
                      <FiChevronDown className="icon-position" />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>
                        <Row>
                          <Col md="9">
                            <Media.Body
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                              }}
                            >
                              <h6 className="font-weight-light text-primary">
                                Adjuntar examen
                              </h6>
                            </Media.Body>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card> */}

                  {/* antecedentes de salud */}
                  <Card className='card-styles'>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey='4'
                      className='acordion-toogle'
                      onClick={() => {
                        handldeShowDetails('salud');
                      }}
                    >
                      <h6 className='text-primary'>
                        Antecedentes Personales Patológicos
                      </h6>
                      <FiChevronDown className='icon-position' />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='4'>
                      <Card.Body>
                        <Row>
                          {antecedentesSalud.length > 0 ? (
                            antecedentesSalud.map((salud) => (
                              <div
                                style={{
                                  borderBottom: '1px solid #f5f5f6',
                                  width: '100%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  paddingTop: 3,
                                  paddingBottom: 3,
                                }}
                                key={salud._id}
                              >
                                <Col md='9'>
                                  <Media.Body>
                                    <h6 className='font-weight-light text-primary'>
                                      {salud.name}
                                    </h6>
                                    {salud.other.length !== 0 &&
                                      salud.other.map((item) => (
                                        <Row
                                          key={item}
                                          style={{
                                            paddingTop: 7,
                                            paddingBottom: 7,
                                            display: 'flex',
                                          }}
                                        >
                                          <Col xs={10}>
                                            <div>{item}</div>
                                          </Col>
                                          <Col xs={2}>
                                            <button
                                              type='button'
                                              className='buttons-others'
                                              onClick={() => {
                                                handleDeleteOtherHealth(item);
                                              }}
                                            >
                                              <RiDeleteBin6Line size={12} />
                                            </button>
                                          </Col>
                                        </Row>
                                      ))}
                                  </Media.Body>
                                </Col>
                              </div>
                            ))
                          ) : (
                            <div>
                              No posee historial de antecedetes de salud.
                            </div>
                          )}
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  {/* medicamentos */}
                  <Card className='card-styles'>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey='5'
                      className='acordion-toogle'
                      onClick={() => {
                        handldeShowDetails('medicamentos');
                      }}
                    >
                      <h6 className='text-primary'> Medicamentos</h6>
                      <FiChevronDown className='icon-position' />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='5'>
                      <Card.Body>
                        <Row>
                          {medicamentos.length > 0 ? (
                            medicamentos.map((medicamento) => (
                              <div
                                style={{
                                  borderBottom: '1px solid #f5f5f6',
                                  width: '100%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  paddingTop: 3,
                                  paddingBottom: 3,
                                }}
                                key={medicamento._id}
                              >
                                <Col md='9'>
                                  <Media.Body>
                                    <h6 className='font-weight-light text-primary'>
                                      {medicamento.name}
                                    </h6>
                                    <span style={{ color: '#809FFF' }}>
                                      {medicamento.dose}
                                    </span>
                                    {medicamento.dose && <br />}
                                    <span>{medicamento.frequency}</span>
                                  </Media.Body>
                                </Col>
                                <Col md='3'>
                                  <div className='row buttons-container'>
                                    <button
                                      className='button-medicines'
                                      type='button'
                                      onClick={() =>
                                        addSelectedItem(medicamento)
                                      }
                                    >
                                      <i className='fas fa-pencil-alt btn-group bluei' />
                                    </button>
                                    <button
                                      type='button'
                                      className='button-medicines'
                                      onClick={() => {
                                        handleDeleteMedicine(
                                          medicamento._id,
                                          medicamento.name
                                        );
                                        deleteSelectedItem();
                                      }}
                                    >
                                      <i className='fas fa-times btn-group bluei2' />
                                    </button>
                                  </div>
                                </Col>
                              </div>
                            ))
                          ) : (
                            <div>No posee historial de medicamentos.</div>
                          )}
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Modal
                    show={confirm}
                    onHide={handleConfirmClose}
                    centered
                    size='sm'
                  >
                    <Modal.Header className='border-bottom-0 mx-auto'>
                      <h5 className='text-center text-primary pt-3 pb-0 font-weight-bold'>
                        ¿Quiere eliminar el medicamento?
                      </h5>
                    </Modal.Header>
                    <Modal.Body className='pt-0 pb-0'>
                      <h6 className='text-center text-black-50 pt-0 pb-0'>
                        {medicineName}
                      </h6>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className='d-flex'>
                        <Button
                          type='button'
                          variant='link'
                          className='btn btn-link btn-sm mr-3 text-secondarymdv font-weight-bold'
                          onClick={handleConfirmClose}
                        >
                          Cancelar
                        </Button>
                        <Button
                          variant='primary'
                          className='btn btn-primary btn-sm'
                          type='button'
                          onClick={handleSubmit}
                        >
                          Confirmar
                        </Button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                  {/* antecedentes familiares */}
                  <Card className='card-styles'>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey='6'
                      className='acordion-toogle'
                      onClick={() => {
                        handldeShowDetails('familiares');
                      }}
                    >
                      <h6 className='text-primary'>
                        Antecedentes Familiares Patológicos
                      </h6>
                      <FiChevronDown className='icon-position' />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='6'>
                      <Card.Body>
                        <Row>
                          {userFamiliarAntecedents.length > 0 ? (
                            userFamiliarAntecedents.map((familiar) => (
                              <div
                                style={{
                                  borderBottom: '1px solid #f5f5f6',
                                  width: '100%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  paddingTop: 3,
                                  paddingBottom: 3,
                                }}
                                key={familiar._id}
                              >
                                <Col md='9'>
                                  <Media.Body>
                                    <h6 className='font-weight-light text-primary'>
                                      {familiar.name}
                                    </h6>
                                    {familiar.relation !== null &&
                                      familiar.relation.map((rel, index) => (
                                        <span
                                          style={{ color: '#809FFF' }}
                                          key={index}
                                        >
                                          {rel}
                                          {index ===
                                          familiar.relation.length - 1
                                            ? null
                                            : ', '}
                                        </span>
                                      ))}
                                    <div>
                                      {familiar.other.length !== 0 &&
                                        familiar.other.map((item) => (
                                          <Row
                                            key={item}
                                            style={{
                                              paddingTop: 7,
                                              paddingBottom: 7,
                                              display: 'flex',
                                            }}
                                          >
                                            <Col xs={10}>
                                              <div>{item}</div>
                                            </Col>
                                            <Col xs={2}>
                                              <button
                                                type='button'
                                                className='buttons-others'
                                                onClick={() => {
                                                  handleDeleteOtherFamiliar(
                                                    item
                                                  );
                                                }}
                                              >
                                                <RiDeleteBin6Line size={12} />
                                              </button>
                                            </Col>
                                          </Row>
                                        ))}
                                    </div>
                                  </Media.Body>
                                </Col>
                                <Col md='3'>
                                  <div className='row buttons-container'>
                                    <button
                                      className='button-medicines'
                                      type='button'
                                      onClick={() => {
                                        addSelectedFamiliarItem(familiar);
                                        openModalFamiliar();
                                      }}
                                    >
                                      <i className='fas fa-pencil-alt btn-group bluei' />
                                    </button>
                                  </div>
                                </Col>
                              </div>
                            ))
                          ) : (
                            <div>
                              No posee historial de antecedentes familiares.
                            </div>
                          )}
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  {/* Antecedentes quirúrgicos */}
                  <Card className='card-styles'>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey='7'
                      className='acordion-toogle'
                      onClick={() => {
                        handldeShowDetails('cirugias');
                      }}
                    >
                      <h6 className='text-primary'>Antecedentes Quirúrgicos</h6>
                      <FiChevronDown className='icon-position' />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='7'>
                      <Card.Body>
                        <Row>
                          {cirugias.length > 0 ? (
                            cirugias.map((cirugia) => (
                              <div
                                style={{
                                  borderBottom: '1px solid #f5f5f6',
                                  width: '100%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  paddingTop: 3,
                                  paddingBottom: 3,
                                }}
                                key={cirugia._id}
                              >
                                <Col md='9'>
                                  <Media.Body>
                                    <h6 className='font-weight-light text-primary'>
                                      {cirugia.name}
                                    </h6>
                                    <span>{cirugia.year}</span>
                                  </Media.Body>
                                </Col>
                                <Col md='3'>
                                  <div className='row buttons-container'>
                                    <button
                                      className='button-medicines'
                                      type='button'
                                      onClick={() => addItemSurgeries(cirugia)}
                                    >
                                      <i className='fas fa-pencil-alt btn-group bluei' />
                                    </button>
                                    <button
                                      type='button'
                                      className='button-medicines'
                                      onClick={() => {
                                        handleDeleteSurgeries(cirugia._id);
                                        deleteItemSurgeries();
                                      }}
                                    >
                                      <i className='fas fa-times btn-group bluei2' />
                                    </button>
                                  </div>
                                </Col>
                              </div>
                            ))
                          ) : (
                            <div>No posee antecedentes quirúrgicos.</div>
                          )}
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  {/* Historial clinico conductual */}
                  <Card className='card-styles'>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey='8'
                      className='acordion-toogle'
                      onClick={() => {
                        handldeShowDetails('clinica');
                      }}
                    >
                      <h6 className='text-primary'>
                        Historial Clínico Conductual
                      </h6>
                      <FiChevronDown className='icon-position' />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='8'>
                      <Card.Body>
                        <Row>
                          {clinicos.length > 0 ? (
                            clinicos.map((clinico) => (
                              <div
                                style={{
                                  borderBottom: '1px solid #f5f5f6',
                                  width: '100%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  paddingTop: 3,
                                  paddingBottom: 3,
                                }}
                                key={clinico._id}
                              >
                                <Col md='9'>
                                  <Media.Body>
                                    <h6 className='font-weight-light text-primary'>
                                      {clinico.name}
                                    </h6>
                                  </Media.Body>
                                </Col>
                              </div>
                            ))
                          ) : (
                            <p className='text-black-50 p-0 m-0'>
                              No se agrego ningun historial clínico.
                            </p>
                          )}
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  {/* estilo de vida */}
                  <Card className='card-styles'>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey='9'
                      className='acordion-toogle'
                      onClick={() => {
                        handldeShowDetails('vida');
                      }}
                    >
                      <h6 className='text-primary'>Estilo de vida</h6>
                      <FiChevronDown className='icon-position' />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='9'>
                      <Card.Body>
                        <Row>
                          {estilo_vida.length > 0 ? (
                            estilo_vida.map((vida) => (
                              <div
                                style={{
                                  borderBottom: '1px solid #f5f5f6',
                                  width: '100%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  paddingTop: 3,
                                  paddingBottom: 3,
                                }}
                                key={vida._id}
                              >
                                <Col md='9'>
                                  <Media.Body>
                                    <h6 className='font-weight-light text-primary'>
                                      {vida.name}
                                    </h6>
                                  </Media.Body>
                                </Col>
                              </div>
                            ))
                          ) : (
                            <div>No se ha agregado ningun dato.</div>
                          )}
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  {/* alergia */}
                  <Card className='card-styles'>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey='10'
                      className='acordion-toogle'
                      onClick={() => {
                        handldeShowDetails('alergias');
                      }}
                    >
                      <h6 className='text-primary'> Alergias</h6>
                      <FiChevronDown className='icon-position' />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='10'>
                      <Card.Body>
                        <Row>
                          {alergias.length > 0 ? (
                            alergias.map((alergia) => (
                              <div
                                style={{
                                  borderBottom: '1px solid #f5f5f6',
                                  width: '100%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  paddingTop: 3,
                                  paddingBottom: 3,
                                }}
                                key={alergia._id}
                              >
                                <Col md='9'>
                                  <Media.Body>
                                    <h6 className='font-weight-light text-primary'>
                                      {alergia.name}
                                    </h6>
                                  </Media.Body>
                                  <div>
                                    {alergia.other.length !== 0 &&
                                      alergia.other.map((item) => (
                                        <Row
                                          key={item}
                                          style={{
                                            paddingTop: 7,
                                            paddingBottom: 7,
                                            display: 'flex',
                                          }}
                                        >
                                          <Col xs={10}>
                                            <div>{item}</div>
                                          </Col>
                                          <Col xs={2}>
                                            <button
                                              type='button'
                                              className='buttons-others'
                                              onClick={() => {
                                                handleDeleteOtherAllergie(item);
                                              }}
                                            >
                                              <RiDeleteBin6Line size={12} />
                                            </button>
                                          </Col>
                                        </Row>
                                      ))}
                                  </div>
                                </Col>
                              </div>
                            ))
                          ) : (
                            <div>
                              No posee historial de antecedetes de alergia.
                            </div>
                          )}
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  {/* vacunas */}
                  <Card className='card-styles'>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey='11'
                      className='acordion-toogle'
                      onClick={() => {
                        handldeShowDetails('vacunas');
                      }}
                    >
                      <h6 className='text-primary'> Vacunas</h6>
                      <FiChevronDown className='icon-position' />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='11'>
                      <Card.Body>
                        <Row>
                          {vacunas.length > 0 ? (
                            vacunas.map((vacuna) => (
                              <div
                                style={{
                                  borderBottom: '1px solid #f5f5f6',
                                  width: '100%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  paddingTop: 3,
                                  paddingBottom: 3,
                                }}
                                key={vacuna._id}
                              >
                                <Col md='9'>
                                  <Media.Body>
                                    <h6 className='font-weight-light text-primary'>
                                      {vacuna.name}
                                    </h6>
                                  </Media.Body>
                                  <div>
                                    {vacuna.other.length !== 0 &&
                                      vacuna.other.map((item) => (
                                        <Row
                                          key={item}
                                          style={{
                                            paddingTop: 7,
                                            paddingBottom: 7,
                                            display: 'flex',
                                          }}
                                        >
                                          <Col xs={10}>
                                            <div>{item}</div>
                                          </Col>
                                          <Col xs={2}>
                                            <button
                                              type='button'
                                              className='buttons-others'
                                              onClick={() =>
                                                handleDeleteOtherVaccinate(item)
                                              }
                                            >
                                              <RiDeleteBin6Line size={12} />
                                            </button>
                                          </Col>
                                        </Row>
                                      ))}
                                  </div>
                                </Col>
                              </div>
                            ))
                          ) : (
                            <div>No se agrego ninguna vacuna.</div>
                          )}
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Row>
            </Container>
          </Container>
        </div>
        <div className='col-md-7 col-sm-12 col-xs-12 gutter'>
          {/*  seccion de agregar lla interactividad del historial medico */}
          {medicines && <Medicines />}
          {health && <HealthHistory />}
          {vaccine && <Vaccinate />}
          {lifeStyle && <LifeStyle />}
          {alergies && <Allergies />}
          {exams && <Exams />}
          {surgery && <Surgeries />}
          {clinical && <Clinical />}
          {familiar && <Familiar />}
          {queryHistory && <QueryHistory />}
          {nursingNotes && <NursingNotes />}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  medicamentos: state.history.medicines.items,
  user: state.auth.user,
  antecedentesSalud: state.history.health.items,
  vacunas: state.history.vaccinate.items,
  estilo_vida: state.history.lifeStyle.items,
  alergias: state.history.allergies.items,
  cirugias: state.history.surgeries.items,
  userFamiliarAntecedents: state.history.familiar.items,
  clinicos: state.history.clinical.items,
  patientIMG: state.profile.searchPatientIMG,
  patientProfile: state.reference.profileRef,
  selectedVaccinate: state.history.vaccinate.selected,
  selectedAllergie: state.history.allergies.selected,
  defaultVaccinate: state.history.vaccinate.def,
  selectedFamiliar: state.history.familiar.selectedItem,
  selectedHealth: state.history.health.selected,
  userRef: state.reference.userRef,
});

export default connect(mapStateToProps, {
  deleteMedicine,
  addSelectedItem,
  deleteSelectedItem,
  deleteSurgeries,
  addItemSurgeries,
  deleteItemSurgeries,
  addSelectedFamiliarItem,
  updateDeleteVaccinate,
  updateDeleteAllergies,
  openModalFamiliar,
  updateFamiliarAntecedent,
  updateHealth,
  updateContactPatient,
})(PatientHistory);

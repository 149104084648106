/* eslint-disable arrow-parens */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import "../../../stylesheets/scheduling.css";
import { Modal, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { CgClose } from "react-icons/all";
import { getListOnlineDoctor } from "../../../api/doctor";
import Spinner from "../../Spinner/Spinner";
import CardContactDoctor from "../../Card/CardContactDoctor";
import { observerDoctorOnline } from "../../../websokect/patientWebSocket";
import {
  openModalScheduleDoctorList,
  closeModalScheduleDoctorList,
} from "../../../redux/actions/ui.action";
import {
  resetOnline,
  resetPresential,
} from "../../../redux/actions/scheduling.action";
import { resetConsultory } from "../../../redux/actions/consultories.action";

// eslint-disable-next-line arrow-body-style
const DoctorList = ({
  setDoctorModal,
  doctorModal,
  user,
  token,
  modalDoctorSchedule,
  openModalScheduleDoctorList,
  closeModalScheduleDoctorList,
  resetOnline,
  resetPresential,
  schedulingModal,
  resetConsultory,
}) => {
  const [loading, setLoading] = useState(true);
  const [doctorsArray, setDoctorsArray] = useState([]);
  const [doctor, setDoctor] = useState(undefined);
  const [textSearch, setTextSearch] = useState("");

  useEffect(() => {
    resetConsultory();
    const page = 0;
    // obteniendo listado de medicos
    getListOnlineDoctor({ token, page })
      .then((res) => {
        const { doctors } = res;
        setDoctorsArray(...doctorsArray, doctors);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });

    observerDoctorOnline(() => {
      getListOnlineDoctor({
        token,
        page,
      })
        .then((res) => {
          const { doctors } = res;
          setDoctorsArray(...doctorsArray, doctors);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }, []);

  const handleOnHide = () => {
    closeModalScheduleDoctorList();
    if (!schedulingModal) {
      resetOnline();
      resetPresential();
    }
  };

  const handleDataDoctor = (doc) => {
    setDoctor(doc);
  };
  const handleSearchDoctor = (event) => {
    setTextSearch(event.target.value);
  };

  return (
    <Modal
      show={modalDoctorSchedule}
      onHide={handleOnHide}
      backdropClassName="backdrop-style"
      centered
      size="lg"
      scrollable
    >
      <Modal.Header>
        <Modal.Title className="title-modal-doctor-scheduling">
          Selecciona un doctor
        </Modal.Title>
        <CgClose
          size={24}
          onClick={handleOnHide}
          style={{ marginRight: 5, marginTop: 3, cursor: "pointer" }}
        />
      </Modal.Header>
      <Modal.Body>
        <Row>
          {/* sección de buscador de pacientes */}
          <Col xs={9}>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control search-doctor-scheduling"
                id="Search"
                onChange={handleSearchDoctor}
                value={textSearch}
                name="searchdoctor"
                placeholder="&nbsp;Buscar Médico"
                aria-label="&nbsp;BUSCAR MÉDICO"
                aria-describedby="button-addon2"
              />
              <div className="input-group-append">
                <button
                  className="btn btn-light border border-right-rounded"
                  type="button"
                >
                  <i className="fas fa-search text-secondarymdv" />
                </button>
              </div>
            </div>
          </Col>

          {/* seccion listar doctorres disponibles */}
          <Col xs={3}>
            <div className="dropdown">
              <button
                className="btn btn-primary btn-block dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span>Filtrar:</span> Disponible
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <a className="dropdown-item" href="#">
                  No Disponible
                </a>
                <a className="dropdown-item" href="#">
                  En Espera
                </a>
                <a className="dropdown-item" href="#">
                  De Vacaciones
                </a>
              </div>
            </div>
          </Col>
        </Row>
        {/* seccion de listar doctores */}
        <Row>
          <Col xs={12}>
            {/* seccion de listar medicos */}
            {loading && <Spinner />}
            {!loading && (
              <>
                {doctorsArray.length === 0 ? (
                  <p>No hay doctores en línea</p>
                ) : (
                  <>
                    {doctorsArray.map((doctor) => {
                      if (textSearch) {
                        const stringRegex = textSearch.split(/ /).join("|");
                        const regexSearch = new RegExp(stringRegex, "i");
                        if (
                          regexSearch.test(doctor.firstName) ||
                          regexSearch.test(doctor.lastName)
                        ) {
                          return (
                            <CardContactDoctor
                              key={doctor._id}
                              handleDataDoctor={() => handleDataDoctor(doctor)}
                              image={
                                doctor.image
                                  ? `/api/profile/image/${doctor.image}`
                                  : undefined
                              }
                              name={
                                doctor.gender === "male"
                                  ? `Dr. ${doctor.firstName} ${doctor.lastName}`
                                  : `Dra. ${doctor.firstName} ${doctor.lastName}`
                              }
                              specialty="Pediatra"
                              appointment={doctor.profile.appointment}
                              status={doctor.profile.status}
                              // handleVideoCall={() =>
                              //   this.handleVideconference(doctor)
                              // }
                              // handleChat={() => this.handleChatDoctor(doctor)}
                              rating={doctor.profile.rating}
                            />
                          );
                        } else {
                          return undefined;
                        }
                      } else {
                        return (
                          <CardContactDoctor
                            key={doctor._id}
                            handleDataDoctor={() => handleDataDoctor(doctor)}
                            image={
                              doctor.image
                                ? `/api/profile/image/${doctor.image}`
                                : undefined
                            }
                            name={
                              doctor.gender === "male"
                                ? `Dr. ${doctor.firstName} ${doctor.lastName}`
                                : `Dra. ${doctor.firstName} ${doctor.lastName}`
                            }
                            specialty="Pediatra"
                            appointment={doctor.profile.appointment}
                            status={doctor.profile.status}
                            // handleVideoCall={() =>
                            //   this.handleVideconference(doctor)
                            // }
                            // handleChat={() => this.handleChatDoctor(doctor)}
                            doctorId={doctor._id}
                            rating={doctor.profile.rating}
                          />
                        );
                      }
                    })}
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

const mapStatetoProps = (state) => ({
  token: state.auth.token,
  user: state.auth.user,
  modalDoctorSchedule: state.ui.doctorModalList.open,
  schedulingModal: state.ui.schedulingModal.open,
});

export default connect(mapStatetoProps, {
  openModalScheduleDoctorList,
  closeModalScheduleDoctorList,
  resetOnline,
  resetPresential,
  resetConsultory,
})(DoctorList);

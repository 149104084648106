/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import datosClinicos from "../../../utils/clinical";
import {
  deleteclinical,
  addclinical,
} from "../../../redux/actions/clinical.action";

const Clinical = ({
  clinicos,
  deleteclinical,
  addclinical,
  patientID,
  userRef,
}) => {
  const [clinical, setclinical] = useState(datosClinicos);
  const [psicologico, setpsicologico] = useState();
  const [preferencia, setpreferencia] = useState();
  const question1 =
    "Tienes algún familiar que haya sido hospitalizado por problemas psicológicos";
  const question2 = "Cuál es tu preferencia para un consejero";
  useEffect(() => {
    try {
      clinicos.forEach((element) => {
        if (element.name.split(":")[1]) {
          if (element.name.split(":")[0] == question1) {
            setpsicologico(element.name.split(":")[1].toString());
          } else {
            setpreferencia(element.name.split(":")[1]);
          }
        }
      });

      clinicos.forEach((actual) => {
        const index = clinical.findIndex(
          (clinico) => clinico.name === actual.name
        );
        const tempClinico = [...clinical];
        tempClinico[index].check = true;
        setclinical(tempClinico);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleChange = (e) => {
    const index = clinical.findIndex(
      (clinico) => clinico.name === e.target.name
    );
    if (index !== -1) {
      const tempArray = [...clinical];
      tempArray[index].check = !tempArray[index].check;
      setclinical(tempArray);
    }
  };

  const handleChangeRadio = (e) => {
    const { name, value } = e.target;
    if (name === "psicologico") {
      setpsicologico(value);
      const newname = `${question1}:${value}`;
      const validar = clinicos.find(
        (data) => data.name.split(":")[0] == question1
      );
      if (validar) {
        deleteclinical(validar._id);
        addclinical(newname, patientID);
      } else {
        addclinical(newname, patientID);
      }
    } else {
      setpreferencia(value);
      const newname = `${question2}:${value}`;
      const validar = clinicos.find(
        (data) => data.name.split(":")[0] == question2
      );
      if (validar) {
        deleteclinical(validar._id);
        if (userRef) {
          addclinical(newname, userRef._id);
        } else {
          addclinical(newname, patientID);
        }
      } else if (userRef) {
        addclinical(newname, userRef._id);
      } else {
        addclinical(newname, patientID);
      }
    }
  };

  const handleUpdate = (e) => {
    const indexState = clinical.findIndex(
      (clinico) => clinico.name === e.target.name
    );
    if (clinical[indexState].check === true) {
      if (userRef) {
        addclinical(e.target.name, userRef._id);
      } else {
        addclinical(e.target.name, patientID);
      }
    } else {
      clinicos.forEach((element) => {
        if (element.name === e.target.name) {
          deleteclinical(element._id);
        }
      });
    }
  };
  return (
    <div>
      <div className="sales mt-3 pr-0 pl-0">
        <div className="media">
          <div className="media-body">
            <div className="panel panel-default">
              <div className="panel-heading">
                <h5 className="text-primary font-weight-bold mt-2 mb-3 text-center">
                  ¿Has sido diagnosticado con algunas de estas condiciones?
                </h5>
              </div>
              <ul className="list-group list-group-flush">
                {clinical.map((clinico) => (
                  <li className="list-group-item" key={clinico.id}>
                    {clinico.name}
                    <div className="material-switch pull-right">
                      <input
                        id={clinico.name}
                        name={clinico.name}
                        type="checkbox"
                        checked={clinico.check}
                        onChange={(e) => {
                          handleChange(e);
                          handleUpdate(e);
                        }}
                      />
                      <label htmlFor={clinico.name} className="label-default" />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="container mt-4 center">
          <h6 className="text-secondarymdv mb-3">¿{question1}?</h6>
          <div className="btn-group btn-group-toggle btn-block mb-3">
            <label
              className="btn border border-primary text-secondarymdv"
              style={{
                borderTopLeftRadius: "2rem",
                borderBottomLeftRadius: "2rem",
              }}
            >
              <input
                type="radio"
                name="psicologico"
                value="Si"
                checked={psicologico === "Si"}
                onChange={(e) => {
                  handleChangeRadio(e);
                }}
              />{" "}
              Si
            </label>
            <label className="btn border border-primary text-secondarymdv">
              <input
                type="radio"
                name="psicologico"
                value="No"
                checked={psicologico === "No"}
                onChange={(e) => {
                  handleChangeRadio(e);
                }}
              />{" "}
              No
            </label>
            <label
              className="btn border border-primary text-secondarymdv"
              style={{
                borderTopRightRadius: "2rem",
                borderBottomRightRadius: "2rem",
              }}
            >
              <input
                type="radio"
                name="psicologico"
                value="No lo se"
                checked={psicologico === "No lo se"}
                onChange={(e) => {
                  handleChangeRadio(e);
                }}
              />{" "}
              No lo sé
            </label>
          </div>
          <h6 className="text-secondarymdv mb-3">¿{question2}?</h6>
          <div className="btn-group btn-group-toggle btn-block mb-3">
            <label
              className="btn border border-primary text-secondarymdv"
              style={{
                borderTopLeftRadius: "2rem",
                borderBottomLeftRadius: "2rem",
              }}
            >
              <input
                type="radio"
                name="preferencia"
                value="Sin Preferencia"
                checked={preferencia === "Sin Preferencia"}
                onChange={(e) => {
                  handleChangeRadio(e);
                }}
              />{" "}
              Sin Preferencia
            </label>
            <label className="btn border border-primary text-secondarymdv">
              <input
                type="radio"
                name="preferencia"
                value="Hombre"
                checked={preferencia === "Hombre"}
                onChange={(e) => {
                  handleChangeRadio(e);
                }}
              />{" "}
              Hombre
            </label>
            <label
              className="btn border border-primary text-secondarymdv"
              style={{
                borderTopRightRadius: "2rem",
                borderBottomRightRadius: "2rem",
              }}
            >
              <input
                type="radio"
                name="preferencia"
                value="Mujer"
                checked={preferencia === "Mujer"}
                onChange={(e) => {
                  handleChangeRadio(e);
                }}
              />{" "}
              Mujer
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  clinicos: state.history.clinical.items,
  patientID: state.profile.searchPatientID,
  userRef: state.reference.userRef,
});

export default connect(mapStateToProps, { addclinical, deleteclinical })(
  Clinical
);

import axios from "axios";

export const url = "/api/dependent/patient";

export const createDependentPatient = ({
  dependentPatient,
  relation,
  token,
  otherName,
}) =>
  new Promise((resolve, reject) => {
    if (dependentPatient && relation && token) {
      axios
        .post(
          `${url}/create`,
          {
            dependentPatient,
            relation,
            otherName,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === "success") {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => reject(error));
    } else if (!dependentPatient) {
      reject({
        status: "error",
        info: "Paciente no esta definido",
      });
    } else if (!relation) {
      reject({
        status: "error",
        info: "Relacion no definida",
      });
    } else {
      reject({
        status: "error",
        info: "Token no definido",
      });
    }
  });

export const updateDependentPatient = ({ relation, _id, token }) =>
  new Promise((resolve, reject) => {
    if (relation && _id && token) {
      axios
        .post(
          `${url}/update`,
          {
            relation,
            _id,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === "success") {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => reject(error));
    } else if (!relation) {
      reject({
        status: "error",
        info: "Relacion no esta definida",
      });
    } else if (!_id) {
      reject({
        status: "error",
        info: "Identificador no esta definido",
      });
    } else {
      reject({
        status: "error",
        info: "Token no esta definido",
      });
    }
  });

export const removeDependentPatient = ({ _id, token }) =>
  new Promise((resolve, reject) => {
    if (_id && token) {
      axios
        .delete(`${url}/remove/${_id}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === "success") {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => reject(error));
    } else if (!_id) {
      resolve({
        status: "error",
        info: "Identificador no esta definido",
      });
    } else {
      reject({
        status: "error",
        info: "Token no definido",
      });
    }
  });

export const getDependentPatient = ({ token }) =>
  new Promise((resolve, reject) => {
    if (token) {
      axios
        .get(`${url}/all`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === "success") {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => reject(error));
    } else {
      reject({
        status: "error",
        info: "Token no definido",
      });
    }
  });

export const getDependentEmployee = async (token, user) => {
  const _id = user;
  try {
    const res = await axios.get(
      "/api/dependent/patient/allEmployeeDependents",
      { 
        body: { _id } 
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log("mi respuesta", res);
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const searchPatient = ({ text, token }) =>
  new Promise((resolve, reject) => {
    if (text && token) {
      axios
        .post(
          `${url}/search/patient`,
          {
            text,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === "success") {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => reject(error));
    } else if (!text) {
      resolve({
        status: "success",
        data: [],
      });
    } else {
      reject({
        status: "error",
        info: "Token no definido",
      });
    }
  });

export const isDependentPatient = ({ token }) =>
  new Promise((resolve, reject) => {
    if (token) {
      axios
        .get(`${url}/is/dependent`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === "success") {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => reject(error));
    } else {
      reject({
        status: "error",
        info: "Token no definido",
      });
    }
  });

export const addDependentUser = ({
  token,
  firstName,
  lastName,
  gender,
  email,
  idType,
  dni,
  bornAt,
  password,
  relation,
  otherName,
}) =>
  new Promise((resolve, reject) => {
    if (
      token &&
      firstName &&
      lastName &&
      gender &&
      email &&
      idType &&
      dni &&
      bornAt &&
      password &&
      password.length >= 8 &&
      relation
    ) {
      axios
        .post(
          `${url}/add/user/dependent`,
          {
            firstName,
            lastName,
            gender,
            email,
            idType,
            dni,
            bornAt,
            password,
            relation,
            otherName,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === "success") {
            resolve(data);
          } else {
            resolve(data);
          }
        })
        .catch((error) => reject(error));
    } else if (!token) {
      reject({
        status: "error",
        info: "Token no definido",
      });
    } else if (!firstName) {
      reject({
        status: "error",
        info: "Nombre no definido",
      });
    } else if (!lastName) {
      reject({
        status: "error",
        info: "Apellido no definido",
      });
    } else if (!gender) {
      reject({
        status: "error",
        info: "Genero no definido",
      });
    } else if (!email) {
      reject({
        status: "error",
        info: "Correo no definido",
      });
    } else if (!idType) {
      reject({
        status: "error",
        info: "Tipo de indentificacion no definida",
      });
    } else if (!dni) {
      reject({
        status: "error",
        info: "DNI no definido",
      });
    } else if (!bornAt) {
      reject({
        status: "error",
        info: "Fecha de nacimiento no definido",
      });
    } else if (!password) {
      reject({
        status: "error",
        info: "Contraseña no definida",
      });
    } else if (password.length < 8) {
      reject({
        status: "error",
        info: "La contraseña debe de poseer al menos 8 caracteres",
      });
    } else if (!relation) {
      reject({
        status: "error",
        error: "La relacion es requerida",
      });
    } else {
      reject({
        status: "error",
        info: "Por favor verifique bien los campos del formulario",
      });
    }
  });

export const addDependentUserEmployee = ({
  token,
  firstName,
  lastName,
  gender,
  email,
  idType,
  dni,
  bornAt,
  password,
  relation,
  otherName,
  loggedUser,
}) =>
  new Promise((resolve, reject) => {
    if (
      token &&
      firstName &&
      lastName &&
      gender &&
      email &&
      idType &&
      dni &&
      bornAt &&
      password &&
      password.length >= 8 &&
      relation
    ) {
      axios
        .post(
          `${url}/add/user/dependentEmployee`,
          {
            firstName,
            lastName,
            gender,
            email,
            idType,
            dni,
            bornAt,
            password,
            relation,
            otherName,
            loggedUser,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === "success") {
            resolve(data);
          } else {
            resolve(data);
          }
        })
        .catch((error) => reject(error));
    } else if (!token) {
      reject({
        status: "error",
        info: "Token no definido",
      });
    } else if (!firstName) {
      reject({
        status: "error",
        info: "Nombre no definido",
      });
    } else if (!lastName) {
      reject({
        status: "error",
        info: "Apellido no definido",
      });
    } else if (!gender) {
      reject({
        status: "error",
        info: "Genero no definido",
      });
    } else if (!email) {
      reject({
        status: "error",
        info: "Correo no definido",
      });
    } else if (!idType) {
      reject({
        status: "error",
        info: "Tipo de indentificacion no definida",
      });
    } else if (!dni) {
      reject({
        status: "error",
        info: "DNI no definido",
      });
    } else if (!bornAt) {
      reject({
        status: "error",
        info: "Fecha de nacimiento no definido",
      });
    } else if (!password) {
      reject({
        status: "error",
        info: "Contraseña no definida",
      });
    } else if (password.length < 8) {
      reject({
        status: "error",
        info: "La contraseña debe de poseer al menos 8 caracteres",
      });
    } else if (!relation) {
      reject({
        status: "error",
        error: "La relacion es requerida",
      });
    } else {
      reject({
        status: "error",
        info: "Por favor verifique bien los campos del formulario",
      });
    }
  });

  export const addDependentUserEmployeeMassive = ({
    token,
    firstName,
    lastName,
    gender,
    email,
    idType,
    dni,
    bornAt,
    password,
    relation,
    otherName,
    loggedUser,
    numberReference2,
  }) =>
    new Promise((resolve, reject) => {
      if (
        token &&
        firstName &&
        lastName &&
        gender &&
        email &&
        idType &&
        dni &&
        bornAt &&
        password &&
        password.length >= 8 &&
        relation 
      ) {
        axios
          .post(
            `${url}/add/user/dependentEmployeeMassive`,
            {
              firstName,
              lastName,
              gender,
              email,
              idType,
              dni,
              bornAt,
              password,
              relation,
              otherName,
              loggedUser,
              numberReference2
            },
            {
              headers: {
                Authorization: token,
              },
            }
          )
          .then((res) => {
            console.log("success", res);
            if (res.status === "success") {
              resolve(res.message);
            } else {
              resolve(res);
            }
          })
          .catch((error) => {
            reject(error.message)
          });
      } else if (!token) {
        reject({
          status: "error",
          info: "Token no definido",
        });
      } else if (!firstName) {
        reject({
          status: "error",
          info: "Nombre no definido",
        });
      } else if (!lastName) {
        reject({
          status: "error",
          info: "Apellido no definido",
        });
      } else if (!gender) {
        reject({
          status: "error",
          info: "Genero no definido",
        });
      } else if (!email) {
        reject({
          status: "error",
          info: "Correo no definido",
        });
      } else if (!idType) {
        reject({
          status: "error",
          info: "Tipo de indentificacion no definida",
        });
      } else if (!dni) {
        reject({
          status: "error",
          info: "DNI no definido",
        });
      } else if (!bornAt) {
        reject({
          status: "error",
          info: "Fecha de nacimiento no definido",
        });
      } else if (!password) {
        reject({
          status: "error",
          info: "Contraseña no definida",
        });
      } else if (password.length < 8) {
        reject({
          status: "error",
          info: "La contraseña debe de poseer al menos 8 caracteres",
        });
      } else if (!relation) {
        reject({
          status: "error",
          error: "La relacion es requerida",
        });
      } else {
        reject({
          status: "error",
          info: "Por favor verifique bien los campos del formulario",
        });
      }
    });

  export const getDependentEmployeeExport = async (_id) =>{
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ _id });
    try {
      const res = await axios.post(
        "/api/dependent/patient/allEmployeeDependents",
        body,
        config
      );
      return res.data;
    } catch (error) {
      return [];
    }
  };
  

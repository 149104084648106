/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { connect } from 'react-redux';
import Spinner from '../Spinner/Spinner';
import {
  cancelVideoconferenceDoctor,
  acceptVideoconferenceDoctor,
  observerCancelVideoconferencePatient,
} from '../../websokect/notificationsWebsocket';
import { changeStatusDoctor } from '../../websokect/doctorWebsockets';
import { getOneVideoconference } from '../../api/videoconference';

class IncomingCall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      patient: undefined,
      videoconference: undefined,
      sound: new Audio('/sound.mp3'),
    };
  }

  componentDidMount() {
    const { history, match, token, user } = this.props;
    const { sound } = this.state;
    const { roomId: idVideo } = match.params;
    sound.play();
    getOneVideoconference({ token, idVideo })
      .then((res) => {
        const { patient, videoconference } = res;
        this.setState({
          loading: false,
          patient: patient,
          videoconference: videoconference,
        });
        observerCancelVideoconferencePatient({ idVideo }, () => {
          alert('El paciente ha cancelado la llamada');
          changeStatusDoctor({
            doctorId: user._id,
            role: user.role,
            status: 'available',
          });
          sound.pause();
          history.push('/dashboard');
        });
      })
      .catch((error) => {
        console.log(error);
        alert('Error al completar la llamada');
        history.push('/dashboard');
      });
    changeStatusDoctor({
      doctorId: user._id,
      role: user.role,
      status: 'occupied',
    });
  }

  handleAccept = () => {
    const { videoconference, sound } = this.state;
    const { history } = this.props;
    acceptVideoconferenceDoctor({ idVideo: videoconference._id }, (data) => {
      if (data.status === 'success') {
        if (videoconference.type === 'videocall') {
          sound.pause();
          window.location.href = `/videoconference/${videoconference._id}`;
        } else if (videoconference.type === 'chat') {
          sound.pause();
          history.push(`/dashboard/chat/${videoconference._id}`);
        } else if (videoconference.type === 'call') {
          sound.pause();
          window.location.href = `/call/${videoconference._id}`;
        } else {
          alert('Dato erroneo');
        }
      } else {
        alert('Lo sentimos no se pudo aceptar la llamada');
      }
    });
  };

  handleCancel = () => {
    const { history, user } = this.props;
    const { videoconference, sound } = this.state;
    cancelVideoconferenceDoctor({ idVideo: videoconference._id }, (data) => {
      if (data.status === 'success') {
        sound.pause();
        changeStatusDoctor({
          doctorId: user._id,
          role: user.role,
          status: 'available',
        });
        history.push('/dashboard');
      } else {
        alert('Lo sentimos no se pudo cancelar la llamada');
      }
    });
  };

  render() {
    const { loading, patient, videoconference } = this.state;
    if (loading && !patient && !videoconference) {
      return <Spinner />;
    }

    return (
      <div className="call mt-5 shadow">
        <header>
          <h1>Llamada Entrante</h1>
        </header>
        <div className="user">
          <div className="row user-name">
            {patient.publicData.gender == 'male' ? (
              <img
                src={require('../../assets/img/male.png')}
                alt="profile-image"
                className="media-object img-circle img-doc-3 center-div"
              />
            ) : (
              <img
                src={require('../../assets/img/female.png')}
                alt="profile-image"
                className="media-object img-circle img-doc-3 center-div"
              />
            )}{' '}
          </div>
          <div className="user-name p-1">
            <b className="text-secondarymdv">{`${patient.publicData?.firstName} ${patient.publicData?.lastName}`}</b>{' '}
            está llamando para una consulta.
          </div>
          <div className="status">
            <div className="circle" />
            <div className="circle" />
            <div className="circle" />
            <div className="circle" />
            <div className="circle last" />
          </div>
          <div className="d-flex justify-content-center mt-4">
            <div className="mr-2">
              <button
                type="button"
                onClick={this.handleCancel}
                className="btn btn-danger rounded-pill btn-sm"
              >
                Cancelar
              </button>
            </div>
            <div className="ml-2">
              <button
                type="button"
                onClick={this.handleAccept}
                className="btn btn-primary btn-sm"
              >
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => state.auth;

export default connect(mapStatetoProps)(IncomingCall);

/* eslint-disable no-console */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import moment from 'moment';
import 'moment/locale/es';
import { AiOutlineWhatsApp, BiPhoneCall, BiVideo } from 'react-icons/all';
import Spinner from '../Spinner/Spinner';
import {
  addProfileDoctorCvFile,
  addProfileImage,
} from '../../redux/actions/profile.action';
import { openModalProfileImage } from '../../redux/actions/ui.action';
import {
  monitorConsults,
  addDoctor,
  getVideoconference,
  cancelConsultDoctorOffice,
  getPatientToReview,
} from '../../api/consult.doctor.office';
import { getDoctorOffice } from '../../api/doctor.office';
import {
  obseverChangeConsultDoctorOffice,
  changeConsultDoctorOffice,
} from '../../websokect/consultDoctorOfficeWebsocket';
import { changeStatusDoctor } from '../../websokect/doctorWebsockets';
import jspdf from 'jspdf';
import { toPng } from 'html-to-image';
import { searchPatientID } from '../../redux/actions/searchPatient.action';
import { useDispatch } from 'react-redux';

const statusText = (status) => {
  const doc = {
    created: 'Creada',
    virtual: 'Virtual',
    attending: 'Atendiendo',
    finished: 'Finalizada',
  };
  return doc[status];
};

const NoneConsult = () => (
  <div
    style={{ height: '100vh' }}
    className='d-flex justify-content-center w-100'
  >
    <h2 className='m-auto text-black-50'>No estas en ningún consultorio.</h2>
  </div>
);

const QueryMonitor = ({ auth, history }) => {
  const [loading, setLoading] = useState(true);
  const [consult, setConsult] = useState(false);
  const [items, setItems] = useState([]);
  const { token, user } = auth;
  const dispatch = useDispatch();

  useEffect(() => {
    getDoctorOffice({ token })
      .then((res) => {
        const { data } = res;
        setConsult(data);
        monitorConsults({ _id: data._id, token })
          .then((res2) => {
            setItems(res2.data);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
        obseverChangeConsultDoctorOffice(data._id, () => {
          monitorConsults({ _id: data._id, token })
            .then((res2) => {
              setItems(res2.data);
              setLoading(false);
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
            });
        });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Spinner />;
  }

  // validando que la enfermera posea una
  if (!consult) {
    return <NoneConsult />;
  }

  // funcion para crear la cita presencial
  const handleCreateConsultPresencial = (
    idConsultMonitor,
    consultationType,
    idPostVideoconference,
    bySchedule,
    followUpReference
  ) => {
    addDoctor({
      token: token,
      idDoctor: user._id,
      status: 'attending',
      _id: idConsultMonitor,
      consultationType: consultationType,
      idPostVideoconference: idPostVideoconference,
      bySchedule,
      followUpReference,
    })
      .then((res) => {
        const { videoconference } = res;
        changeStatusDoctor({
          doctorId: user._id,
          role: user.role,
          status: 'occupied',
        });
        history.push(
          `/dashboard/consulta-presencial-paciente/${videoconference._id}`
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // funcion para ingresar a la videoconferencia
  const handleEnterConsultPresencial = (_id) => {
    getVideoconference({ token, _id })
      .then((res) => {
        const { videoconference } = res;
        changeStatusDoctor({
          doctorId: user._id,
          role: user.role,
          status: 'occupied',
        });
        history.push(
          `/dashboard/consulta-presencial-paciente/${videoconference._id}`
        );
      })
      .catch((err) => {
        console.log(err);
        alert(
          'Lo sentimos, no se pudo ingresar a la consulta. Contáctese con el administrador.'
        );
      });
  };

  //funcion entrar a la post consulta
  const handleEnterPostConsult = (_id, idPostVideoconference) => {
    changeStatusDoctor({
      doctorId: user._id,
      role: user.role,
      status: 'occupied',
    });

    history.push(`/dashboard/post-consulta/${idPostVideoconference}`);
  };

  const handleCancelConsult = (_id) => {
    Swal.fire({
      title: 'Escriba el motivo de la cancelación',
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Si',
      showLoaderOnConfirm: true,
      cancelButtonText: 'No',
      preConfirm: (reasonCancel) =>
        cancelConsultDoctorOffice({
          token: token,
          reasonCancel: reasonCancel,
          _id: _id,
        })
          .then((res) => res)
          .catch((error) => {
            if (error.info) {
              Swal.showValidationMessage(error.info);
            } else {
              Swal.showValidationMessage('Error al cancelar la consulta');
            }
          }),
    }).then((result) => {
      if (result.isConfirmed) {
        changeConsultDoctorOffice({ idConsult: _id });
        window.location.href = '/dashboard';
      }
    });
  };

  const downloadRecipe = () => {
    toPng(document.getElementById('descargarReceta'))
      .then((dataUrl) => {
        const doc = new jspdf();

        // Dimensiones de la ventana del navegador
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        // Dimensiones deseadas de la imagen en relación con la pantalla
        const imgWidth = screenWidth * 0.12; // Puedes ajustar el factor según tus necesidades
        const imgHeight = screenHeight * 0.4; // Puedes ajustar el factor según tus necesidades

        // Mantener la relación de aspecto
        const aspectRatio = imgWidth / imgHeight;

        // Calcular el ancho y alto reales para mantener la relación de aspecto
        let actualImgWidth = imgWidth;
        let actualImgHeight = imgWidth / aspectRatio;

        if (actualImgHeight > imgHeight) {
          actualImgHeight = imgHeight;
          actualImgWidth = imgHeight * aspectRatio;
        }

        // Calcular la posición para centrar la imagen en la página PDF
        const xPosition = (screenWidth - actualImgWidth) / 2;
        const yPosition = (screenHeight - actualImgHeight) / 2;
        doc.setProperties({
          pageWidth: actualImgWidth + 10,
          pageHeight: actualImgHeight * 1.5,
        });
        doc.addImage(dataUrl, 'PNG', 40, 20, actualImgWidth, actualImgHeight);
        doc.save('RecetaMedica.pdf');
      })
      .catch((error) => {
        console.log(error);
        alert('Lo sentimos ocurrió un error al descargar la receta médica');
      });
  };

  const viewInfoPatient = (_id) => {
    try {
      if (_id) {
        getPatientToReview({ _id: _id, token }).then((res) => {
          const { value, profile, image, label, gender } = res;
          dispatch(
            searchPatientID(value, profile, image, label, gender, history)
          );
        });
      }
    } catch (err) {}
  };
  return (
    <div className='container-fluid'>
      <div className='user-dashboard'>
        {/* Web View */}
        <div className='d-none d-sm-block'>
          <h2 className='text-primary mb-5 mt-5'>
            Monitor&nbsp;
            <b className='text-secondarymdv'>de Consultas</b>
            {/* <Link
                to="/dashboard/consulta-presencial-paciente"
                type="button"
                className="btn btn-info float-right"
                style={{
                    borderRadius: '0.8rem',
                  }}
                >
                  <small className="font-weight-bold">
                    Cita Presencial
                  </small>
              </Link> */}
          </h2>
        </div>
        {/* Fin Web View */}
        {/* Mobile View */}
        <div className='d-block d-sm-none'>
          <h5 className='text-primary mb-4 mt-4 font-weight-bold text-center'>
            Monitor&nbsp;
            <b className='text-secondarymdv'>de Consultas</b>
          </h5>
        </div>
        {/* Fin Web View */}
        <div className='row'>
          <div className='col-12 col-md-12 col-sm-12 col-xs-12 gutter'>
            <div className='table-responsive'>
              <table className='table table-light table-borderless table-striped'>
                <thead className='font-weight-bold bg-primarymdv text-center text-uppercase'>
                  <tr>
                    <th scope='col' className='title-header-table'>
                      ID
                    </th>
                    <th scope='col' className='title-header-table'>
                      Fecha
                    </th>
                    <th scope='col' className='title-header-table'>
                      Hora
                    </th>
                    <th scope='col' className='title-header-table'>
                      Estado
                    </th>
                    <th scope='col' className='title-header-table'>
                      Paciente
                    </th>
                    <th scope='col' className='title-header-table'>
                      {user.role === 'nurse' ? ' Enfermera(o)' : 'Cajero(a)'}
                    </th>
                    <th scope='col' className='title-header-table'>
                      Doctor(a)
                    </th>
                    <th scope='col' className='title-header-table'>
                      Tipo cita
                    </th>
                    <th scope='col' className='title-header-table'>
                      Indicaciones
                    </th>
                    {(user.role === 'doctor' ||
                      user.role === 'nurse' ||
                      user.role === 'checker') && (
                      <th scope='col' className='title-header-table'>
                        Acciones
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className='text-center'>
                  {items?.length === 0 ? (
                    <tr>
                      <th
                        colSpan={user.role === 'doctor' ? '8' : '7'}
                        scope='row'
                        className='text-secondarymdv'
                      >
                        No hay datos
                      </th>
                    </tr>
                  ) : (
                    items.map((item, index) => (
                      <>
                        <tr>
                          <th scope='row' className='text-secondarymdv'>
                            {index + 1}
                            {/* {item._id.slice(-4)} */}
                          </th>
                          <th scope='row' className='text-secondarymdv'>
                            {moment(item.createdAt).format('DD/MM/YYYY')}
                          </th>
                          <td>{item.hour}</td>
                          <td>{statusText(item.status)}</td>
                          <td>
                            {item.dataPatient &&
                              item.dataPatient[0] &&
                              `${item?.dataPatient?.[0]?.firstName} ${item?.dataPatient?.[0]?.lastName}`}
                            {item.dataPatient &&
                            item.dataPatient[0] &&
                            user.role === 'doctor' ? (
                              <button
                                type='button'
                                className='btn btn-info mt-2 btn-sm rounded-pill'
                                onClick={() =>
                                  viewInfoPatient(item?.dataPatient?.[0]?._id)
                                }
                                style={{ marginBottom: '4%', marginLeft: '4%' }}
                              >
                                Ver información
                              </button>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td>
                            {item.dataNurse &&
                              item.dataNurse[0] &&
                              `${item?.dataNurse?.[0]?.firstName} ${item?.dataNurse?.[0]?.lastName}`}
                          </td>
                          <td>
                            {item.dataDoctor &&
                              item.dataDoctor[0] &&
                              `${item.dataDoctor[0].firstName} ${item.dataDoctor[0].lastName}`}
                          </td>
                          <td>
                            {/*item.consultationType === 'videocall' &&
                            'Videollamada'*/}
                            {item.consultationType === 'presential' &&
                              'Presencial'}
                            {item.consultationType === 'lab' &&
                              'Servicio laboratorio'}
                            {item.consultationType === 'call' && (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <BiPhoneCall color='#011688' size={30} />
                                <span style={{ marginLeft: 5 }}>
                                  Llamada telefónica
                                </span>
                              </div>
                            )}
                            {item.consultationType === 'videocall' && (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <BiVideo color='#011688' size={30} />
                                <span style={{ marginLeft: 5 }}>
                                  Videollamada
                                </span>
                              </div>
                            )}
                            {item.consultationType === 'whatsapp' && (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <AiOutlineWhatsApp color='00BB2D' size={30} />
                                <span style={{ marginLeft: 5 }}>WhatsApp</span>
                              </div>
                            )}
                          </td>
                          {item?.indications?.length <= 0 ? (
                            <td>
                              <div>No hay indicaciones</div>
                            </td>
                          ) : (
                            <td>
                              <div>
                                <button
                                  type='button'
                                  className='btn btn-info mt-2 btn-sm rounded-pill'
                                  data-toggle='modal'
                                  data-target='#receta'
                                  /*onClick={(e) => {
                                showData(consulta);
                              }}*/
                                >
                                  Descargar receta
                                </button>
                              </div>
                            </td>
                          )}
                          {(user.role === 'doctor' ||
                            user.role === 'nurse' ||
                            user.role === 'checker') && (
                            <th>
                              {item.status === 'created' &&
                                user.role === 'doctor' && (
                                  <div className='d-flex'>
                                    <button
                                      onClick={() => {
                                        handleCreateConsultPresencial(
                                          item._id,
                                          item.consultationType,
                                          item.idPostVideoconference,
                                          item.bySchedule,
                                          item.followUpReference
                                        );
                                      }}
                                      type='button'
                                      className='btn btn-primary m-auto btn-sm'
                                    >
                                      Iniciar consulta
                                    </button>
                                    <button
                                      onClick={() => {
                                        handleCancelConsult(item._id);
                                      }}
                                      type='button'
                                      className='btn btn-danger rounded-pill m-auto btn-sm'
                                    >
                                      Cancelar consulta
                                    </button>
                                  </div>
                                )}
                              {item.status === 'attending' &&
                                item.idDoctor === user._id && (
                                  <button
                                    onClick={() => {
                                      handleEnterConsultPresencial(item._id);
                                    }}
                                    type='button'
                                    className='btn btn-primary btn-sm'
                                  >
                                    Ingresar a la consulta
                                  </button>
                                )}
                              {item.status === 'finished' &&
                                item.idPostVideoconference && (
                                  <button
                                    onClick={() => {
                                      handleEnterPostConsult(
                                        item.dataDoctor._id,
                                        item.idPostVideoconference
                                      );
                                      /*history.push(
                                        `/dashboard/post-consulta/${item.idPostVideoconference}`
                                      )*/
                                    }}
                                    type='button'
                                    style={{ borderRadius: '0.8rem' }}
                                    className='btn btn-info btn-sm'
                                  >
                                    Post consulta
                                  </button>
                                )}
                            </th>
                          )}
                        </tr>
                        {/*Modal de receta medica */}
                        <div
                          className='modal fade'
                          id='receta'
                          tabIndex='-1'
                          aria-labelledby='exampleModalLabel'
                          aria-hidden='true'
                        >
                          <div className='modal-dialog modal-dialog-centered'>
                            <div className='modal-content'>
                              <div className='col-md-12 col-sm-12 col-xs-12 gutter'>
                                <div className='bg-detail'>
                                  <div className='sales'>
                                    <div className='row  mar-2'>
                                      <div
                                        className='card-r2'
                                        id='descargarReceta'
                                      >
                                        <div className='card-body bbbb'>
                                          <h6 className='white center'>
                                            Receta médica
                                          </h6>
                                          {item.indications != null && (
                                            <>
                                              <h6 className='white center'>
                                                {`Paciente: ${item?.dataPatient?.[0]?.firstName} ${item?.dataPatient?.[0]?.lastName}`}
                                              </h6>
                                              <h6 className='white center'>
                                                {`Doctor: ${item.dataDoctor[0].firstName} ${item.dataDoctor[0].lastName}`}
                                              </h6>
                                            </>
                                          )}

                                          <center className='m-t-30'>
                                            {' '}
                                            <img
                                              className='img-circle-2 img-fluid'
                                              src={
                                                item?.profile?.image
                                                  ? `/api/profile/image/${item?.dataPatient?.image}`
                                                  : require('../../assets/img/avatars/avatar-pat-female.png')
                                              }
                                              alt='profile'
                                            />
                                          </center>
                                        </div>
                                        {item.indications != null ? (
                                          <div className='cont-r2'>
                                            <hr />
                                            <h6 className='font-blue-r'>HEA</h6>
                                            <h6 className='font-blue-l'>
                                              {item?.indications?.hea
                                                ? item.indications.hea
                                                : 'No disponible'}
                                            </h6>{' '}
                                            <hr />
                                            <h6 className='font-blue-r'>
                                              Su Diagnostico
                                            </h6>
                                            <h6 className='font-blue-l'>
                                              {item?.indications?.diagnosis
                                                ? item.indications.diagnosis
                                                : 'No disponibles'}
                                            </h6>
                                            <hr />
                                            <h6 className='font-blue-r'>
                                              Indicaciones Médicas
                                            </h6>
                                            {item?.indications?.length > 0 ? (
                                              <h6 className='font-blue-l'>
                                                {item.indications
                                                  ? item?.indications?.map(
                                                      (item) =>
                                                        `(${item.medicine},${item.dose},${item.frequency}), `
                                                    )
                                                  : 'No disponibles'}
                                              </h6>
                                            ) : (
                                              <div />
                                            )}
                                          </div>
                                        ) : (
                                          <div className='cont-r2'>
                                            <h6 className='font-blue-r'>HEA</h6>
                                            <h6 className='font-blue-r'>
                                              Su Diagnostico
                                            </h6>
                                            <h6 className='font-blue-r'>
                                              Indicaciones Médicas
                                            </h6>
                                            <h6 className='font-blue-r'>
                                              Medico Tratante
                                            </h6>
                                          </div>
                                        )}
                                      </div>
                                      <button
                                        type='button'
                                        className='btn btn-primary mt-2 btn-sm'
                                        onClick={downloadRecipe}
                                      >
                                        Descargar receta
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='modal-footer mt-2 pb-3'>
                                <div className='d-flex'>
                                  <button
                                    type='button'
                                    className='btn btn-link btn-sm mr-3 text-secondarymdv font-weight-bold'
                                    data-dismiss='modal'
                                  >
                                    Cancelar
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile.profile,
});

export default connect(mapStateToProps, {
  addProfileDoctorCvFile,
  addProfileImage,
  openModalProfileImage,
})(QueryMonitor);

/* eslint-disable no-shadow */
/* eslint-disable arrow-parens */
import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import { openModalScheduleDetails } from "../../../redux/actions/ui.action";
import ScheduleModal from "../../Patient/Scheduling/ScheduleDetailsModal";
import { getReference } from "../../../redux/actions/references";
import { showDetails } from "../../../redux/actions/scheduling.action";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../../stylesheets/scheduling.css";

const localizer = momentLocalizer(moment);

const Scheduling = ({
  schedule,
  showDetails,
  details,
  openModalScheduleDetails,
  getReference,
  openModalDetails,
  user,
}) => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (schedule.length > 0) {
      const tempSchedule = [];
      schedule.forEach((item) => {
        // preparar los datos
        const initial = new Date(moment(item.date));
        const finish = new Date(moment(item.date));
        initial.setHours(Number(item.hour.substr(0, 2)));
        finish.setHours(Number(item.hour.substr(0, 2)));

        const tempFinishHour = Number(item.hour.substr(3, 4));

        if (tempFinishHour === 0) {
          initial.setMinutes(0);
          finish.setMinutes(20);
        } else if (tempFinishHour === 20) {
          initial.setMinutes(20);
          finish.setMinutes(40);
        } else {
          initial.setMinutes(40);
          finish.setMinutes(0);
          finish.setHours(Number(item.hour.substr(0, 2)) + 1);
        }

        const doc = {
          item: item,
          start: initial,
          end: finish,
          title:
            user?.role === "doctor"
              ? `${item?.patient?.firstName?.split(" ")[0]} ${
                  item?.patient?.lastName?.split(" ")[0]
                } - ${item.hour} ${
                  parseInt(item.hour.substr(0, 2), 10) >= 12 ? "PM" : "AM"
                }`
              : `${item?.doctor?.firstName?.split(" ")[0]} ${
                  item?.doctor?.lastName?.split(" ")[0]
                } - ${item.hour} ${
                  parseInt(item.hour.substr(0, 2), 10) >= 12 ? "PM" : "AM"
                }`,
          status: item.status,
        };
        tempSchedule.push(doc);
      });
      setEvents(tempSchedule);
    } else {
      setEvents([]);
    }
  }, [schedule]);

  const handleShowdetails = async (e) => {
    showDetails(e.item);
    await getReference(e.item.patient._id);
    openModalScheduleDetails();
  };

  // estilos para cada uno de los items del calendario
  const handleEventStyle = (event) => {
    const toDay = new Date();
    const temp = moment(event.item.date, "YYYY-MM-DD");
    const day = temp.format("D");
    const month = temp.format("M");
    const year = temp.format("YYYY");
    const selectedDate = new Date(
      year,
      parseInt(month, 10) - 1,
      day,
      parseInt(event.item.hour, 10)
    );

    const enabled = {
      backgroundColor: "#0c87e7",
      color: "#fff",
      borderRadius: "1.5rem",
      border: "1px solid #fff",
      fontSize: 12,
    };

    if (selectedDate.getTime() <= toDay.getTime()) {
      enabled.backgroundColor = "#CDCDCD";
    } else if (event.status === "accept") {
      enabled.backgroundColor = "#5FCE7F";
    } else if (event.status === "reject") {
      enabled.backgroundColor = "#C60005";
    }

    return {
      className: "",
      style: enabled,
    };
  };

  return (
    <Container className="container-scheduling" fluid>
      {openModalDetails && <ScheduleModal />}
      <Calendar
        defaultDate={moment().toDate()}
        className="bigCalendar-container"
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 800, width: "100%" }}
        messages={{
          next: "sig",
          previous: "ant",
          today: "Hoy",
          month: "Mes",
          week: "Semana",
          day: "Día",
        }}
        eventPropGetter={(event) => handleEventStyle(event)}
        onSelectEvent={(e) => handleShowdetails(e)}
      />
    </Container>
  );
};
const mapStateToProps = (state) => ({
  schedule: state.scheduling.items,
  details: state.scheduling.details,
  openModalDetails: state.ui.modalScheduleDetails.open,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  showDetails,
  getReference,
  openModalScheduleDetails,
})(Scheduling);

import axios from 'axios';

export const urlVideoconference = '/api/videoconference';

export const getApiKeyZoom = ({ token }) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${urlVideoconference}/zoom/data/api`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        const { data } = res;
        if (data.status === 'success') {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

export const createVideoconference = ({ token, type, doctor }) =>
  new Promise((resolve, reject) => {
    if (token && type && doctor) {
      axios
        .post(
          `${urlVideoconference}/create`,
          {
            type,
            doctor,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      reject({
        status: 'error',
        info: 'Verifique los parametros de la funcion',
      });
    }
  });

export const endCallVideconference = ({
  token,
  endCallDate,
  idVideoconference,
  reason,
  indications,
  diagnosis,
  rating,
  commentPatient,
  ratingPresentation,
  ratingComunication,
  hea,
  nursingNote,
  vitalSings,
  physicalTest,
}) =>
  new Promise((resolve, reject) => {
    if (token && endCallDate && idVideoconference) {
      axios
        .post(
          `${urlVideoconference}/end`,
          {
            endCallDate,
            idVideoconference,
            reason,
            indications,
            diagnosis,
            rating,
            commentPatient,
            ratingComunication,
            ratingPresentation,
            hea,
            nursingNote,
            vitalSings,
            physicalTest,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      reject({
        status: 'error',
        info: 'Verifique los parametros de la función',
      });
    }
  });

export const updateResumenConsultPaciente = ({
  token,
  idVideoconference,
  reason,
  indications,
  diagnosis,
  hea,
  nursingNote,
  vitalSings,
  physicalTest,
}) =>
  new Promise((resolve, reject) => {
    if (token && idVideoconference) {
      axios
        .post(
          `${urlVideoconference}/updateResumeCosult`,
          {
            idVideoconference,
            reason,
            indications,
            diagnosis,
            hea,
            nursingNote,
            vitalSings,
            physicalTest,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      reject({
        status: 'error',
        info: 'Verifique los parametros de la función',
      });
    }
  });

export const getOneVideoconference = ({ token, idVideo }) =>
  new Promise((resolve, reject) => {
    if (token && idVideo) {
      axios
        .get(`${urlVideoconference}/one/${idVideo}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      reject({
        status: 'error',
        info: 'Verfique los parametros de la funcion',
      });
    }
  });

export const verifyVideoConference = ({ token }) =>
  new Promise((resolve, reject) => {
    if (token) {
      axios
        .get(`${urlVideoconference}/verify`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      resolve({
        status: 'error',
        info: 'Token no definido',
      });
    }
  });

export const lastConsultUser = ({ token }) =>
  new Promise((resolve, reject) => {
    if (token) {
      axios
        .get(`${urlVideoconference}/last/consult`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      reject({
        status: 'error',
        info: 'Usuario no autenticado',
      });
    }
  });

export const totalVideoconference = ({ token }) =>
  new Promise((resolve, reject) => {
    if (token) {
      axios
        .get(`${urlVideoconference}/total`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => reject(error));
    } else {
      reject({
        status: 'error',
        info: 'El usuario no esta autenticado',
      });
    }
  });

export const cancelVideoconference = ({ token, reasonCancel, _id }) =>
  new Promise((resolve, reject) => {
    if (token && reasonCancel && _id) {
      axios
        .put(
          `${urlVideoconference}/cancel/${_id}`,
          {
            reasonCancel,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => reject(err));
    } else if (!token) {
      reject({
        status: 'error',
        info: 'Toke no definido',
      });
    } else if (!reasonCancel) {
      reject({
        status: 'error',
        info: 'El motivo de la cancelacion es requerida',
      });
    } else {
      reject({
        status: 'error',
        info: 'Identificador es requerido',
      });
    }
  });

export const skipVideoconference = ({ token, idVideoconference }) =>
  new Promise((resolve, reject) => {
    if (token && idVideoconference) {
      axios
        .put(`${urlVideoconference}/skip/${idVideoconference}`, undefined, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => reject(error));
    } else if (!token) {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    } else {
      reject({
        status: 'error',
        info: 'Identificador no definido',
      });
    }
  });

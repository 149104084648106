/* eslint-disable no-shadow */
/* eslint-disable comma-dangle */
import React, {useEffect} from 'react';
import queryString from 'query-string';
import {connect, useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {
  getMedicines,
  getHealth,
  getAllergies,
  getFamiliarAntecedents,
  getItemsFrontendFamiliar,
  getVaccinateDef,
  getAllergiesDef,
  getAllHealthDef,
  getAllFamiliarDef,
  getAllLifeStyleDef,
} from '../../../redux/actions/patient.history.action';

import {getSurgeries} from '../../../redux/actions/surgeries.action';
import {getvaccinate} from '../../../redux/actions/vaccinate.action';
import {getlifestyle} from '../../../redux/actions/lifestyle.action';
import {getExams} from '../../../redux/actions/exams.action';
import {getclinical} from '../../../redux/actions/clinical.action';
import PatientHistory from '../../../Components/Patient/PatientHistory';
import {getQueryHistory} from '../../../redux/actions/searchPatient.action';
import {
  deleteProfileReference,
  deleteReference,
  getProfileReference,
  getReference,
} from '../../../redux/actions/references';

// eslint-disable-next-line no-shadow

const Historial = ({
  patient,
  getMedicines,
  getHealth,
  getAllergies,
  getFamiliarAntecedents,
  getvaccinate,
  getlifestyle,
  getExams,
  getclinical,
  getSurgeries,
  getQueryHistory,
  getItemsFrontendFamiliar,
  getVaccinateDef,
  getAllergiesDef,
  getAllHealthDef,
  getAllFamiliarDef,
  getAllLifeStyleDef,
  userRef,
  user,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    // imprimir que tiene el querystring
    const {q} = queryString.parse(location.search);
    // datos referencia
    getVaccinateDef();
    getAllergiesDef();
    getAllHealthDef();
    getAllFamiliarDef();
    getAllLifeStyleDef();

    if (user?.role === 'patient') {
      getMedicines(user._id);
      getHealth(user._id);
      getAllergies(user._id);
      getSurgeries(user._id);
      getvaccinate(user._id);
      getlifestyle(user._id);
      getFamiliarAntecedents(user._id);
      getExams(user._id);
      getclinical(user._id);
    } else if (patient) {
      getMedicines(patient);
      getHealth(patient);
      getAllergies(patient);
      getSurgeries(patient);
      getvaccinate(patient);
      getlifestyle(patient);
      getFamiliarAntecedents(patient);
      getExams(patient);
      getclinical(patient);
      getItemsFrontendFamiliar();
      getQueryHistory('', '', patient);
    } else if (q) {
      getMedicines(q);
      getHealth(q);
      getAllergies(q);
      getSurgeries(q);
      getvaccinate(q);
      getlifestyle(q);
      getFamiliarAntecedents(q);
      getExams(q);
      getclinical(q);
      getItemsFrontendFamiliar();
      getQueryHistory('', '', q);
      dispatch(getReference(q));
      dispatch(getProfileReference(q));
    }
  }, [patient]);

  if (!userRef && user.role !== 'patient') {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          height: '100vh',
          fontSize: 30,
        }}
      >
        No se encuentra el perfil buscado
      </div>
    );
  }

  return <PatientHistory />;
};

const mapStateToProps = (state) => ({
  patient: state.reference.userRef?._id,
  user: state.auth.user,
  userRef: state.reference.userRef,
});

export default connect(mapStateToProps, {
  getMedicines,
  getHealth,
  getAllergies,
  getSurgeries,
  getvaccinate,
  getlifestyle,
  getFamiliarAntecedents,
  getExams,
  getclinical,
  getQueryHistory,
  getItemsFrontendFamiliar,
  getVaccinateDef,
  getAllergiesDef,
  getAllHealthDef,
  getAllFamiliarDef,
  getAllLifeStyleDef,
})(Historial);

/* eslint-disable no-shadow */
/* eslint-disable arrow-parens */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import isMobile from 'ismobilejs';
import Layout from '../../Components/DashboardMenu';
import { verifyVideoConference } from '../../api/videoconference';
import {
  addVerifyConsult,
  removeVerifyConsult,
  endLoadingVerifyConsult,
} from '../../redux/actions/videoconference.action';
import ModalDescriptionProduct from '../../Components/Modal/ModalDescriptionProduct';
import ModalSearchPatientName from '../../Components/Modal/ModalSearchPatienName';
// rutas enfermera
import HomeNurse from '../../pages/Nurse/Dashboard/Home';

// rutas doctor
import HomeDoctor from '../../pages/Doctor/Dasboard/Home';
import ChatDoctor from '../../pages/Doctor/Dasboard/Chat';
import AgendaDoctor from '../../pages/Doctor/Dasboard/Scheduling';
import ProfileDoctor from '../../pages/Doctor/Dasboard/Profile';
/* import HistorialDoctor from '../../pages/Doctor/Dasboard/Historial'; */
import ConfigurationDoctor from '../../pages/Doctor/Dasboard/Configurations';
import DoctorProfits from '../../Components/Doctor/DoctorProfits';
import Medicaldetails from '../../pages/Doctor/Dasboard/Details';
import CallDetails from '../../pages/Doctor/Dasboard/Calls';
import IncomingCall from '../../pages/Doctor/Dasboard/Telellamada';
import PatientDetails from '../../pages/Doctor/Dasboard/Patients';
import Withdrawal from '../../pages/Doctor/Dasboard/WithdrawalPage';
import ReasonCheck from '../../pages/Doctor/Dasboard/Reason';
import FacetofaceApppointment from '../../Components/Doctor/FaceToFaceAppoinment/FacetofaceApppointment';
import QueryMonitor from '../../Components/Doctor/QueryMonitor';
import FacetoFaceConsultationPatient from '../../Components/Doctor/FacetoFaceConsultationPatient';
import PostConsultation from '../../Components/Doctor/PostConsultation';

// paciente rutas
import PackageInvoiceSubscription from '../../Components/Patient/PackageInvoiceSubscription';
import HomePatient from '../../pages/Patient/Dasboard/Home';
import ChatPatient from '../../pages/Patient/Dasboard/Chat';
import WaitingCall from '../../pages/Patient/Dasboard/WaitingCall';
import AgendaPatient from '../../pages/Patient/Dasboard/Scheduling';
import ProfilePatient from '../../pages/Patient/Dasboard/Profile';
import TelellamadaPatient from '../../pages/Patient/Dasboard/Telellamada';
import HistorialPatient from '../../pages/Patient/Dasboard/Historial';
import ConfigurationPatient from '../../pages/Patient/Dasboard/Configurations';
import Loader from '../../Components/Spinner/Spinner';
import getSpecialties from '../../redux/actions/specialties.action';
import { getShoppingItems } from '../../redux/actions/shopping.cart.action';
import { getCreditCards } from '../../redux/actions/credit.card.action';
import { getBankAccounts } from '../../redux/actions/bank.accounts.action';
import { getNotifications } from '../../redux/actions/notifications.action';
import Invoice from '../../pages/Patient/Dasboard/InvoicePage';
import CheckPayment from '../../pages/Patient/Dasboard/Check';
import PaySuccess from '../../Components/Patient/PaySuccess';
import HEA from '../../Components/Patient/HEA';
import RateDoctor from '../../Components/Patient/RateDoctor';
import PackageInvoicePage from '../../pages/PackageInvoisePage';
import FacetoFaceConsultation from '../../Components/Patient/Presential/FacetoFaceConsultation';
import Laboratorio from '../../Components/Patient/Laboratorio';
import TelemedicineInvoicePage from '../../pages/TelemedicineInvoicePage';
import CashPackageChecker from '../../pages/Checker/CashPackageChecker';
import SearchPatients from '../../pages/Checker/SearchPatients';
import PatientCard from '../../Components/Patient/Carnet/PatientCard';
import PatientGiftCards from '../../pages/Patient/Dasboard/Giftcard/Giftcard';

// rutas para el cajero
import HomeChecker from '../../pages/Checker/Home';
import CheckerPayment from '../../pages/Checker/CheckerPayment';
import giftCards from '../../Components/Checker/GiftCards/GiftCards';
import CheckPaymentGiftCard from '../../Components/Checker/GiftCards/CheckPaymentGiftCard';

// rutas para empresas
import HomeManageEmployees from '../../pages/Enterprise/ManageEmployees';
import EnterpriseInvoicePage from '../../pages/Enterprise/EnterpriseInvoicePage';
import EnterpriseHistoryPage from '../../pages/Enterprise/EnterpriseHistoryPage';
import EnterpriseLogPage from '../../pages/Enterprise/EnterpriseLogPage';

import {
  onLineDoctor,
  observerNotificationCall,
} from '../../websokect/doctorWebsockets';
import NotFound from '../../pages/NotFound/NotFoundPublic';
import { getPlanReference } from '../../redux/actions/references';
import Callcenter from '../../pages/Callcenter/Callcenter';
import EnterpriseLog from '../../Components/Enterprise/EnterpriseLog';
import EnterpriseProfile from '../../Components/Enterprise/EnterpriseProfile';

import { getAllPlans } from '../../redux/actions/plans.action';

const showNotification = () => {
  // const notification = new Notification('Nueva notificación');
};

const DashboardLayout = ({
  userLoaded,
  profileLoaded,
  user,
  getSpecialties,
  getShoppingItems,
  getCreditCards,
  getBankAccounts,
  token,
  getNotifications,
  addVerifyConsult,
  removeVerifyConsult,
  endLoadingVerifyConsult,
  verifyConsult,
  loadingVerifyConsult,
  getPlanReference,
  getAllPlans,
}) => {
  useEffect(() => {
    getSpecialties();
    getShoppingItems();
    getCreditCards();
    getBankAccounts();
    getNotifications();
    getPlanReference();
    getAllPlans();
    verifyVideoConference({
      token,
    })
      .then((res) => {
        const { activeVideo, dataVideo } = res;
        if (activeVideo) {
          addVerifyConsult(dataVideo);
        } else {
          removeVerifyConsult();
        }
        endLoadingVerifyConsult();
      })
      .catch((error) => {
        console.log(error);
        removeVerifyConsult();
        endLoadingVerifyConsult();
      });

    if (!isMobile(window.navigator).apple) {
      if (Notification.permission === 'default') {
        Notification.requestPermission().then((permission) => {});
      } else if (Notification.permission === 'granted') {
        showNotification();
      }
    }
  }, []);

  if (!token) {
    return <Redirect to='/' />;
  }

  if (userLoaded && profileLoaded) {
    if (user.role === 'patient') {
      // haciendo la validacion para el paciente para que llene la
      // infomracion necesaria de la consulta pendiente
      if (loadingVerifyConsult) {
        return <Loader />;
      }

      if (window.location.pathname !== '/dashboard/rate' && verifyConsult) {
        return <Redirect to='/dashboard/rate' />;
      }

      return (
        <>
          <Layout>
            <ModalDescriptionProduct />
            <Switch>
              <Route exact path='/dashboard' component={HomePatient} />
              <Route
                exact
                path='/dashboard/enterprise/manage-employees'
                component={HomeManageEmployees}
              />
              <Route
                exact
                path='/dashboard/enterprise/invoice'
                component={EnterpriseInvoicePage}
              />
              <Route
                exact
                path='/dashboard/enterprise/log'
                component={EnterpriseLogPage}
              />
              <Route
                exact
                path='/dashboard/enterprise/history'
                component={EnterpriseHistoryPage}
              />
              <Route path='/dashboard/agenda' component={AgendaPatient} />
              <Route path='/dashboard/perfil' component={ProfilePatient} />
              <Route
                path='/dashboard/telellamada'
                component={TelellamadaPatient}
              />
              <Route
                path='/dashboard/consulta-presencial'
                component={FacetoFaceConsultation}
              />
              <Route
                path='/dashboard/waitingcall/:roomId'
                component={WaitingCall}
              />
              <Route path='/dashboard/chat/:roomId' component={ChatPatient} />
              <Route path='/dashboard/historial' component={HistorialPatient} />
              <Route path='/dashboard/consulta/invoice' component={Invoice} />
              <Route
                path='/dashboard/planes/invoice'
                component={PackageInvoicePage}
              />
              <Route
                path='/dashboard/telemedicine/invoice'
                component={TelemedicineInvoicePage}
              />
              scheduleWebSocket
              {!user.isRegisterByDomain && !user.idCompany && (
                <Route
                  path='/dashboard/giftcard'
                  component={PatientGiftCards}
                />
              )}
              <Route
                path='/dashboard/planes/suscription'
                component={PackageInvoiceSubscription}
              />
              <Route path='/dashboard/consulta' component={CheckPayment} />
              <Route path='/dashboard/success' component={PaySuccess} />
              <Route path='/dashboard/HEA' component={HEA} />
              <Route path='/dashboard/rate' component={RateDoctor} />
              <Route path='/dashboard/details' component={Medicaldetails} />
              <Route path='/dashboard/laboratorio' component={Laboratorio} />
              <Route
                path='/dashboard/configuracion'
                component={ConfigurationPatient}
              />
              <Route
                path='/dashboard/carnet-paciente'
                component={PatientCard}
              />
              <Route path='*' component={NotFound} />
            </Switch>
          </Layout>
        </>
      );
    } else if (user.role === 'doctor') {
      onLineDoctor({ doctorId: user._id, role: user.role });
      observerNotificationCall({ doctorId: user._id }, (dataVideo) => {
        if (dataVideo) {
          if (dataVideo.type === 'videocall') {
            window.location.href = `/dashboard/telellamada/${dataVideo.idVideo}`;
          } else if (dataVideo.type === 'chat') {
            window.location.href = `/dashboard/telellamada/${dataVideo.idVideo}`;
          } else if (dataVideo.type === 'call') {
            window.location.href = `/dashboard/telellamada/${dataVideo.idVideo}`;
          }
        }
      });

      if (loadingVerifyConsult) {
        return <Loader />;
      }

      if (window.location.pathname !== '/dashboard/reason' && verifyConsult) {
        return <Redirect to='/dashboard/reason' />;
      }

      return (
        <>
          <Layout>
            <ModalSearchPatientName />
            <Switch>
              <Route exact path='/dashboard' component={HomeDoctor} />
              <Route path='/dashboard/agenda' component={AgendaDoctor} />
              <Route path='/dashboard/perfil' component={ProfileDoctor} />
              <Route
                path='/dashboard/telellamada/:roomId'
                component={IncomingCall}
              />
              <Route path='/dashboard/patients' component={PatientDetails} />
              <Route path='/dashboard/calls' component={CallDetails} />
              <Route path='/dashboard/details' component={Medicaldetails} />
              <Route path='/dashboard/profits' component={DoctorProfits} />
              <Route path='/dashboard/chat/:roomId' component={ChatDoctor} />
              <Route path='/dashboard/historial' component={HistorialPatient} />
              <Route path='/dashboard/laboratorio' component={Laboratorio} />
              <Route
                path='/dashboard/cita-presencial'
                component={FacetofaceApppointment}
              />
              <Route
                path='/dashboard/consulta-presencial-paciente/:roomId'
                component={FacetoFaceConsultationPatient}
              />
              <Route
                path='/dashboard/monitor-de-consultas'
                component={QueryMonitor}
              />
              <Route
                path='/dashboard/post-consulta/:roomId'
                component={PostConsultation}
              />
              <Route path='/dashboard/retiros' component={Withdrawal} />
              <Route path='/dashboard/reason' component={ReasonCheck} />
              <Route
                path='/dashboard/configuracion'
                component={ConfigurationDoctor}
              />
              <Route path='*' component={NotFound} />
            </Switch>
          </Layout>
        </>
      );
    } else if (user.role === 'nurse') {
      return (
        <>
          <Layout>
            <Switch>
              <Route exact path='/dashboard' component={HomeNurse} />
              <Route path='/dashboard/agenda' component={AgendaDoctor} />
              <Route path='/dashboard/perfil' component={ProfileDoctor} />
              <Route
                path='/dashboard/telellamada/:roomId'
                component={IncomingCall}
              />
              <Route path='/dashboard/patients' component={PatientDetails} />
              <Route path='/dashboard/calls' component={CallDetails} />
              <Route path='/dashboard/details' component={Medicaldetails} />
              <Route path='/dashboard/profits' component={DoctorProfits} />
              <Route path='/dashboard/chat/:roomId' component={ChatDoctor} />
              <Route path='/dashboard/historial' component={HistorialPatient} />
              <Route path='/dashboard/laboratorio' component={Laboratorio} />
              <Route
                path='/dashboard/cita-presencial'
                component={FacetofaceApppointment}
              />
              <Route
                path='/dashboard/post-consulta/:roomId'
                component={PostConsultation}
              />
              <Route
                path='/dashboard/monitor-de-consultas'
                component={QueryMonitor}
              />
              <Route path='*' component={NotFound} />
            </Switch>
          </Layout>
        </>
      );
    } else if (user.role === 'checker') {
      return (
        <>
          <Layout>
            <Switch>
              <Route exact path='/dashboard' component={HomeChecker} />
              <Route
                exact
                path='/dashboard/checker/payment'
                component={CheckerPayment}
              />
              <Route
                exact
                path='/dashboard/historial'
                component={CashPackageChecker}
              />
              <Route exact path='/dashboard/giftCards' component={giftCards} />
              <Route
                exact
                path='/dashboard/giftCardsCheckPay'
                component={CheckPaymentGiftCard}
              />
              <Route
                exact
                path='/dashboard/patients'
                component={SearchPatients}
              />
              <Route
                path='/dashboard/cita-presencial'
                component={FacetofaceApppointment}
              />
              <Route
                path='/dashboard/monitor-de-consultas'
                component={QueryMonitor}
              />
              <Route path='*' component={NotFound} />
            </Switch>
          </Layout>
        </>
      );
    } else if (user.role === 'callcenter') {
      return (
        <>
          <Layout>
            <Switch>
              <Route exact path='/dashboard' component={Callcenter} />
            </Switch>
          </Layout>
        </>
      );
    } else if (user.role === 'company') {
      return (
        <>
          <Layout>
            <Switch>
              <Route exact path='/dashboard' component={HomeManageEmployees} />
              <Route
                exact
                path='/dashboard/manage-employees'
                component={HomeManageEmployees}
              />
              <Route
                exact
                path='/dashboard/enterprise/invoice'
                component={EnterpriseInvoicePage}
              />
              <Route
                exact
                path='/dashboard/history'
                component={EnterpriseHistoryPage}
              />
              <Route exact path='/dashboard/log' component={EnterpriseLog} />

              <Route path='*' component={NotFound} />
            </Switch>
          </Layout>
        </>
      );
    } else {
      return <Loader />;
    }
  } else {
    return <Loader />;
  }
};

const mapStateToProps = (state) => ({
  userLoaded: state.auth.userLoaded,
  isAuth: state.auth.isAuth,
  user: state.auth.user,
  profileLoaded: state.profile.profileLoaded,
  token: state.auth.token,
  verifyConsult: state.videoconference.verifyVideo,
  videoconferenceDataState: state.videoconference,
  loadingVerifyConsult: state.videoconference.loadingVerifyConsult,
});

export default connect(mapStateToProps, {
  getSpecialties,
  getShoppingItems,
  getCreditCards,
  getBankAccounts,
  getNotifications,
  addVerifyConsult,
  removeVerifyConsult,
  endLoadingVerifyConsult,
  getPlanReference,
  getAllPlans,
})(DashboardLayout);

/* eslint-disable func-names */
import {
  ADD_USER_REFERENCE,
  DELETE_USER_REFERENCE,
  GET_DOCTOR_TIMETABLE,
  DELETE_REFERENCE_TIMETABLE,
  ADD_CONSULTORY_REFERENCE_OF_DOCTOR,
  DELETE_CONSULTORY_REFERENCE_OF_DOCTOR,
  GET_PROFILE_REFERENCE,
  DELETE_PROFILE_REFERENCE,
  ADD_USER_FLAG_BY_CHECKER,
  GET_PLAN_REFERENCE,
  CLEAN_PLAN_REFERENCE,
  GET_ALL_DEPENDENTS,
  CLEAN_DEPENDENTS,
  CLEAN_ALL_REFERENCES,
  GET_PLANS_REFERENCE,
  ADD_SELECTED_PLAN,
  GET_ALL_APPOINTMENT_REFERENCE,
  ADD_SELECTED_APPOINTMENT,
  CLEAR_ALL_APPOINTMENT,
  CLEAR_SELECTED_APPOINTMENT,
  GET_DOCTOR_REFERENCE,
  DELETE_DOCTOR_REFERENCE,
} from '../types';

const initialState = {
  userRef: null,
  doctor: null,
  profileRef: null,
  timeTable: null,
  consultories: [],
  addUserFlag: false,
  planRef: null,
  selectedPlan: null,
  dependentsRef: [],
  plansRef: [],
  appointments: [],
  selectedAppointment: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DOCTOR_REFERENCE:
      return {
        ...state,
        doctor: payload,
      };

    case DELETE_DOCTOR_REFERENCE:
      return {
        ...state,
        doctor: null,
      };

    case GET_ALL_APPOINTMENT_REFERENCE:
      return {
        ...state,
        appointments: payload,
      };

    case GET_ALL_APPOINTMENT_REFERENCE:
      return {
        ...state,
        appointments: payload,
      };

    case CLEAR_SELECTED_APPOINTMENT:
      return {
        ...state,
        selectedAppointment: null,
      };

    case CLEAR_ALL_APPOINTMENT:
      return {
        ...state,
        appointments: [],
      };

    case ADD_SELECTED_APPOINTMENT:
      return {
        ...state,
        selectedAppointment: payload,
      };

    case ADD_SELECTED_PLAN:
      return {
        ...state,
        selectedPlan: payload,
      };

    case CLEAN_ALL_REFERENCES:
      return {
        ...initialState,
      };
    case GET_ALL_DEPENDENTS:
      return {
        ...state,
        dependentsRef: payload,
      };

    case CLEAN_DEPENDENTS:
      return {
        ...state,
        dependentsRef: [],
      };

    case GET_PLAN_REFERENCE:
      return {
        ...state,
        planRef: payload,
      };

    case GET_PLANS_REFERENCE: {
      return {
        ...state,
        plansRef: payload,
      };
    }

    case CLEAN_PLAN_REFERENCE:
      return {
        ...state,
        planRef: null,
      };

    case ADD_USER_REFERENCE:
    case DELETE_USER_REFERENCE:
      return {
        ...state,
        userRef: payload,
      };

    case GET_DOCTOR_TIMETABLE:
    case DELETE_REFERENCE_TIMETABLE:
      return {
        ...state,
        timeTable: payload,
      };

    case ADD_CONSULTORY_REFERENCE_OF_DOCTOR:
    case DELETE_CONSULTORY_REFERENCE_OF_DOCTOR:
      return {
        ...state,
        consultories: payload,
      };

    case GET_PROFILE_REFERENCE:
    case DELETE_PROFILE_REFERENCE:
      return {
        ...state,
        profileRef: payload,
      };

    case ADD_USER_FLAG_BY_CHECKER:
      return {
        ...state,
        addUserFlag: payload,
      };

    default:
      return state;
  }
}
